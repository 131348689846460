//@flow
import type { SerializedStyles } from "@emotion/utils/src/types";
import * as React from 'react';
import { FaCircle } from 'react-icons/fa';
import { css } from '@emotion/react';
import KeyboardNav from '../../../utils/layout/keyboard_nav';
import { navigate } from 'gatsby';
type Props = {
  keyboardNav?: boolean,
  useRouteBase?: string,
  navCss?: any,
  loop?: boolean,
  loopInterval?: number,
  transitionLength?: number,
  startAt?: number,
  children: React.Node
};

type State = {
  transitioning: boolean,
  slides: React.Node[],
  currentIndex: number
}

let looper;
export default class Carousel extends React.Component <Props, State> {

  static defaultProps: {loop: boolean, loopInterval: number, transitionLength: number,...} = {
    loop: false,
    loopInterval: 4000,
    transitionLength: 600,
  };

  state: State = {
    transitioning: false,
    slides: React.Children.map(this.props.children, x => x),
    currentIndex: ( typeof this.props.startAt === 'undefined') ?  0 : this.props.startAt - 1
  };

  nextSlide: (() => void) = () => {
    this.changeSlide(1);
  };

  previousSlide: (() => void) = () => {
    this.changeSlide(-1);
  };

  switchTo(slideNum: number) {
    let {slides} = this.state;
    let numSlides = slides.length;
    let currentIndex;

    if (!slideNum || slideNum < 0) {
      currentIndex = 0;
    } else if (slideNum > (numSlides -1)) {
      currentIndex = (numSlides -1);
    } else {
      currentIndex = slideNum;
    }

    this.setState({currentIndex});
  }

  changeSlide: ((increment: number) => void) = (increment: number) => {

    let { slides, currentIndex, transitioning } = this.state;
    if (transitioning) return;

    let { useRouteBase} = this.props;
    let numSlides = slides.length;

    let tempNext = currentIndex + increment;

    if (tempNext < 0) {
      currentIndex = (numSlides -1);
    } else if (tempNext > (numSlides -1)) {
      currentIndex = 0;
    } else {
      currentIndex=tempNext;
    }

    this.setState({ currentIndex, transitioning: true });

    setTimeout(() => {
      this.setState({ transitioning: false });
      if (useRouteBase) {
        navigate(useRouteBase+ '/' + (currentIndex + 1));
      }
    }, (this.props.transitionLength || 0) + 200);
  };

  buildSlide(child: React.Node): React.Node {
    return child;
  }

  loop() {
    looper = setTimeout(() => {
      this.nextSlide();
      this.loop();
    }, this.props.loopInterval);
  }

  componentDidMount() {
    let {loop} = this.props;

    if (loop) {
      this.loop();
    }
  }

  componentWillUnmount() {
    clearTimeout(looper);
  }

  navDot: SerializedStyles = css`
    margin-right: 1rem;
    color: rgba(255,255,255,0.1);
    cursor: pointer;
    font-size: 12px;
    :hover {
      color: rgba(255,255,255,0.6);
    }
    transition: color 0.5s ease;
  `;

  navDotSelected: SerializedStyles = css`color: rgba(255,255,255,0.9);`;

  navigationDot(id: number, currentIndex:number):React.Node {
    let styles = [this.navDot];
    if (id === currentIndex) {
      styles.push(this.navDotSelected);
    }
    return <FaCircle key={id} css={styles} onClick={this.switchTo.bind(this, id)}/>;
  }

  navigation():React.Node {
    const { currentIndex, slides } = this.state;
    const { navCss } = this.props;
    if (slides.length < 2) return null;
    return (
      <div css={navCss} className='text-center'>
        {slides.map((slide, count) => this.navigationDot(count, currentIndex))}
      </div>
    );
  }

  render():React.Node {

    const { currentIndex, slides } = this.state;
    const { keyboardNav, transitionLength } = this.props;

    const nextSlideIndex = currentIndex === slides.length - 1 ? 0 : currentIndex + 1;
    const previousSlideIndex = currentIndex === 0 ? slides.length - 1: currentIndex - 1;

    const wrapperCss = css`
      position: relative;
      overflow: hidden;
      > div {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        transition: transform ${transitionLength}ms ease-in-out;
        &:last-of-type {
          position: static
        }
      }
    `;

    //TODO: Swipeable is not compatible with updated react/gatsby
    return (
      <div>
      {/*<Swipeable onSwipedLeft={this.nextSlide} onSwipedRight={this.previousSlide} >*/}
        {keyboardNav && (
          <KeyboardNav onRight={this.nextSlide} onLeft={this.previousSlide} onEsc={() => {}}/>
        )}
        <div css={wrapperCss}>
          <div key={previousSlideIndex} css={css`transform: translate3d(-100%,0,0); position: absolute;`}>{slides[previousSlideIndex]}</div>
          <div key={currentIndex} css={css`transform: translate3d(0,0,0);`}>{slides[currentIndex]}</div>
          <div key={nextSlideIndex} css={css`transform: translate3d(100%,0,0); position: absolute;`}>{slides[nextSlideIndex]}</div>
          <div key='x' css={css`visibility: hidden;`}>{slides[0]}</div>
        </div>
        {this.navigation()}
        {/*</Swipeable>*/}
        </div>
    );
  }
}
