// @flow

import * as React from 'react';
import { css } from '@emotion/react';
import colors from '../../utils/config/colors';
import { MdWarning } from 'react-icons/md';

type Props = {
  children: React.Node
};

let errorStyle = css({
  'border':`solid 1px ${colors.danger}`,
  'color': colors.danger,
  'padding': '1.5rem 2rem',
  'margin':'1rem auto',
  'width':'100%',
  'borderRadius':'0.5rem',
  'fontWeight':'400',
  'backgroundColor':'rgba(255,255,255,0.8)'
});

const Error = ({children}: Props): React.Node => {
  return (
    <div css={errorStyle}>
      <MdWarning style={{ verticalAlign: 'bottom', 'marginRight':'0.5rem', 'fontSize':'2rem' }}/> {children}
    </div>
  );
};

export default Error;
