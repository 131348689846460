const camelHump = word => (word[0] || '').toUpperCase() + word.substring(1).toLowerCase();

const camelcase = (word) => {
  const words = word.split(/[ \-_.]/g);
  const first = (words.shift() || '');

 return first.toLowerCase() + words.map(camelHump).join('');
};

export default camelcase;
