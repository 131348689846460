//@flow
import * as React from 'react';
import Loader from 'react-spinners/BeatLoader';

const LoadingButton = ({children, className='', loading, onClick}: {children:React.Node, className?: string, loading?: boolean, onClick?: Function}):React.Node => {
  return (<button className={`button-primary ${className}`} style={{'display':'flex', 'justifyContent':'center'}}>
    <Loader color="#FFFFFF" size={10} loading={loading} />
   {children}
  </button>);
};

export default LoadingButton;
