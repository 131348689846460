// @flow
import * as React from 'react';
import Color from 'color';

import { Column } from '../../../utils/layout/grid';
import { css } from '@emotion/react';
import { FluidImage } from './fluid';
import type { VideoProps } from './fluid-video';

import sizes from '../../../utils/config/breakpoints.json';
import colors from '../../../utils/config/colors.json';
import fonts from '../../../utils/config/fonts.json';
import { FluidVideo } from "./fluid-video";

type ImageProps = {
  src: string,
  imageTitle?: string,
  children?: React.Node,
  size?: number,
  compact?: boolean
};

const imageCaptionCss = css`
  margin: 4rem auto;
  text-align: center;
  figure {
    margin-bottom: 0;
    img, iframe {
      width: 100%;
      margin-bottom: 0;
    }
    @media (max-width: ${sizes.breakpointMobile}) {
      width: 95%;
      margin: auto;
    }
  }
  figcaption {
    border-left: solid 2px ${Color(colors.bodyFont).lighten(0.6).hex()};
    color: ${Color(colors.bodyFont).lighten(0.3).hex()};
    font-family: ${fonts.quoteFont};
    margin: 0.5rem auto;
    padding: 0.75rem 1rem 0.75rem 1.25rem;
    text-align: left;
    max-width: ${sizes.contentWidth};
  }
`;

export const ImageCaption = ({ src, imageTitle, size, children, compact }: ImageProps): React.Node => {

  const marginCss = css`margin: 0 auto;`;

  function content() {
    return (
      <div css={compact? [imageCaptionCss, marginCss]: [imageCaptionCss]}>
        <figure>
          <FluidImage src={src} alt={imageTitle || ''}/>
          {children && <figcaption>
            {children}
          </figcaption>}
        </figure>
      </div>
    );
  }

  if (size) {
    return (
      <Column size={size}>
        {content()}
      </Column>
    );
  }
  return content();

};


type IframeProps = {
  iframeSrc: string,
  allowFullscreen?: boolean,
  children?: React.Node
};

const iframeWrapperCss = css`
 overflow: hidden
 padding-bottom: 56.25%
 position: relative
 height: 0
 iframe
   left: 0
   top: 0
   height: 100%
   width: 100%
   position: absolute
`;

export const IframeCaption = ({ iframeSrc, allowFullscreen, children }: IframeProps): React.Node => {
  return (
    <div css={imageCaptionCss}>
      <figure>
        <div css={iframeWrapperCss}>
          <iframe src={iframeSrc} allowFullScreen={allowFullscreen}/>
        </div>
        {children && <figcaption>
          {children}
        </figcaption>}
      </figure>
    </div>
  );
};

interface CaptionProps extends VideoProps {
  children?: React.Node,
  size?: number,
  compact?: boolean
}

export const VideoCaption = (props: CaptionProps): React.Node => {

  const marginCss = css`margin: 0 auto;`;
  let { compact, children, size, ...rest } = props;

  function content() {
    return (
      <div css={compact? [imageCaptionCss,marginCss]: [imageCaptionCss]}>
        <figure>
          <FluidVideo mp4 = {props.mp4} {...rest} />
          {children && <figcaption>
            {children}
          </figcaption>}
        </figure>
      </div>
    );
  }

  if (size) {
    return (
      <Column size={size}>
        {content()}
      </Column>
    );
  }
  return content();

};
