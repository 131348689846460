// @flow
import type { Node } from "react";
import React from "react";
import Layout from '../components/layouts/layout-basic';
import { Link, graphql } from "gatsby";
import { Row, Column, Readable, Section } from '../utils/layout/grid';
import { HeroAngle, HeroBlurb } from '../components/heroes';
import { ExtrudedText } from '../components/ui';
import colors from '../utils/config/colors';
import HeroBars from '../components/heroes/hero-bars';

const JobsIndex = ({ data }: {data: Object}): Node => {

  let { edges } = data.allMarkdownRemark;

  let activeJobs = edges.filter(({ node }) => {
    return node.frontmatter.status === 'active';
  });

  let contractJobs = edges.filter(({ node }) => {
    return node.frontmatter.status === 'contract';
  });

  let evergreenJobs = edges.filter(({ node }) => {
    return node.frontmatter.status === 'always';
  });

  function renderJob(post: any) {
    let { title, briefDescription } = post.node.frontmatter;
    let { slug } = post.node.fields;
    return (
      <div key={post.node.id} className='spaced'>
        <p><strong><Link to={slug}>{title}</Link></strong><br/>
        {briefDescription}{" "}<Link to={slug}>View</Link></p>
      </div>
    );
  }

  return (
    <Layout pageTitle="Lightboard Jobs" onViewEvent="jobs" disableRetarget>
      <HeroAngle backgroundColor={colors.primaryHero} heroBars={HeroBars}>
        <HeroBlurb>
          <ExtrudedText headlineText="Work With Us" extrudeColor={colors.primaryHero}/>
          <Readable left short>
            <p>We're a small team in Seattle, WA, dedicated to great design and making software our customers love.</p>
            <p>More <Link to='/about'>about us</Link>.</p>
          </Readable>
        </HeroBlurb>
      </HeroAngle>
      <Section>
        <Row>
          <Column readable className='headroom' >
            <h1 className='underscore'>Work At Lightboard</h1>
            <p>We are a 100% remote team, and have been since 2019.</p>
            <p> Most of our team is based in Seattle, but we work with folks across the United States.</p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2 className='headroom-half'>Current Openings</h2>
          </Column>
        </Row>
        <Row>
          <Column readable>
            {activeJobs && activeJobs.length > 0 && <small>Current Openings</small>}
            {activeJobs.map(post => renderJob(post))}

            {contractJobs && contractJobs.length > 0 && <small>Contract Positions</small>}
            {contractJobs.map(post => renderJob(post))}

            {evergreenJobs && evergreenJobs.length > 0 && <small>Always Looking</small>}
            {evergreenJobs.map(post => renderJob(post))}
        </Column>
      </Row>
      <Row>
        <Column readable>
          <p>Don't see the perfect job for you? Write us: <a href='mailto:jobs@lightboard.com'>jobs@lightboard.com</a></p>
        </Column>
      </Row>
    </Section>
  </Layout>);

};

export default JobsIndex;

export const pageQuery: any = graphql`
  query {
    allMarkdownRemark(filter:{ fileAbsolutePath: {regex:"/jobs/"}}) {
      edges {
        node {
          id
          frontmatter {
            title
            briefDescription
            status
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;
