//@flow
import * as React from "react";
import { Centerer } from '../../utils/layout/grid';
import { MdArrowDownward } from 'react-icons/md';
import { Panel } from '../containers/panel';
import { css } from "@emotion/react";

type  Props = {
  children?: React.Node,
  height?: string,
  fadeout?: boolean,
  more?: boolean,
  centered?: boolean,
  backgroundColor?: string,
  backgroundImageUrl?: string
}

const heroFullCss = css`
    width: 100%;
    background-color: #edf7ff;
    position: relative;
    margin-bottom: 4rem;
    color: #FFF;
    text-align: center;
    small {
      color: #FFF;
      border-bottom: dotted 1px #FFF;
      padding-bottom: 0.5rem;
      margin-bottom: 2rem;
    }
    h1, h2 {
      padding: 1rem;
    }
`;


const scrollDown = css`
  position: absolute;
  bottom: 10rem;
  left: 50%;
  text-align center;
  svg {
    height: 3rem;
    width: 3rem;
  }
  a {
    color: #fff;
    border-bottom: 0;
  }
`;

const fadeoutCss = css`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 6;
  background: linear-gradient(to bottom,rgba(255,255,255,0) 40%, rgba(255,255,255,0) 50%, #FFFFFF 100%);
`;


const HeroFull = ({ children, height='100vh', centered=true, fadeout, more=true, backgroundColor="#FFFFFF", backgroundImageUrl }: Props):React.Node => {

  function content() {
    return (
      <>
        {centered && <Centerer>{children}</Centerer>}
        {!centered && children}
        <div css={scrollDown}>
          <a href="#more">
            <h3> More</h3>
            <MdArrowDownward/>
          </a>
        </div>
      </>
    );
  }

  return (
    <div style={{'height':height}} css={heroFullCss}>
      <Panel padding={0} color='#FFFFFF' height={height} backgroundImageUrl={backgroundImageUrl} backgroundColor={backgroundColor} fadeIn>
        {content()}
        {fadeout && <div css={fadeoutCss}></div>}
      </Panel>
    </div>
  );
};

export default HeroFull;
