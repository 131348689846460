//@flow
import { fetch } from 'whatwg-fetch';

export default (url: string, opts: any): any => {
  return fetch(url, {
    ...opts,
    headers: {
      "Content-Type": "application/json"
    },
    credentials: 'include'
  }).then(response => {
    if (response.status >= 400) {
      throw new Error('http error ' + response.status + ' at ' + url);
    }
    return response;
  }, () => {});
};
