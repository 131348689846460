//@flow
import type {Node} from "react";
import React from "react";
import LayoutJobs from "../../layouts/layout-jobs";
import { Row, Column, Section } from "../../../utils/layout/grid";
import Breadcrumbs from "../../ui/breadcrumbs";

import * as Styles from "./job-post.module.styl";
import { FluidImage } from '../../ui/images/fluid';

const JobsDisclaimer = () => {
  return (
    <div className={Styles.disclaimer}>
      <p>Lightboard is an equal opportunity employer and we are committed to providing a work environment that is free from any form of discrimination.</p>
    </div>);
};

const JobsIntro = () => {
  return (
    <React.Fragment>
      <h2>About Lightboard</h2>
      <p>We are a no-nonsense design service that delivers quality craftsmanship to established brands like Autodesk, GitHub, and Microsoft, as well as innovative younger companies.</p>
      <p>We believe the traditional agency model is fundamentally broken. Our clients don't need a full-service creative agency&mdash;they've already developed their strategy and need high-quality design to bring it to life, quickly and efficiently.</p>
      <p>And that's what Lightboard does.</p>
    </React.Fragment>
  );
};

const JobsDetails = () => {
  return (
    <React.Fragment>
      <h2>Job Details</h2>
      <p>We are a 100% remote team, based in Seattle.</p>
      <p>Why Lightboard might be a fit for you:</p>
      <ul>
        <li> Great customers who love us (<a href="https://lightboard.com/reviews">read the reviews</a>).</li>
        <li> Progressive and open workplace committed to diversity, equity, and inclusion.</li>
        <li> Full health and dental benefits.</li>
        <li> Matching 401k plan.</li>
        <li> Generous vacation policy.</li>
        <li> Never work on your birthday.</li>
      </ul>
      <p> What else? Every team member receives a new Macbook Pro, 4k monitor, and all the fixings.</p>
    </React.Fragment>
  );
};

const BreezyButton = ({breezyId}: {breezyId: string}) => {
  return (
    <div className='spaced text-center'>
      <a className="button button-primary button-large" href={`https://lightboard.breezy.hr/p/${breezyId}/apply`} target="_blank" rel="noreferrer">
        Apply online
      </a>
      <p>
        <small> Applications are handled by Breezy<br/> (you will be redirected to 'lightboard.breezy.hr')</small>
      </p>
    </div>
  );
};

const HireButton = ({googleId}: {googleId: string}) => {
  return (
    <div className='spaced text-center'>
      <a className="button button-primary button-large" href={`https://hire.withgoogle.com/public/jobs/lightboardingcom/view/${googleId}`} target="_blank" rel="noopener noreferrer">
        Apply online
      </a>
      <p>
        <small> Applications handled by Hire with Google<br/> (you will be redirected to 'withgoogle.com')</small>
      </p>
    </div>
  );
};

const SendEmailButton = ({jobTitle}: {jobTitle: string}) => {
  return (
    <div className='spaced text-center'>
      <a className="button button-primary button-large" href={`mailto:jobs@lightboard.com?subject=${jobTitle} Candidate`} target="_blank" rel="noreferrer">
        Apply via Email
      </a>
      <p>
        <small>To apply, send an email to jobs@lightboard.com</small>
      </p>
    </div>
  );
};


const JobsPostLayout = ({ pageContext }: {pageContext: Object}): Node => {
  const { post } = pageContext;
  const { title, intro, fte, googleId, breezyId, instructions, metaDescription } = post.frontmatter;
  return (
    <LayoutJobs pageTitle={title} pageDescription={metaDescription}>
      <Section>
        <Row>
          <Column size={8} readable className={Styles.jobs}>
            <Breadcrumbs base={{url:'/jobs', title:'Jobs'}}>{title}</Breadcrumbs>
            <h1 className='underscore'>{title}</h1>
            <div dangerouslySetInnerHTML={{ __html: intro }}/>
            <JobsIntro/>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
            {fte && <JobsDetails />}
            {instructions && <div className='spaced-half' dangerouslySetInnerHTML={{ __html: instructions }}/>}
            {breezyId ? <BreezyButton breezyId={breezyId}/> :  <SendEmailButton jobTitle={title}/>}
            <JobsDisclaimer/>
          </Column>
          <Column size={4}>
            <FluidImage src='illustrations/documents.png' alt="Documents illustration" className='headroom' />
          </Column>
        </Row>
      </Section>
    </LayoutJobs>
  );
};

export default JobsPostLayout;
