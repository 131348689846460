//@flow
import * as React from 'react';
import CaptureForm from '../capture_form';
import Error from '../../../components/containers/error';
import useTracking from '../../../utils/track/use_tracking';
import LoadingButton from '../buttons/loading-button';
import ConfettiDrop from '../../ui/confetti-drop';
import Emoji  from 'react-emoji-render';
import { FluidImage } from '../../ui/images/fluid';

type Props = {
  children?: React.Node,
  title?: string,
  buttonLabel?: string,
  formLocation?: string
;}

const ContactForm = ( {
    children,
    title = "Contact Us",
    buttonLabel = 'Contact Lightboard',
    formLocation
  }: Props):React.Node => {

  const track = useTracking();

  function onFocus(fieldName: string) {
    track.event('form-focus', { 'location': formLocation, 'field': fieldName });
  }

  return (
    <CaptureForm formLocation={formLocation} render={({ phase, email, setEmail, message, setMessage, errorMessage, onSubmit }) => {

      if (phase==='submitted') {
        return (
          <>
            <ConfettiDrop/>
            <div className='form-friendly text-center'>
              <FluidImage src="illustrations/documents.png" width={250} style={{'margin':'6rem auto 2rem auto'}}/>
              <h2><Emoji text="😍" />{" "}<span>Awesome! Thank you for writing.</span></h2>
              <p>You'll hear from us shortly.</p>
            </div>
          </>);
      }

      return (
        <form onSubmit={onSubmit} className='form-friendly'>
          {children}
          {!children && <><h2>{title}</h2><p>We'd love to hear from you.</p></>}
          <div className='spaced'>
            <label>Your Email Address</label>
            <input type="email" placeholder="Your work email" onFocus={onFocus.bind(null, 'email')} onChange={e => setEmail(e.target.value)} />
          </div>
          <div className='footroom'>
            <label>Your Message</label>
            <textarea onChange={e => setMessage(e.target.value)} onFocus={onFocus.bind(null, 'contact')} placeholder="What can we do for you?" rows={6} />
          </div>
          <LoadingButton className='button-giant' loading={(phase==='submitting')} onClick={onSubmit}> {buttonLabel} </LoadingButton>
      {phase === 'error' && <Error>{errorMessage || <span>Shoot, we had a problem submitting your message.</span>}</Error>}
          <p className='spaced'>Or write us at <a href="mailto:hello@lightboard.com">hello@lightboard.com</a></p>
        </form>
      );
    }}/>
  );
};

export default ContactForm;
