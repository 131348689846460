//@flow
import type { Node } from "react";
import React from "react";

import Layout from '../components/layouts/layout-basic';
import { FluidImage } from '../components/ui/images/fluid';
import { HeroAngle, HeroBlurb } from '../components/heroes';
import EmailForm from '../components/forms/email';

import { ExtrudedText } from '../components/ui';

import { Spread, SpreadPanel, Readable } from "../utils/layout/grid";
import colors from '../utils/config/colors';
import HeroBars from '../components/heroes/hero-bars';
import PortfolioCallout from '../components/pages/shared/portfolio-callout';
import HowItWorks from '../components/pages/shared/how-it-works';
import StartCta from '../components/pages/shared/start-cta';
import BeforeAfter from "../components/pages/shared/before-after";
import LogosAndQuotes from "../components/pages/shared/logos-and-quotes";

const DifferencePage = ({ data }: { data: Object }): Node => {

  let primaryHeroColor = colors.primaryHero;
  let secondaryHeroColor = colors.secondaryHero;

  return (
    <Layout pageTitle="Lightboard Difference">
      <HeroAngle backgroundColor={primaryHeroColor} patchColor={secondaryHeroColor} heroBars={HeroBars}>
        <HeroBlurb>
          <ExtrudedText headlineText="We're Different" extrudeColor={primaryHeroColor} />
          <Readable left>
            <h1>Marketing design, on-demand.</h1>
            <p>Lightboard gives your team superpowers.</p>
            <p>Experienced designers and account managers, powered by our own world-class collaboration software—just a click away.</p>
            <div className='spaced'>
              <EmailForm formLocation="difference-hero" buttonLabel="Learn More" />
            </div>
          </Readable>
        </HeroBlurb>
      </HeroAngle>
      <HeroAngle backgroundColor={secondaryHeroColor}>
        <Spread fluid vcenter flopped>
          <SpreadPanel>
            <ExtrudedText headlineText="Our DNA" extrudeColor={secondaryHeroColor} />
            <Readable left>
              <h2>Founded by a designer and engineer.</h2>
              <p>Our founders both worked at agencies before switching to startups. At our roots, we understand how software can enable deep collaboration between teams more efficiently than old-fashioned phone calls and emails.</p>
              <p>We've taken that foundation and built Lightboard around our collaboration software. We can efficiently connect you, your team, our account manager and team of designers on small projects.</p>
            </Readable>
          </SpreadPanel>
          <SpreadPanel>
            <FluidImage src='home/software.png' alt="Lightboard Software" />
          </SpreadPanel>
        </Spread>
      </HeroAngle>
      <BeforeAfter/>
      <HowItWorks />
      <LogosAndQuotes/>
      <PortfolioCallout />
      <StartCta />
    </Layout>);
};

export default DifferencePage;
