// @flow

import * as React from 'react';

export type AppContextType = {
  fetched: boolean,
  userId: string,
  registered: boolean,
  bucketId: number,
  retarget: boolean,
  track: boolean,
  avatarUrl?: string,
  chat: boolean,
  refresh: () => void,
  disableRetarget?: boolean,
  onViewEvent?: () => void
};

const AppContext: React$Context<AppContextType> = React.createContext<AppContextType>({
  fetched: false,
  userId: '',
  bucketId: 1,
  registered: false,
  track: false,
  chat: false,
  retarget: false,
  refresh: () => {}
});

export default AppContext;
