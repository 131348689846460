//@flow
export const ALPHA: string[] = [
  ' ',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];
export const NUMERIC = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
export const PUNCTUATION = [
  '!',
  '@',
  '#',
  '$',
  '(',
  ')',
  '^',
  '-',
  '_',
  '+',
  '&',
  '=',
  ';',
  ':',
  '*',
  "'",
  '"',
  ',',
  '.',
  '<',
  '>',
  '/',
  '\\',
  '?',
  '°',
  '%',
];
