//@flow
import * as React from "react";
import { Section, Row, Column } from '../../../utils/layout/grid';

type Faq = {
  id:string,
  title: string,
  body: string
};

const FaqModule = ({faq}:{faq:Faq}): React.Node => {
  return (
    <Column size={4}>
      <div style={{'padding': '0 2rem 0 0'}}>
        <h3>{faq.title}</h3>
        <p>{faq.body}</p>
      </div>
    </Column>
  );
};

const MembershipFaqs = ({faqs}:{faqs: Faq[]}): React.Node => {
    return (
      <Section>
        <Row balancedGutters>
          {faqs.map((faq:Faq) => <FaqModule faq={faq} key={faq.id}/>)}
        </Row>
      </Section>
    );
};

export default MembershipFaqs;
