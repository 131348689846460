//@flow
import * as React from "react";
import { Section, Row, Column } from "../../../../utils/layout/grid";
import { useProjectFaqs } from '../../../../utils/graphql/faqs/projects';

import type { Faq} from '../../../../utils/types';

export default function FrequentQuestions(): React.Node {
  let faqs = useProjectFaqs();

  function renderFaq(faq:Faq) {
    return (
    <Column size={4} key={faq.id}>
      <div className='padded-x'>
        <h4>{faq.title}</h4>
        <p>{faq.body}</p>
      </div>
    </Column>);
  }


  return (
    <>
      <Section padded>
        <Row>
          <Column textCenter>
            <h2>FAQs</h2>
          </Column>
        </Row>
      </Section>
      <Section gutters>
        <Row balancedGutters>
          {faqs.map(faq => renderFaq(faq))}
        </Row>
      </Section>
    </>
  );
}
