// @flow
import combineStyles from '../../utils/combine_styles';

type SizeValue = number;

type ColumnSettings = {
  flopped?: boolean,
  flex?: boolean,
  min?: boolean,
  max?: boolean,
  half?: boolean,
  size?: SizeValue,
  mobileSize?: SizeValue,
  gutters?: boolean,
  balancedGutters?: boolean,
  relative?: boolean,
  textCenter?: boolean,
  mobileBreakpoint: number
};

type PaddingSettings = {
  balancedGutters?: boolean,
  gutters?: boolean,
  size?: SizeValue,
  mobileSize?: SizeValue,
  mobileBreakpoint: number,
  min?: boolean,
  max?: boolean,
  flopped?: boolean,
  half?: boolean
}

const normalizeSizeValue = (size: number) => size >= 1 ? size / 12 : size;

const width = (size: SizeValue) => `${ normalizeSizeValue(size) * 100}%`;

const columnPadding = ({balancedGutters, gutters, size, mobileSize, min, max, flopped, half}: PaddingSettings) => {
  let mobile = {
    padding: '',
    width: '',
    display: '',
    marginRight: '',
    '&:first-of-type': {
      paddingLeft: ''
    },
    '&:last-of-type': {
      paddingRight: ''
    }

  };
  let desktop = {
    padding: '',
    width: '',
    display: '',
    marginRight: '',
    '&:nth-of-type(1)': {},
    '&:nth-of-type(2)': {},
    '&:first-of-type': {
      paddingLeft: ''
    },
    '&:last-of-type': {
      paddingRight: ''
    }
  };

  if (!(min || max)) {
    mobile.width = width(mobileSize || 12);
    desktop.width = size ? width(size) : '100%';
  }

  if (mobileSize === 0) {
    mobile.display = 'none';
    desktop.display = 'block';
  }
  if (size === 0) {
    desktop.display = 'none';
  }

  if (min) {
    mobile.marginRight = '1rem';
  }

  if (half) {
    const rightStyles = {
      order: 2,
    };
    const leftStyles = {
      order: 1,
      textAlign: 'right',
    };
    desktop['&:nth-of-type(1)'] = flopped ? rightStyles : leftStyles;
    desktop['&:nth-of-type(2)'] = flopped ? leftStyles : rightStyles;
  }

  if (gutters) {
    mobile.padding = '0 0 1rem 0';
    desktop.padding = '0 1rem 2rem 1rem';

    desktop['&:first-of-type'] = {
      paddingLeft: '0'
    };
    desktop['&:last-of-type'] = {
      paddingRight: '0'
    };
  }

  if (balancedGutters) {
    desktop.padding = '0 1rem 2rem 1rem';

    desktop['&:first-of-type'] = {
      paddingLeft: '1rem'
    };
    desktop['&:last-of-type'] = {
      paddingRight: '1rem'
    };
  }

  return [mobile, desktop];
};

const columnFlex = ({min, max, size }: { min: any, max: any, size: any }) => {
  if (min) return [{}, {}];

  if (!size && !max) {
    return [{
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: 'auto'
    }, {
      flexBasis: 0,
      flexGrow: 1,
      flexShrink: 0,
    }];
  }
  return [{
    flexGrow: max ? 1 : 0,
    flexShrink: 1,
    flexBasis: max ? 0 : 'auto',
  }];
};

const columnPosition = ({relative}: {relative?: boolean}) => [( relative ? {position: 'relative'} : {})];


type ColumnAlignmentSettings = {
  textCenter?: boolean;
};

const columnAlignment = ({ textCenter }: ColumnAlignmentSettings) => [
  textCenter ? { textAlign: 'center' } : {},
  {}
];

const columnStyles = (
  {
    half,
    min,
    max,
    size,
    mobileSize = 12,
    gutters,
    balancedGutters,
    relative,
    textCenter,
    flopped,
    mobileBreakpoint = 900
  }: ColumnSettings,
): { ... } => {
  const styles = combineStyles(
    mobileBreakpoint,
    [
      columnFlex({ min, max, size }),
      columnPadding({ gutters, balancedGutters, mobileBreakpoint, mobileSize, size, min, max, flopped, half }),
      columnPosition({ relative }),
      columnAlignment({ textCenter }),
    ]
  );

  return styles;
};

export default columnStyles;
