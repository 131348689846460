//@flow
import type { Node } from "react";
import React from "react";
import { Link } from "gatsby";
import { Readable, Section, Row, Column } from "../../../utils/layout/grid";
import LayoutArticle from "../../layouts/layout-article";
import { FluidImage } from '../../ui/images/fluid';
import HelpLinks from './help-links';
import PortfolioCallout from '../shared/portfolio-callout';
import StartCta from '../shared/start-cta';

const HelpPage = ({ pageContext }: {pageContext: Object}): Node => {

  const { post } = pageContext;
  const { frontmatter } = post;
  const footer = <><HelpLinks/><PortfolioCallout/><StartCta/></>;
  return (
    <LayoutArticle pageTitle={frontmatter.title} pageDescription={frontmatter.briefDescription} footer={footer}>
      <Section>
        <Row>
          <Column>
            <small><Link to="/help">Help</Link> > {frontmatter.title}</small>
            <Readable>
              <div className='spaced'>
                <FluidImage src={`help/${frontmatter.imageUrl}.png`} />
              </div>
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </Readable>
          </Column>
        </Row>
      </Section>
    </LayoutArticle>
  );
};

export default HelpPage;
