//@flow
import type { Node } from "react";
import React from "react";
import ContactForm from "../components/forms/contact";

import { Column, Readable, Row, Section } from "../utils/layout/grid";
import { useSiteMetadata } from "../utils/graphql/site_metadata";
import LayoutLifted from "../components/layouts/layout-lifted";
import { AnimateSharedLayout, motion } from "framer-motion";

import SpeechBubble from "../images/svg/speech-bubble.svg";
import DottedRing from "../images/svg/dotted-ring.svg";
import RedTriangle from "../images/svg/red-triangle.svg";
import BlueCircle from "../images/svg/blue-circle.svg";

const HeroAnimation =(): Node => {
  return (
    <div style={{position: "absolute", left: 0, top: 0, width: "100%", height: '60vh', zIndex: 1, backgroundColor: "#157ddd"}}>
      <AnimateSharedLayout>
        <motion.div
          initial = {{ x:250 }}
          animate = {{
            rotate: [0, 360],
            x:[250, 250]
          }}
          transition={{
            repeat: Infinity,
            type: "tween",
            ease: "linear",
            duration: 18,
          }}
          style={{width:"125px", position: "absolute"}}
        >
          <DottedRing width="125"/>
        </motion.div>
        <motion.div
          initial = {{ x:"66vw", y: "6vh"}}
          animate = {{
            rotate: [0, -360],
            y: ["6vh", "12vh"],
            x:["66vw", "66vw"]
          }}
          transition={{
            repeat: Infinity,
            type: "tween",
            ease: "linear",
            duration: 72,
          }}
          style={{width:"250px", position: "absolute"}}
        >
          <DottedRing width="250"/>
        </motion.div>
        <motion.div
          initial = {{ x:"-15%"}}
          animate = {{
            x: ["-15%", "60%", "115%"],
            y:["50%","55%","52%","58%", "52%"],
            rotate: [-3, 5, -6, 4]
          }}
          transition={{
            repeat: Infinity,
            type: "tween",
            ease: "linear",
            duration: 120,
          }}
        >
          <SpeechBubble style={{width:'200px'}}/>
        </motion.div>
        <motion.div
          style= {{ width: '100px', position: 'absolute'}}
          initial = {{ x:"-15%"}}
          animate = {{
            x: [ "115vw", "60vw", "-15vw"],
            y:["20vh","25vh","22vh","28vh", "22vh"],
            rotate: [-30, 90, -60, 40]
          }}
          transition={{
            repeat: Infinity,
            type: "tween",
            ease: "linear",
            duration: 90,
          }}
        >
          <RedTriangle width="100"/>
        </motion.div>
        <motion.div
          style= {{ width: '100px', position: 'absolute'}}
          initial = {{ x:"-15%"}}
          animate = {{
            x: [ "115vw", "60vw", "-15vw"],
            y:["10vh","15vh","12vh","18vh", "12vh"],
            rotate: [-30, 90, -60, 40]
          }}
          transition={{
            repeat: Infinity,
            type: "tween",
            ease: "linear",
            duration: 110,
          }}
        >
          <BlueCircle width="100"/>
        </motion.div>

      </AnimateSharedLayout>
    </div>
  );
};

const ContactPage = (): Node => {
  let { lbAppHost } = useSiteMetadata();
  let portal={'url':'site/contact-us.png', 'color':'#e4f5fe'};
  return (
    <>
      <HeroAnimation/>
      <LayoutLifted pageTitle="Contact Us" portal={portal} heroHeight="60vh" pageDescription="Send a contact message to Lightboard." onViewEvent="contact">
        <Section>
          <Row>
            <Column>
              <Readable className='headroom'>
                <ContactForm formLocation="contact">
                  <h1>Contact Lightboard.</h1>
                  <p>If you'd like an estimate, please <a href={`${lbAppHost}/start`}>create a project request</a>.</p>
                  <p>To learn more about how we work, or request a demo, please fill out this form.</p>
                </ContactForm>
              </Readable>
            </Column>
          </Row>
        </Section>
        <Section spaced>
          <Row>
            <Column>
              <div style={{height:'400px'}}/>
            </Column>
          </Row>
        </Section>
      </LayoutLifted>
    </>
  );
};

export default ContactPage;
