// @flow
import * as React from 'react';
import styled from '@emotion/styled';
import type { CommonLayoutProps  } from '../../types';
import SpreadContext from './context';
import wrapperStyles from './wrapper_styles';
import pick from '../../utils/pick';

interface SpreadProps extends CommonLayoutProps {
  fluid?: boolean,
  flopped?: boolean,
  alignCenter?: boolean,
  vcenter?: boolean,
}

const StyledSpread = styled.section(wrapperStyles);

const getContext = pick.bind(null, [ 'flopped', 'gutters', 'vcenter', ]);

export const Spread = (props: SpreadProps): React.MixedElement => {
  const {...rest} = props;
  return (
    <SpreadContext.Provider value={getContext(props)}>
      <StyledSpread {...rest} />
    </SpreadContext.Provider>
  );
};
