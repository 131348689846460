//@flow
import { useStaticQuery, graphql } from 'gatsby';
type siteMetadata = {
  environment: string,
  title: string,
  lbDomain: string,
  lbAppHost: string,
  lbAssetHost: string,
  facebookPixelId: string,
  mixpanelToken: string,
}

export const useSiteMetadata = (): siteMetadata => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            environment
            title
            lbDomain
            lbAppHost
            lbAssetHost
            mixpanelToken
            facebookPixelId
          }
        }
      }
    `
  );
  return (site.siteMetadata: siteMetadata);
};
