//@flow
import * as React from "react";
import { Column, Row, Section } from "../../../utils/layout/grid";
import EmailForm from '../../../components/forms/email/index';

const RequestDownloads = (): React.Node => {
  return (
    <Section padded>
      <Row>
        <Column readable textCenter>
          <h1>Want to be notified about new downloads?</h1>
          <p>Add your email to be the first to know.</p>
          <EmailForm buttonLabel="Sign Up" formLocation="request-downloads"/>
        </Column>
      </Row>
    </Section>);
};

export default RequestDownloads;
