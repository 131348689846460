//@flow
import type { Node } from "react";
import React from "react";
import { Link } from "gatsby";
import { Column, Spread, SpreadPanel, Readable, Row, Section } from "../../utils/layout/grid";
import { GiantButton } from '../../components/forms/buttons/index';
import PortfolioCallout from '../../components/pages/shared/portfolio-callout';
import Layout from '../../components/layouts/layout-basic';
import { PricingBadge }  from '../../components/pages/pricing';


const HourlyPricingPage =  (): Node => {

  return (
    <Layout pageTitle="Lightboard Hourly Pricing" solidMenu onViewEvent="pricing">
      <Spread gutters flopped spaced vcenter style={{padding: '40px 40px 20px 40px'}}>
        <SpreadPanel>
            <PricingBadge>
              <h1>Hourly</h1>
              <h2 className='pricing'>$150/hr</h2>
              <p>50% on project start, balance upon completion. </p>
            </PricingBadge>
        </SpreadPanel>
        <SpreadPanel>
          <Readable right>
            <h2>Hourly Pricing</h2>
            <p>If you're not ready for a Membership, we can work on a project-to-project basis, billed hourly at $150/hr.</p>
            <p>Our hourly rate is more expensive than our Membership, but comes without a long-term commitment-perfect if you have a one-time need for design. Create a request and we'll provide an estimate and delivery date. If it's our first time working with you, we'll usually schedule a call to learn more about you, your company, and your project to make sure we can deliver what you're expecting.</p>
            <p>We work fast; we provide estimates same business day, and we can usually start work within one business day of project approval. We require 50% payment to start, with the balance upon completion. We usually budget for three rounds of revisions. At the end of the project, we'll deliver the working files (i.e. layered Photoshop, packaged InDesign) so you can make changes in the future.</p>
          </Readable>
        </SpreadPanel>
      </Spread>
      <Section padded>
        <Row>
          <Column readable>
            <div className='text-center'>
              <h2> Ready to start?</h2>
              <p>We'll give you an estimate same business day.</p>
              <Link to="/request-estimate">
                <GiantButton>Request an estimate</GiantButton>
              </Link>
            </div>
          </Column>
        </Row>
      </Section>
      <PortfolioCallout/>
    </Layout>
  );
};


export default HourlyPricingPage;
