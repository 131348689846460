//@flow
import React from "react";
import type { Node } from "react";


import { Column, Readable, Row, Section } from "../../utils/layout/grid";
import { PullQuote, ImageCaption, LogoImage } from "../../components/ui";
import KeyStats from "../../components/pages/case-studies/stat-boxes";

import { useCaseStudiesIndex } from '../../utils/graphql/case_studies';
import LayoutCaseStudy from '../../components/layouts/layout-case-study';

const Rescue =  (): Node => {

  let caseStudy = useCaseStudiesIndex('rescue');

  return (
    <LayoutCaseStudy caseStudy={caseStudy} heroUrl='case-studies/rescue/hero.png'>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h1>Meaningful Design</h1>
              <p>
                Emily Martin, Digital Advertising Officer at the International Rescue Committee, uses Lightboard as an extension of her design team to produce display ads for digital and social media platforms.
              </p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <LogoImage src='logos/rescue.png'  alt="IRC logo" className="headroom" style={{'height':'120px', 'width':'90px'}}/>
              <h2>About IRC</h2>
              <p>Founded at Albert Einstein's request in 1933, the International Rescue Committee is a nonprofit based in New York City that helps people whose lives and livelihoods are shattered by conflict and disaster to survive, recover and regain control of their future.</p>
              <p>The IRC responds to the world's worst humanitarian crises, helping to restore health, safety, education, economic well-being and power to people devastated by conflict and disaster. With over 8,000 staff members currently working in over 40 countries, the IRC is committed to making sure that 90 cents of every dollar goes directly to beneficiaries.</p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <PullQuote src="case-studies/rescue/emily-martin.jpg" name='Emily Martin' title='Digital Advertising Officer'>
                <p>We're a large nonprofit with in-house design resources, but it's still difficult for us to turn around the large number of design projects we need. Lightboard has been a great help to us.</p>
              </PullQuote>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h2>Business Situation</h2>
              <p>For the IRC, digital advertising is one of the most effective ways to bring in new donors—and remind existing donors to give again. Emily and her team need to be able to respond to specific international crises as they happen with fresh, relevant digital creative that raises awareness and attracts new donations.</p>
              <p>But their in-house design team didn't have the resources to handle such a high volume of new marketing design as crises escalate around the world.</p>
              <h2>Discovery</h2>
              <p>Emily's supervisor discovered Lightboard through an ad on Facebook. Emily reached out to learn more, and loved how Lightboard's platform would allow her to collaborate and coordinate with her team to provide feedback and approve designs in an efficient, streamlined way. She ordered her first project in August 2016.</p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid>
          <Column>
            <ImageCaption src="case-studies/rescue/irc-band.png"/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h2>Path to Success</h2>
              <p>The IRC started using Lightboard to create their display ads. Emily was impressed with Lightboard's experience integrating brand guidelines and ability to adhere to ad templates to ensure a cohesive, on-brand look. She quickly began to rely on them to produce a high volume of digital and social advertising variations to test online, and the partnership became a regular part of the IRC's marketing workflow.</p>
              <h2>Benefits</h2>
              <h4>Lightning-Fast Adaptable Marketing</h4>
              <p>Because of the IRC's mission, it's imperative that the nonprofit immediately galvanize around international crises as they happen—and that includes raising awareness and donations for specific conflicts and disasters through digital advertising. With Lightboard's always-available web-based platform, Emily and her team can submit a new job request at any time and quickly generate new ad sets to react to any global situation.</p>
            </Readable>
            <ImageCaption src="case-studies/rescue/mothers-day.png">
              Mother's day display ads.
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h2>Example: South Sudan Famine</h2>
              <p>In early 2017, the United Nations proclaimed the famine in South Sudan to be the largest humanitarian crisis since the agency was founded in 1945, with 100,000 people close to starvation, another 1 million more at risk, and 30 million total facing man-made food insecurity.</p>
              <p>Civil war in the region has made humanitarian access a huge challenge, and the IRC needed to quickly run an ad campaign to gather more donations. Emily requested the project on a Monday, and by Wednesday Lightboard completed the project so that the IRC was able to start running ads. This speedy turnaround helped IRC quickly and effectively focus on helping save children from starvation.</p>
            </Readable>
            <ImageCaption src="case-studies/rescue/south-sudan.png">
              Display ads for the famine in South Sudan.
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <PullQuote src="case-studies/rescue/emily-martin.jpg" name='Emily Martin' title='Digital Advertising Officer'>
                <p>We're no longer constrained by creative production when we have something new we need to raise awareness around. Lightboard gives us more flexibility to try out different designs and messaging.</p>
              </PullQuote>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h2>Professional Design Resources</h2>
              <p>Like so many organizations, the IRC's design team is kept busy creating big campaigns for major initiatives and they struggle to fit high-priority, quick turnaround projects into their queue. With Lightboard, Emily and her team needn't worry about whether or not their in-house designers have time for a project—they know they can submit a design job to Lightboard through the online platform any time and Lightboard's team will quickly address and complete the project.</p>
              <h2>More Effective Marketing Campaigns</h2>
              <p>With Lightboard as a fast, cost-effective solution for display ad design, the IRC has more flexibility to test a broader array of different messages and images in their digital campaigns and see what resonates most strongly with their donors.</p>
              <p>Learn more about IRC at <a href='https://rescue.org/'>Rescue.org</a></p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row className='headroom'>
          <Column>
            <KeyStats stats={caseStudy.stats} />
          </Column>
        </Row>
      </Section>
    </LayoutCaseStudy>
  );
};

export default Rescue;
