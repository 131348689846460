//@flow
import * as React from 'react';
import { Section, Row, Column } from '../../../utils/layout/grid';
import { GiantButton } from '../../forms/buttons/index';
import { useSiteMetadata } from '../../../utils/graphql/site_metadata';

const StartCta = ():React.Node => {
  let { lbAppHost } = useSiteMetadata();
  return (
    <Section padded>
      <Row>
        <Column readable textCenter>
          <h1>Get Started</h1>
          <p>Create an account to start setting up your brand.</p>
          <a href={`${lbAppHost}/register`}><GiantButton>Register</GiantButton></a>
          <p className='spaced'>Not sure what you need? <a href={`${lbAppHost}/start`}>See what we can do</a>.</p>
        </Column>
      </Row>
    </Section>
  );
};

export default StartCta;
