// @flow
import * as React from 'react';

export interface VideoProps {
  mp4: string,
  webm?: string,
  autoPlay?: boolean,
  controls?: boolean,
  loop?: boolean,
  muted?: boolean,
  poster?: string,
  height?: any,
  width?: any
}

export const FluidVideo = ({mp4, webm, poster, autoPlay=true, controls=false,  loop=true, muted=true, width="100%"}: VideoProps): React.Node => {
  return (
    <video autoPlay={autoPlay} controls={controls} playsInline loop={loop} muted={muted} poster={poster} style={{"maxWidth": width, "width": width}}>
      <source src={mp4} type='video/mp4' />
      {webm && <source src={webm} type='video/webm' />}
    </video>
  );
};

