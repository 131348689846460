//@flow
import React from "react";
import type { Node } from "react";

import { Column, Row, Section } from "../../utils/layout/grid";
import { ImageCaption, LogoImage } from "../../components/ui";
import { FillerText, FillerQuote } from '../../components/placeholders/index';
import LayoutCaseStudy from '../../components/layouts/layout-case-study';
import { useCaseStudiesIndex } from '../../utils/graphql/case_studies';

const Balena =  (): Node => {

  let caseStudy = useCaseStudiesIndex('balena');

  return (
    <LayoutCaseStudy caseStudy={caseStudy} heroUrl='case-studies/balena/hero.png'>
      <Section>
        <Row>
          <Column readable>
            <h2>Quick and efficient</h2>
            <p><FillerText/></p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <FillerQuote/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <LogoImage src="logos/balena.png" width={300}/>
            <p><FillerText/></p>
            <h2>Business Situation</h2>
            <p><FillerText/></p>
            <p><FillerText/></p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/balena/aleta-concepts.png'/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/balena/balena-concepts.png'/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>Path to Success</h2>
            <p><FillerText/></p>
            <p><FillerText/></p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid >
          <ImageCaption size={6} src='case-studies/balena/color-right.png'/>
          <ImageCaption size={6} src='case-studies/balena/fin-right.png'/>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>Path to Success</h2>
            <p><FillerText/></p>
            <p><FillerText/></p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/balena/fin-center.png'/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>Path to Success</h2>
            <p><FillerText/></p>
            <p><FillerText/></p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/balena/final-logo.png'>
              Lorem ipsum dolor amet
            </ImageCaption>
          </Column>
        </Row>
        <Row>
          <Column readable>
            <h2>Discovery</h2>
            <p><FillerText/></p>
            <p><FillerText/></p>
          </Column>
        </Row>
        <Row>
          <Column>
            <ImageCaption src='case-studies/balena/logomark-concepts.png'>
              Lorem ipsum dolor amet
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>Discovery!</h2>
            <p><FillerText/></p>
            <p><FillerText/></p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid >
          <Column>
            <ImageCaption src='case-studies/balena/logomark-refinement.png'/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>Discovery</h2>
            <p><FillerText/></p>
            <p><FillerText/></p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid >
          <Column>
            <ImageCaption src='case-studies/balena/product-logos.png'/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>Discovery</h2>
            <p><FillerText/></p>
            <p><FillerText/></p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row >
          <Column>
            <ImageCaption src='case-studies/balena/rubus-concepts.png'>
              Lorem ipsum dolor amet
            </ImageCaption>
          </Column>
        </Row>
      </Section>
    </LayoutCaseStudy>
  );
};

export default Balena;
