//@flow
import type { Node } from "react";
import React from "react";

import Layout from '../components/layouts/layout-basic';
import ContactForm from '../components/forms/contact/index';
import { Section, Row, Column } from '../utils/layout/grid';
import PortfolioCallout from '../components/pages/shared/portfolio-callout';
import CaseStudyCarousel from '../components/pages/shared/case-study-carousel';
import { FluidImage } from '../components/ui/images/fluid';

const RequestDemo = (): Node => {
  return (
    <Layout pageTitle='Schedule a Demo with Lightboard' solidMenu onViewEvent="request-demo" >
      <Section padded>
        <Row>
          <Column readable>
            <div className='text-center'>
              <FluidImage src='illustrations/documents.png' width={320} style={{'margin':'auto'}}/>
            </div>
            <ContactForm formLocation='request-demo' buttonLabel='Request Demo'>
              <div className='spaced text-center'>
                <h1>Schedule a Demo</h1>
                <p>We'd love to show you how our platform works and what we can do.</p>
                <p>Please fill out the form below, and we'll be in touch to schedule a demo.</p>
              </div>
            </ContactForm>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column readable textCenter>
            <h1>What's it like working with Lightboard?</h1>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <CaseStudyCarousel/>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <PortfolioCallout/>
      </Section>
    </Layout>);
};

export default RequestDemo;

