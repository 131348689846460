//@flow
import * as React from 'react';
import { Section, Row, Column, Centerer } from '../../../utils/layout/grid';
import { Panel } from '../../containers/panel';
import { Link } from 'gatsby';
import EmailForm from '../../forms/email/index';
import { css } from '@emotion/react';
import colors from '../../../utils/config/colors';

const ProfileBlock = ({imageUrl, to, children} : {imageUrl:string, to: string, children: React.Node}) => {

  let panelCss = css`
    border: solid 0.5rem rgba(0,0,0,0);
    :hover {
      border-color: ${colors.primaryHero};
      opacity: 0.9;
    }
    transition: border 0.5s ease, opacity 0.5s ease;
    a, a:visited, a:hover {
      color: #FFFFFF;
      border-bottom: 0px;
      display: block;
      width: 100%;
      height: 300px;
    }
    small {
      color: #FFFFFF;
      line-height: 2rem;
      border-bottom: dotted 1px #FFFFFF;
      display: block;
      margin: 1rem auto;
      padding: 1rem 2rem;
    }
    button {
      margin: 1rem auto;
    }

  `;

  return (
    <Panel css={panelCss} height={450} padding={0} backgroundImageUrl={imageUrl}>
      <Link to={to}>
        <Centerer>
          <small>How we help</small>
          {children}
          <button>Learn More</button>
        </Centerer>
      </Link>
    </Panel>
  );
};

const CustomerProfiles = ({linkPrefix='design-for'}: {linkPrefix?:string}): React.Node => {
  return (
    <Section>
      <Row>
        <Column>
          <h1 className='spaced text-center'>How We Help</h1>
        </Column>
      </Row>
      <Row fluid gutters>
        <Column relative size={4}>
          <ProfileBlock imageUrl='customer-profiles/sales-teams.jpg' to={`/${linkPrefix}/sales-teams`}>
            <h2>Sales Teams</h2>
          </ProfileBlock>
        </Column>
        <Column relative size={4}>
          <ProfileBlock imageUrl='customer-profiles/marketing-teams.jpg' to={`/${linkPrefix}/marketing-managers`}>
            <h2>Marketing Teams</h2>
          </ProfileBlock>
        </Column>
        <Column relative size={4}>
          <ProfileBlock imageUrl='customer-profiles/agencies.jpg' to={`/${linkPrefix}/agencies`}>
            <h2>Agencies</h2>
          </ProfileBlock>
        </Column>
      </Row>
      <Row>
        <Column readable textCenter className='spaced-2x'>
          <h2>Request A Demo.</h2>
          <p>Learn how we can help your team with marketing design.</p>
          <EmailForm buttonLabel="Request Demo"/>
        </Column>
      </Row>
    </Section>);
};

export default CustomerProfiles;
