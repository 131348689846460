//@flow
import { useStaticQuery, graphql } from 'gatsby';
import type { Review } from '../types/review';

let reviews;

export const useReviews = ():Review[] => {
  const { allReviews } = useStaticQuery(
    graphql`
      query {
        allReviews: allReviewsJson {
          edges {
            node {
              ...reviewDetails
            }
          }
        }
      }
    `
  );
  if (!reviews) {
    reviews = allReviews.edges.map(review => (
      {
        ...review.node
      }));
  }
  return reviews;
};

export const reviewFragment: any = graphql`
  fragment reviewDetails on ReviewsJson {
    id
    name
    company
    role
    quote
    seattle
    headshotImg: headshot {
      ...headshotImage
    }
  }
`;
