import type { PaddingAndSpacingProps } from '../types';

const paddingAndSpacing = ({padded, spaced}: PaddingAndSpacingProps) => {
  const styles = {};
  if (padded === true) {
    // this replicates daisy grid behavior; not sure if this makes sense as a rule
    styles.padding = '6rem 0';
  }
  else if (typeof padded === 'number') {
    styles.padding = `${padded * 2}rem 0`;
  }

  if (spaced === true) {
    styles.margin = '2rem auto';
  }
  else if (typeof spaced === 'number') {
    styles.margin = `${spaced * 2}rem auto`;
  }

  return styles;
};

export default paddingAndSpacing;
