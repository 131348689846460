//@flow
import React from "react";
import type { Node } from "react";


import { Column, Row, Section, } from "../../utils/layout/grid";
import { ImageCaption, LogoImage } from "../../components/ui";
import LayoutCaseStudy from '../../components/layouts/layout-case-study';
import { useCaseStudiesIndex } from '../../utils/graphql/case_studies';
import CompareImage from '../../components/ui/images/compare-image';
import { Link } from 'gatsby';

const LineShapeSpace =  (): Node => {

  let caseStudy = useCaseStudiesIndex('line-shape-space');

  return (
    <LayoutCaseStudy caseStudy={caseStudy} heroUrl='case-studies/line-shape-space/hero-v3.png'>
      <Section>
        <Row>
          <Column readable>
            <h2>Editorial Illustrations</h2>
            <p>We worked with the great folks at Autodesk's Line//Shape//Space blog to bring their articles to life with custom illustrations. With each new article, we dove into the topic and created illustrations that helped tell the story.    </p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column readable>
            <LogoImage src="logos/line-shape-space.png" width={200} className='footroom'/>
            <p>Line//Shape//Space is run by Autodesk, creator of software including AutoCAD, Revit, Maya, 3ds Max, Fusion 360, SketchBook, and more. Line//Shape//Space is dedicated to helping small businesses in the design and drafting space. </p>
            <p><em>Line//Shape//Space has re-branded to Redshift. We helped them rebrand and define a new illustration style. <Link to="/case-studies/redshift">Read about the process</Link>.</em></p>
            <h2 className='headroom'>Sketching, Line Work and Color</h2>
            <p>Each project starts with a round of sketching. We read the article and do some brainstorming, then put pen to paper. To make sure we're heading in the right direction, we present rough sketches to the Line//Shape//Space team. </p>
            <p> After the sketches are approved, we move on to adding line work and color. </p>
          </Column>
        </Row>
        <Row>
          <Column>
            <ImageCaption src='case-studies/line-shape-space/process-1.png'>
              This example of line work and color is from the article <a href="https://www.autodesk.com/redshift/communication-skills-for-architects/" > Steps to Build Stronger Communication Skills for Architects in Project Collaboration.</a>
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <p>We lined up the sketch and the final illustration from the article <a href='https://www.autodesk.com/redshift/roads-and-bridges/'> The Cure for Aging Roads and Bridges: It May be More Technology Than Money.</a> Drag the slider from left to right to see the transformation. ✨➡  </p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid>
          <Column>
            <CompareImage fluid left="case-studies/line-shape-space/beforeafter1-a.png" right="case-studies/line-shape-space/beforeafter1-b.png"/>
          </Column>
        </Row>
      </Section>

      <Section>
        <Row>
          <Column readable>
            <h2>Ongoing Support</h2>
            <p>Line//Shape//Space has a robust editorial calendar. With so many articles on a wide variety of topics, we learn something new every day! We're there to help illustrate any article that comes our way— from engineering to architecture and everything in between.  </p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid gutters>
          <ImageCaption size={3.905} src='case-studies/line-shape-space/feature-1.png'/>
          <ImageCaption size={4.19} src='case-studies/line-shape-space/feature-5.png'>  Illustrations for <a href="https://www.autodesk.com/redshift/future-of-learning/"> Not your Standard Lecture: 4 Ways the Future of Learning is Changing</a>
          </ImageCaption>
          <ImageCaption size={3.905} src='case-studies/line-shape-space/feature-11.png'/>
        </Row>
      </Section>
      <Section>
        <Row fluid >
          <Column>
            <ImageCaption src='case-studies/line-shape-space/feature-3.png'>
              Illustrations for <a href = 'https://www.autodesk.com/redshift/new-shoring-new-school-of-manufacturing-trends/'> Made in America: Why New-Shoring is the New School of Manufacturing Trends for Small Business</a>
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid gutters>
          <ImageCaption size={5.425} src='case-studies/line-shape-space/feature-6.png'> Illustration for <a href = 'https://www.autodesk.com/redshift/intelligent-buildings/'> Like Sabermetrics Changed Baseball, Big Data Will Transform Intelligent Buildings</a>
          </ImageCaption>
          <ImageCaption size={6.575} src='case-studies/line-shape-space/feature-7.png'/>
        </Row>
      </Section>
      <Section padded>
        <Row vcenter>
          <Column>
            <ImageCaption src='case-studies/line-shape-space/feature-8.png'> Illustrations for <a href = 'https://www.autodesk.com/redshift/5-manufacturing-trends-shaping-the-future-of-small-businesses/'> Power to the People: 5 Manufacturing Trends Shaping the Future of Small Businesses</a>
            </ImageCaption>
          </Column>
        </Row>
        <Row gutters vcenter>
          <ImageCaption size={5.7} src='case-studies/line-shape-space/feature-9.png'/>
          <ImageCaption size={6.3} src='case-studies/line-shape-space/feature-10.png'/>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>A Great Partnership</h2>
            <p>We love working with the team at Line//Shape//Space. Take a look at our work with other teams at Autodesk: <Link to="/case-studies/redshift"> Redshift</Link> and <Link to="/case-studies/autocad">AutoCAD</Link>.</p>
          </Column>
        </Row>
      </Section>
    </LayoutCaseStudy>
  );
};

export default LineShapeSpace;
