//@flow
import * as React from "react";
import Emoji from 'react-emoji-render';

import Layout from "../components/layouts/layout-basic";
import { Column, Row, Section } from "../utils/layout/grid";
import WhyLightboard from '../components/pages/shared/why-lightboard';
import BeforeAfter from '../components/pages/shared/before-after';
import ContactForm from '../components/forms/contact/index';
import { Panel } from '../components/containers/panel';
import colors from '../utils/config/colors';
import { css } from '@emotion/react';
import { StyledText } from '../components/ui/styled-text';
import WrappedConfetti from '../components/ui/confetti';

const Socks = (): React.Node => {
  return (
    <Layout pageTitle="Lightboard puts socks on your feet" onViewEvent="socks-page" solidMenu>
      <Section>
        <Row vcenter fluid>
          <Column size={6}>
            <div style={{'width':'100%', 'overflow':'hidden'}}>
              <video autoPlay playsInline loop muted style={{"height":"100%", "overflow":"hidden", "float":"right"}}>
                <source src="/videos/team-socks.mp4" type='video/mp4' />
              </video>
            </div>
          </Column>
          <Column size={6}>
            <StyledText size={10} fontFamily='reklame-script' textTransform="none">
              <span>Hi there!</span>
              <Emoji text="👋" />
            </StyledText>
            <h2>We want to send you socks.</h2>
            <p>If you think we're awesome, would you tell a friend about us?</p>
            <p>You get awesome socks. Your friend gets awesome design.</p>
            <h2>You win. They win. <Emoji text="🤩" /></h2>
          </Column>
        </Row>
      </Section>
      <Section style={{'position':'relative'}}>
        <WrappedConfetti canvasId="socks-top" />
        <Panel padding={8} backgroundColor={colors.headerColor}>
          <Row>
            <Column readable>
              <Panel borderRadius="2rem" backgroundColor="#FFFFFF" padding={4}>
                <StyledText size={8} fontFamily='reklame-script' textTransform="none">
                  <span>How it works</span>
                </StyledText>

                <h1 className='headroom'>Step 1</h1>
                <p>Send a friend an email about Lightboard, and cc your account manager (Devin or Margaret or Madeline or Stan or Tess or Brad).</p>

                <p> Who's a good fit? We usually work with B2B marketing teams at small to medium companies. We're great at print, web, illustration, and ads.</p>

                <p> Writer's block? Scroll down for a few email ideas.</p>

                <h1 className='headroom'>Step 2</h1>
                <p>Enter your mailing address below and we'll send you the socks.</p>

                <p>It's really that easy. No threads attached. <Emoji text="😉"/></p>

                <ContactForm title='Send me socks' formLocation='socks-request'/>

                <h1 className='headroom'>Step 3</h1>
                <p>Kick back and make fancy sock plans. <Emoji text="🧦"/></p>
              </Panel>
            </Column>
          </Row>
        </Panel>
      </Section>
      <WhyLightboard/>
      <BeforeAfter/>
      <Section css={css`margin-bottom:-120px; position: relative; overflow: hidden;`}>
        <WrappedConfetti canvasId="socks-bottom"/>
        <Panel padding={16} backgroundColor={colors.headerColor}>
          <Row>
            <Column readable>
              <Panel borderRadius="2rem" backgroundColor="#FFFFFF" padding={4}>
                <h2>What are you waiting for?</h2>
                <a href="#socks-form" className="button button-primary button-large">
                <Emoji text="🧦"/> Get Some Socks <Emoji text="🧦"/>
                </a>
                <p> We really want to do this.</p>
                <p> Even if you don't have a friend that you think would be right for Lightboard, let us send you these socks (or something else from the Lightboard swag box) to thank you for working with us.</p>
              </Panel>
            </Column>
          </Row>
        </Panel>
      </Section>
    </Layout>
  );
};

export default Socks;
