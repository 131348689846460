// @flow

import * as React from 'react';
import AppContext from './context';
import type { AppContextType } from './context';
import withApiFetch from "../with_api_fetch";
import type { ApiFetch } from "../with_api_fetch";

let whoamiFetched = false;
let appContext: AppContextType = {
  fetched: false,
  userId: '',
  bucketId: NaN,
  registered: false,
  track: false,
  chat: false,
  retarget: false,
  refresh: () => {}
};

type State = {
  fetched: boolean
}
class Wrapper extends React.Component<{apiFetch: ApiFetch, children: any, status?: number, disableRetarget: boolean, onViewEvent?: string}, {fetched: boolean}> {
  state: State = {
    fetched: false,
  };

  componentDidMount() {
    const { status } = this.props;
    if (!status && whoamiFetched) {
      return; //we're cool
    } else {
      this.refresh();
    }
  }

  refresh = () => {
    const { status } = this.props;
    const screenSize = window.innerWidth + 'x' + window.innerHeight;
    let url = `/users/whoami/web?path=${window.location.pathname}&screenSize=${screenSize}`;

    if (status) {
      const referrer = window.previousPath || window.document.referrer;
      url += `&status=${status}&referrer=${referrer}&host=${window.location.host}`;
    }

    if (window.location.search) {
      url += '&' + window.location.search.substring(1);
    }

    if (this.props.disableRetarget) {
      url += '&disableRetarget=1';
    }

    this.props.apiFetch(url).then(settings => {
      appContext = {
        ...settings,
        fetched: true,
      };
      whoamiFetched = true;
      this.setState({
        fetched: true
      });
    });
  };

  render(): React.MixedElement {
    return (
      <AppContext.Consumer>
        {parentContext => (
          <AppContext.Provider value={
            Object.assign(
              {
                userId: '',
                refresh: () => {},
                fetched: false,
                track: false,
                retarget: false,
                registered: false,
                bucketId: 1,
                chat: false,
                onViewEvent: () => {},
                disableRetarget: false,
                avatarUrl: '',
              },
              (appContext.fetched ? appContext : parentContext),
              { refresh: this.refresh,  }
            )
          }>
            {this.props.children}
          </AppContext.Provider>
        )}
      </AppContext.Consumer>
    );
  }
}

export default (withApiFetch(Wrapper): React.ComponentType<any>);
