// @flow

type Props = {
  vcenter?: boolean,
  fluid?: boolean,
};
const rowStyles = (props: Props): string => `
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;

  ${props.vcenter ? 'align-items: center;' : ''}
  max-width: ${props.fluid ? '100%' : '1100px'};
`;

export default rowStyles;
