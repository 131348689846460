//@flow
import * as React from 'react';

import Text from './text';
import { keyframes } from '@emotion/react';
import styled  from '@emotion/styled';

interface PanelProps {
  background: string,
  position: string,
  textColor: string,
  value: string,
}

const HalfPanel = styled.div`
  position: ${({ position }: { position: string }): string =>
    position === 'top' ? 'relative' : 'absolute'};
  overflow: visible;
`;

const Panel = ({background, position, textColor, value}: PanelProps): React.MixedElement => (
  <HalfPanel color={background} position={position}>
    <Text position={position} color={textColor}>
      {value}
    </Text>
  </HalfPanel>
);

interface FlipPanelProps extends PanelProps {
  direction: string,
  duration: number,
}

const FlipIn = keyframes`
  0% {
    transform: rotateX(-90deg);
  }
  25% {
    transform: rotateX(-90deg);
  }
  100% {
    transform: rotateX(0deg);
  }
`;

const FlipOut = keyframes`
  0% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(90deg);
  }
  100% {
    transform: rotateX(90deg);
  }
`;

const HalfFlipPanel = styled(HalfPanel)`
  position: absolute;
  overflow: visible;
  height: 50%;
  animation-name: ${({ direction }: { direction: string }): string =>
    direction === 'in' ? FlipIn : FlipOut};
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-duration: ${({ duration }: { duration: string }): string => `${duration}s`};
  background: ${({ color, direction }: { color: string, direction: string }): string =>
    direction === 'out' ? `linear-gradient(${color} 50%, transparent 50%)` : `linear-gradient(transparent 50%, ${color} 50%)`};
  border: solid 0px;
  border-color: ${({ direction }: { direction: string }): string =>
    direction === 'in' ? '#FF0000' : '#FFFFFF'};
  z-index: ${({ direction }: { direction: string }): number =>
    direction === 'in' ? 100 : 10};
`;

export const FlipPanel = ({background, direction, duration, position, textColor, value}: FlipPanelProps): React.MixedElement => (
  <HalfFlipPanel direction={direction} duration={duration} color={background} position={position}>
    <Text position={position} color={textColor}>
      {value}
    </Text>
  </HalfFlipPanel>
);

export default Panel;
