//@flow
import * as React from 'react';
import Confetti from 'react-confetti';
import { css } from '@emotion/react';

const ConfettiDrop = (): React.Node => {
  return (
    <div css={css`position:fixed;top:0;left:0;z-index:99999;pointer-events:none;`}>
      <Confetti recycle={false} numberOfPieces={400} gravity={0.25}  />
    </div>);
};

export default ConfettiDrop;
