const hsqPush = (event) => {
  if (typeof window === 'undefined') return;
  if (typeof window._hsq === 'undefined') window._hsq = [];
  window._hsq.push(event);
};

export const identify = (settings) => {
  hsqPush(["identify", { email: settings.email }]);
};

export const trackPageView = (settings) => {
  hsqPush(['trackPageView']);
};
