//@flow
import type { Node } from "react";
import React from "react";

import { Column, Row, Section, Readable } from "../../utils/layout/grid";
import { ImageCaption, LogoImage, PullQuote} from "../../components/ui";
import LayoutCaseStudy from '../../components/layouts/layout-case-study';
import { useCaseStudiesIndex } from '../../utils/graphql/case_studies';

const AutoCAD =  (): Node => {

  let caseStudy = useCaseStudiesIndex('autocad');

  return (
    <LayoutCaseStudy caseStudy={caseStudy} heroUrl='case-studies/autocad/hero.png'>
      <Section>
        <Row>
          <Column readable>
            <p>AutoCAD turned to Lightboard for ongoing support with their busy editorial schedule. Lightboard works with the AutoCAD team to create and design campaigns of eBooks, case studies, one pagers, and social media graphics. </p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <PullQuote src="case-studies/autocad/marielle.png" name="Marielle Covington" title="Marketing Manager">
              <p>We appreciate the flexibility, variety and agility that Lightboard brings to our marketing content creation process!</p>
            </PullQuote>
          </Column>
        </Row>
      </Section>

      <Section padded>
        <Row>
          <Column readable>
            <LogoImage src="logos/autocad.png" width={300}/>
            <p>AutoCAD is a commercial computer-aided design and drafting software application. Developed and marketed by Autodesk, AutoCAD is used by architects, engineers, and construction professionals to create precise 2D and 3D drawings.</p>
            <h2 className='headroom'>Business Situation</h2>
            <p> The AutoCAD marketing team does an excellent job of making a highly technical product accessible and creating a community that celebrates the craft of 2D and 3D modeling. To do this, AutoCAD publishes customer stories, tips and tricks books, and one sheets that explore the program's features. </p>
            <p> In addition to these community building materials, AutoCAD also has a robust editorial calendar of social media graphics, case studies, and one-pagers. </p>
          </Column>
        </Row>
      </Section>
      <Section spaced>
        <Row >
          <Column>
            <ImageCaption src='case-studies/autocad/hero-op1.png'>
              Lightboard supports the AutoCAD team with a wide variety of marketing design needs.
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column readable>
            <h2>Ongoing Support </h2>
            <h4>Social Media</h4>
            <p>In an effort to showcase the users behind their product, AutoCAD launched a 35 under 35 campaign that highlighted 35 trend setting designers under the age of 35, who use the AutoCAD software to turn ideas into impact. Lightboard designed a series of striking graphics for the campaign that the AutoCAD team used in the <a href="https://blogs.autodesk.com/autocad/tag/autocad-35-under-35-list/"> blog posts</a> and shared on social media. </p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid>
          <Column>
            <ImageCaption compact src='case-studies/autocad/35under35-feature.png'/>
          </Column>
        </Row>
        <Row fluid>
          <Column>
            <ImageCaption compact src='case-studies/autocad/35under35-grid.png'>
            Lightboard worked with AutoCAD to set the creative direction for the 35 under 35 campaign, then built out the rest of the images once the style was finalized.
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid vcenter>
          <Column size={6}>
            <ImageCaption src='case-studies/autocad/customerstories-feature.png'/>
          </Column>
          <Column size={6}>
            <Readable left>
              <h4>Case Studies</h4>
              <p>AutoCAD also relied on Lightboard for ongoing support with designing and templating their case study campaigns. One of these campaigns was a series of customer stories that featured how their customers have been using the AutoCAD platform. In an effort to make the recurring work as efficient as possible, Lightboard worked with the AutoCAD team to create a production ready template that could be used for each story as the campaign progressed. </p>
              <p>Once the template had been finalized, Lightboard was able to quickly build out the other customer stories as the AutoCAD team created them.</p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row >
        </Row>
      </Section>
      <Section>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/autocad/customerstories-spread.png'>
              Templates allow for quick and efficient execution that stays perfectly on brand—every time.
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>Special Projects</h2>
            <p>Lightboard also supports the AutoCAD team in special marketing and sales projects, like eBooks and illustrations. These projects are a great opportunity to deepen the create relationship between Lightboard and AutoCAD. AutoCAD's eBooks typically feature illustration-packed pages and colorful imagery that make a highly technical product feel accessible. </p>

            <h2 className='headroom'>Project Focus: Tips and Tricks</h2>
            <p>The <strong> 33 Tips Every AutoCAD User Should Know </strong> book was a collaboration between Lightboard and AutoCAD in preparation for the Autodesk University Conference. The book was distributed online and printed to hand out at the conference.</p>

            <h4>Process </h4>
            <p>The book relied heavily on the use of illustrations to showcase techniques to using the AutoCAD software. Since illustration style was key to the look and feel of the book, the first round of design featured a look at some potential illustration excerpts from the tips. </p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <ImageCaption src='case-studies/autocad/book-illo-proposals.png'> A peek at illustration concept ideas for the tips book. </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section>
       <Row>
          <Column readable>
            <h4>Layout</h4>
            <p>After the illustration style was approved, Lightboard began the process of laying out the 80+ page book. AutoCAD provided a word document with the copy, and Lightboard worked to layout the pages, incorporating the illustrations and content into the spread.</p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid gutters vcenter>
          <ImageCaption compact size={4.35} src='case-studies/autocad/book-overview.png'/>
          <ImageCaption compact size={4.35} src='case-studies/autocad/book-page-grid.png'/>
          <ImageCaption compact size={3.3}  src='case-studies/autocad/book-pages.png'/>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h4>Prep for Production</h4>
            <p>Once the layout was finalized, it was time to prepare the book for printing. To add the last level of polish to the book, front/back covers, a table of contents, and custom iconography were added to the book. The files were then delivered to AutoCAD, one built to the printer's specs and another version optimized for web.</p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid gutters>
          <ImageCaption compact size={4.65} src='case-studies/autocad/book.png'/>
          <ImageCaption size={7.3} compact src='case-studies/autocad/book-icons.png'/>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>Partners in Design</h2>
            <p>Lightboard and AutoCAD have developed a close creative relationship, which allows them to knock out projects quickly and efficiently. Because of that relationship and Lightboard's team of account managers, AutoCAD can trust that Lightboard will always understand their brand when working on projects—saving them time, and money.</p>
          </Column>
        </Row>
      </Section>
    </LayoutCaseStudy>
  );
};

export default AutoCAD;
