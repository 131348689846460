//@flow
import type { Node } from "react";
import React from "react";

import { Column, Row, Section } from "../../utils/layout/grid";
import { FluidImage } from '../../components/ui/images/fluid';
import PortfolioMenu from '../../components/pages/portfolio/portfolio-menu';
import LayoutPortfolio from '../../components/layouts/layout-portfolio';
import { Link } from "gatsby";
import { VideoCaption } from "../../components/ui";

const MotionPortfolio = ({location}: {location:Object}): Node => {

  let autoPlay = false;
  let showControls = true;
  return (
    <LayoutPortfolio heroUrl="tiles/seamless-white.png" onViewEvent="portfolio-set" pageTitle="Motion"  breadcrumbsTitle="Motion" pageDescription="Examples of motion graphics from Lightboard." solidMenu>
      <Section>
        <Row gutters>
          <Column readable textCenter>
            <FluidImage src="portfolio/heroes/branding.png"/>
            <div className="spaced">
              <h1>Motion</h1>
              <p>Bring your design to life. Explain complex concepts, capture the imagination of your audience, or bring movement to your brand.</p>
              <p>We've created motion projects for social media, websites, and conferences—wherever our customers have needed to set their ideas in motion.  We help from storyboard to post-production—just let us know what you <Link to="/contact">need</Link>.</p>
            </div>
          </Column>
        </Row>
      </Section>

      <Section>
        <Row>
          <Column textCenter>
            <PortfolioMenu location={location}/>
          </Column>
        </Row>
      </Section>

      <Section spaced balancedGutters>
        <Row>
          <VideoCaption mp4="/portfolio/motion/lightboard-motion-portfolio.mp4" autoPlay={true} controls={showControls} size={12}>
            <p>Our motion graphics reel</p>
          </VideoCaption>
        </Row>

        <Row>
          <VideoCaption mp4="/portfolio/motion/infocepts.mp4" autoPlay={true} controls={showControls} size={12}>
            <p>InfoCepts: service overview. Design & Animation</p>
          </VideoCaption>
        </Row>

        <Row>
          <VideoCaption mp4="/portfolio/motion/ad-respect-edison.mp4" autoPlay={autoPlay} controls={showControls} size={12}>
            <p>Respect: A Brief History of Thomas Edison for Autodesk Redshift. Design & Animation</p>
          </VideoCaption>
        </Row>
        <Row>
          <VideoCaption mp4="/portfolio/motion/ad-respect-wright.mp4" autoPlay={autoPlay} controls={showControls} size={12}>
            <p>Respect: A Brief History of Frank Lloyd Wright for Autodesk Redshift. Design & Animation</p>
          </VideoCaption>
        </Row>
        <Row>
          <Column>
            <p>See all of our motion work for Autodesk <Link to="/case-studies/redshift-motion-graphics">in this case study</Link>.</p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column>
            <h1>Motion for Social Ads</h1>
            <p>Bring life to your Facebook, Instagram, and Snapchat stories. We can animate your current static display ads to bring motion to the social media format, or develop new designs that capture your audience's attention.</p>
          </Column>
        </Row>
        <Row>
          <VideoCaption mp4="/portfolio/motion/pinger.mp4" size={12}/>
        </Row>
        <Row gutters vcenter>
          <VideoCaption mp4="/portfolio/motion/ad-kinetic-instagram.mp4" size={5}/>
          <VideoCaption mp4="/portfolio/motion/ad-car-loop.mp4" size={7}/>
        </Row>
        <Row gutters vcenter>
          <VideoCaption mp4="/portfolio/motion/galen-snapchat.mp4" size={6}/>
          <VideoCaption mp4="/portfolio/motion/ballertv-snapchat.mp4" size={6}/>
        </Row>
      </Section>
      <Section spaced>
        <Row>
          <Column>
            <h1>Explainers</h1>
            <p>Complex products can be difficult to explain—but a short animation quickly covers a lot of ground. We work with you to storyboard the scenes and write a voiceover track that sends a crystal clear message, and then make it beautiful with illustration and animation.</p>
          </Column>
        </Row>
        <Row>
          <VideoCaption mp4="/portfolio/motion/fixt-tutorial.mp4" controls/>
          <VideoCaption mp4="/portfolio/motion/trackr-iphone.mp4" controls/>
        </Row>
      </Section>
      <Section spaced>
        <Row>
          <Column textCenter>
            <h1>See more of our work.</h1>
            <PortfolioMenu location={location}/>
          </Column>
        </Row>
      </Section>
    </LayoutPortfolio>
  );
};

export default MotionPortfolio;
