//@flow
import styled from '@emotion/styled';

export const Centerer: any = styled.div(({zIndex}: {zIndex?: number}) => `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${zIndex ? 'z-index: ' + zIndex : ''}
`);
