// @flow
import * as React from 'react';
import { useContext } from 'react';
import AppContext from './context';
import { Helmet } from 'react-helmet';
import useTracking from './use_tracking';
import debounce from 'lodash.debounce';

//@NOTE: there may be a better way to do this.
// just want to log scrolls for tracking engagement
class ScrollListener extends React.Component< {tracker: any}, {scroll: number} > {
  debouncedScroll: ?any = null;
  state: { scroll: number} = { 'scroll': 0 };

  componentDidMount() {
    this.debouncedScroll = debounce(this.checkScroll, 750);
    window.addEventListener('scroll', this.debouncedScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll',this.debouncedScroll);
  }

  checkScroll = () => {
    const { scrollY } = window;
    let { scroll } = this.state;
    if (scrollY !== scroll) { //scroll changed, report
      this.props.tracker.event('scroll', {'scrollY':scrollY});
      this.setState({'scroll':scrollY});
    }
  };

  render(): null {
    return null;
  }
}

const Tracking = ({onViewEvent}: {onViewEvent?: string}): any => {
  const settings = useContext(AppContext);
  const tracker = useTracking();

  if (!settings.fetched) {
    return null;
  }

  let { chat, retarget } = settings;
  const sessionStart = tracker.setup();
  if (sessionStart) {
    tracker.sessionStart();
    if (retarget) { //enroll in retargeting audiences
      tracker.retarget();
    }
  }

  tracker.identify(settings);

  tracker.pageView(sessionStart);
  if (onViewEvent) { //special event on load, passed from layout
    tracker.event(onViewEvent);
  }

  return (
    <>
      <Helmet>
        {chat && <script id='hs-script-loader' type='text/javascript' async defer src='//js.hs-scripts.com/3885280.js' />}
        {retarget && <script type='text/javascript'> _linkedin_data_partner_id = "33738";</script>}
        {retarget && <script type='text/javascript' async src="https://snap.licdn.com/li.lms-analytics/insight.min.js"></script>}
      </Helmet>
      <ScrollListener tracker={tracker}/>
    </>
  );
};

export default Tracking;
