//@flow
import { useStaticQuery, graphql } from 'gatsby';
import type { ProjectType } from '../types/project_type';
let projectTypes;

export const useProjectTypes = ():ProjectType[] => {
  const { allProjectsJson } = useStaticQuery(
    graphql`
      query {
        allProjectsJson (sort: {fields:priority}) {
          edges {
            node {
              ...projectTypeDetails
            }
          }
        }
      }
    `
  );
  if (!projectTypes) {
    projectTypes = allProjectsJson.edges.map(projectType => (
      {slug: projectType.node.fields.slug, ... projectType.node}));
  }
  return projectTypes;
};

export const projectTypeFragment: any = graphql`
  fragment projectTypeDetails on ProjectsJson {
    priority
    title
    icon
    appLink
    examples
    featured
    description
    shortDescription
    fields {
      key
      slug
    }
  }
`;
