// @flow
import type { Node } from 'react';
import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import camelCase from '../../../utils/camel_case';
import { Readable, Spread, SpreadPanel } from "../../../utils/layout/grid";
import { FluidImage } from '../../ui/images/fluid';
type Props = {
  feature: {
    headline: string,
    featureImageUrl: string,
    html: string
  },
  flopped?: boolean
}

const Layout = ({ feature, flopped=false }: Props) => {
  let {featureImageUrl, headline, html} = feature;

  return (
    <Spread flopped={flopped} alignCenter vcenter padded={2}>
      <SpreadPanel>
        <FluidImage src={featureImageUrl} alt={feature.headline}/>
      </SpreadPanel>
      <SpreadPanel>
        <Readable>
          {headline && <h2>{headline}</h2>}
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Readable>
      </SpreadPanel>
    </Spread>
  );
};

const FeaturesLayout = ({featureSet}: {featureSet: string[]}): Node => {

  const data = useStaticQuery(graphql`
    {
      features: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(data/features)/"}}) {
        edges {
          node {
            props:frontmatter {
              headline
              featureImageUrl
            }

            html
            fields {
              slug
            }
          }
        }
      }
    }
  `);

  let features = {};
  data.features.edges.forEach(edge => {
    let { node } = edge;
    let feature = {
      html: node.html,
      ...node.props};
    let key = camelCase(node.fields.slug.split('/').slice(-2).shift());
    features[key] = feature;
  });

  return (
    <>
      {featureSet.map((feature, index) =>
        <Layout key={feature} feature={features[feature]} flopped={(index%2===0)}/>
      )}
    </>
  );
};

export default FeaturesLayout;
