//@flow
import { useStaticQuery, graphql } from 'gatsby';

import type { Faq } from '../../types';

let projectFaqs;

function setup() {
  const { faqs } = useStaticQuery(
    graphql`
      query {
        faqs:allProjectFaqJson {
          edges {
            node {
              id
              title
              body
            }
          }
        }
      }
    `
  );

  projectFaqs = faqs.edges.map(({node}) => {
    return ({ ... node});
  });
}

export const useProjectFaqs = ():Faq[] => {
  if (!projectFaqs) setup();
  return projectFaqs;
};
