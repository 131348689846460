//@flow
import React from "react";
import type { Node } from "react";

import { withPrefix } from 'gatsby';

import { Column, Row, Section } from "../../utils/layout/grid";
import {  ImageCaption, LogoImage, FluidVideo } from "../../components/ui";
import LayoutCaseStudy from '../../components/layouts/layout-case-study';
import { useCaseStudiesIndex } from '../../utils/graphql/case_studies';
import { Link } from 'gatsby';

const LineShapeSpaceEbooks =  (): Node => {

  let caseStudy = useCaseStudiesIndex('line-shape-space-ebooks');

  return (
    <LayoutCaseStudy caseStudy={caseStudy} heroUrl='case-studies/line-shape-space-ebooks/hero.png'>
      <Section>
        <Row>
          <Column readable>
            <h2>eBook Design</h2>
            <p>We worked with the great folks at Autodesk's Line//Shape//Space blog to create a series of featured eBooks. After reading through copy supplied by the Autodesk team, we worked to deliver a unique design for each–from ultra-modern to comic book. It was an opportunity for both teams to stretch out into new design territory.</p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column readable>
            <LogoImage src="logos/line-shape-space.png" width={200} className='footroom' />
            <p>Line//Shape//Space is run by Autodesk, creator of design software like AutoCAD, Revit, Maya, 3ds Max, Fusion 360, SketchBook, and more. Line//Shape//Space is dedicated to helping small businesses in the design and drafting space. </p>
            <p><em>Line//Shape//Space has re-branded to Redshift. We helped them rebrand and define a new illustration style. <Link to="/case-studies/redshift">Read about the process</Link>.</em></p>

            <h2 className='headroom'>Product Data Management</h2>
            <p>The team at Line//Shape//Space came to us with an eBook about product data management. The eBook featured six strategies to make file management more accessible, and featured a distinct problem/solution format.</p>

            <p>When designing the layout, we aimed to utilize natural and humanistic elements to personify the information. We also used repeated patterns and visual elements to give the layout a cohesive feel.</p>

            <p>We love how <a href="https://www.autodesk.com/redshift/product-data-management/" >this one</a> turned out. 💙 </p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/line-shape-space-ebooks/1-ebook-grid.png'/>
          </Column>
        </Row>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/line-shape-space-ebooks/1-ebook-cover.png'/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid gutters>
          <ImageCaption size={7.325} src='case-studies/line-shape-space-ebooks/1-ebook-feature-1.png'/>
          <ImageCaption size={4.674} src='case-studies/line-shape-space-ebooks/1-ebook-feature-2.png'/>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column readable>
            <h2>Corporate Social Responsibility </h2>
            <p>For the next eBook, the Autodesk team came to us with six examples of social corporate responsibility to illustrate and layout.</p>
            <p>The design direction was for flat and minimal; the moody result includes a few animations and feature "a day at the office" as the central theme.</p>
            <p>Read the eBook it <a href="https://www.autodesk.com/redshift/promotional/importance-of-corporate-social-responsibility/" > here</a>. 🖥 </p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/line-shape-space-ebooks/2-ebook-grid.png'/>
          </Column>
        </Row>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/line-shape-space-ebooks/2-ebook-cover.png'/>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid gutters>
          <Column size={3.59}>
            <FluidVideo mp4={withPrefix("videos/case-studies/line-shape-space-ebooks/2-ebook-animation1.mp4")}/>
          </Column>
          <Column size={3.635}>
            <FluidVideo mp4={withPrefix("videos/case-studies/line-shape-space-ebooks/2-ebook-animation3.mp4")}/>
          </Column>
          <Column size={4.775}>
            <FluidVideo mp4={withPrefix("videos/case-studies/line-shape-space-ebooks/2-ebook-animation2.mp4")}/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>Sustainability</h2>
            <p>With this eBook on sustainability, the Autodesk team wanted the design to pull inspiration from graphic novels.</p>
            <p>We honed in on a simple color scheme of neutral blues and greens. After syncing on the illustration style, we worked on laying out the body text, headers, call outs and illustrations into the graphic novel inspired format.</p>
            <p>We think <a href="https://www.autodesk.com/redshift/adapting-to-climate-change/" > this one</a> turned out really cool. 😎</p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/line-shape-space-ebooks/3-ebook-grid.png'/>
          </Column>
        </Row>
        <Row fluid gutters>
          <ImageCaption size={6} src='case-studies/line-shape-space-ebooks/3-ebook-cover.png'/>
          <ImageCaption size={6} src='case-studies/line-shape-space-ebooks/3-page-highlight.png'/>
        </Row>
        <Row fluid gutters>
          <ImageCaption size={6} src='case-studies/line-shape-space-ebooks/3-ebook-illo-a.png'/>
          <ImageCaption size={6} src='case-studies/line-shape-space-ebooks/3-ebook-illo.png'/>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>A Great Partnership</h2>
            <p>We love working with the team at Line//Shape//Space. See more of our editorial illustrations <Link to="/case-studies/line-shape-space">here</Link>, and take a look at our work with other teams at Autodesk: <Link to="/case-studies/redshift"> Redshift</Link> and <Link to="/case-studies/autocad">AutoCAD</Link>.</p>
          </Column>
        </Row>
      </Section>


    </LayoutCaseStudy>
  );
};

export default LineShapeSpaceEbooks;
