//@flow
import * as React from "react";

import type { PortfolioAsset } from "../../../utils/types";
import { Section, Row, ColumnMax, ColumnMin } from '../../../utils/layout/grid';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import fonts from '../../../utils/config/fonts.json';
const PortfolioAssetDetails = ({src}: { src: PortfolioAsset}):React.Node => {

  const detailsCss = css({

    'marginTop': '1.5rem',
    'borderTop': 'solid 1px rgba(0,0,0,0.05)',
    'paddingTop': '1rem',
    'fontSize': '1.6rem',
    ['span'] : {
      'fontFamily': fonts.quoteFont
    }
});

  if (!src.title && !src.description && !src.caseStudy) {
    return null;
  }

  return (
    <div css={detailsCss}>
      <Section>
        <Row vcenter>
          <ColumnMax size={9} mobileSize={0}>
            <p><strong>{src.title}</strong> <span>{src.description}</span></p>
          </ColumnMax>
          <ColumnMin size={3} mobileSize={0}>
            <div className='text-right'>
              {src.caseStudy && <Link to={`/case-studies/${src.caseStudy}`}>
                <button>
                  Read Case Study
                </button>
              </Link>}
            </div>
          </ColumnMin>
        </Row>
      </Section>
    </div>
  );

};

export default PortfolioAssetDetails;
