//@flow
import * as React from "react";
import Layout from './layout-basic';
import { Row, Column } from '../../utils/layout/grid';
import type { LayoutProps } from './layout-basic';

export default (props: LayoutProps): React.Node => {
  let { children, ...rest } = props;
  return (
  <Layout {...rest} onViewEvent="jobs" solidMenu disableRetarget>
    <Row>
      <Column>
        {children}
      </Column>
    </Row>
  </Layout>
  );
};
