//@flow
import * as React from 'react';
import classnames from 'classnames';

type ButtonDefaults = {
  className: string,
  defaultText: string
};

type Props = {
  children?: any,
  css?: any,
  onClick?: Function,
  className?: string,
  wide?: boolean,
  large?: boolean
};

type ButtonComponent = React.StatelessFunctionalComponent<Props>;

const removeExtraProps = (props: Object) => {
  const withoutChildren = {...props};
  delete withoutChildren.children;
  delete withoutChildren.wide;
  delete withoutChildren.large;

  return withoutChildren;
};

const StyledButton = ({
  className,
  defaultText
}: ButtonDefaults): ButtonComponent => (
  props: Props
) => {
    let classes = className;
    if (props.className) {
      classes = classnames(className, props.className);
    }
    if (props.wide) {
      classes = classnames(classes, 'button-wide');
    }
    if (props.large) {
      classes = classnames(classes, 'button-large');
    }
  return (
    <button type='button' { ...removeExtraProps(props)} className={classes}>{props.children || defaultText}</button>
  );
};

export const PrimaryButton:ButtonComponent = StyledButton({
  className: 'button-primary',
  defaultText: 'Submit'
});

export const DefaultButton:ButtonComponent = StyledButton({
  className: 'button-default',
  defaultText: 'Submit'
});

export const SecondaryButton:ButtonComponent = StyledButton({
  className: 'button-secondary',
  defaultText: 'Submit'
});

export const OutlineButton:ButtonComponent = StyledButton({
  className: 'button-outline',
  defaultText: 'Submit'
});

export const DangerButton:ButtonComponent = StyledButton({
  className: 'button-danger',
  defaultText: 'Submit'
});

export const MinimalButton:ButtonComponent = StyledButton({
  className: 'button-minimal',
  defaultText: 'Submit'
});

export const LinkButton:ButtonComponent = StyledButton({
  className: 'button-link',
  defaultText: 'Submit'
});

export const CancelButton:ButtonComponent = StyledButton({
  className: 'dialog__cancel-button',
  defaultText: 'Cancel'
});

export const GiantButton:ButtonComponent = StyledButton({
  className: 'button-primary button-giant',
  defaultText: 'Submit'
});
