// @flow
import * as React from 'react';

function getLoadingState() {

  if (typeof window === 'undefined') return 'loading';

  const html = window.document.documentElement;

  if (!html) return;
  if (html.classList.contains('wf-active')) {
    return 'loaded';
  }

  if (html.classList.contains('wf-inactive')) {
    return 'inactive';
  }

  return 'loading';
}

type State = {
  loadingState: string
};

export default function withWebfontLoadingState(Component: any): React.ComponentType<any> {
  class Wrapped extends React.Component<any, { loadingState: string }> {
    interval: any = null;
    state: State = {
      loadingState: 'loading'
    };

    componentDidMount() {
      this.interval = setInterval(this.checkLoadingState, 20);
    }

    checkLoadingState = () => {
      const loadingState = getLoadingState();
      if (loadingState === 'loaded') clearInterval(this.interval);
      if (loadingState !== this.state.loadingState) {
        this.setState({ loadingState });
      }
    };

    render(): React.Node {
      return <Component {...this.props} webfontLoadingState={this.state.loadingState} />;
    }
  }

  return Wrapped;
}
