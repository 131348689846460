
//@flow
import * as React from "react";
import { FluidImage } from '../ui/images/fluid';
import { css } from '@emotion/react';
import { Centerer } from '../../utils/layout/grid';
import { Link } from 'gatsby';
interface PanelProps {
  absolute?: boolean,
  backgroundColor?: string,
  backgroundGradient?: string[],
  backgroundImageUrl?: string,
  backgroundRepeat?: boolean,
  border?: any,
  borderRadius?: string,
  children?: any,
  className?: string,
  color?: string,
  css?: any,
  fadeIn?: boolean,
  padding?: any,
  screenback?: string,
  textAlign?: string,
  height?: any,
  width?: string,
  zIndex?: number,
}

const bgStylesBase = {
  'position':'absolute',
  'top':'0',
  'zIndex':'99',
  'width':'100%',
  'height':'100%',
  'backgroundRepeat':'no-repeat',
  'backgroundSize':'auto'
};

export const Panel = ({
  absolute=false,
  backgroundColor='inherit',
  backgroundGradient,
  backgroundImageUrl,
  backgroundRepeat,
  border,
  borderRadius='0',
  children,
  className,
  color="#000",
  fadeIn,
  height=20,
  padding=2,
  screenback,
  textAlign='center',
  width='100%',
  zIndex
}: PanelProps): React.Node => {

  if (border) {
    border = (border===true) ? 'solid 1px #ff0000' : border;
  } else {
    border = '0px';
  }

  const _height = (typeof height=== 'string') ? height : height + 'px';
  const _padding = (typeof padding=== 'string') ? padding : padding + 'rem';

  function buildPanelCss() {
    return css({
      'background': (backgroundGradient) ? `linear-gradient(to bottom,${backgroundGradient[0]},${backgroundGradient[1]})` : 'inherit',
      'backgroundColor': backgroundColor,
      'minHeight': _height,
      'borderRadius': borderRadius,
      'color': color,
      'border': border,
      'width': width,
      'padding': _padding,
      'textAlign': textAlign,
      'position': (absolute) ? 'absolute' : 'relative',
      'zIndex': zIndex || 'inherit'
    });
  }

  let bgStyles = {'minHeight':`${_height}`,...bgStylesBase};

  if (backgroundRepeat) {
    bgStyles.backgroundSize = `${_height}`;
    bgStyles.backgroundRepeat = 'repeat';
  }

  let screenbackCss = css({
    'position': 'absolute',
    'top':'0',
    'zIndex':100,
    'width':'100%',
    'height':'100%',
    'minHeight':`${_height}`,
    'backgroundColor':`${screenback || 'rgba(0,0,0,0)'}`
  });

  return (
    <div className={className || ''} css={buildPanelCss()}>
      <div css={css`z-index:101;position:relative;min-height:${_height};`}>{children}</div>
      {screenback && <div css={screenbackCss} />}
      {backgroundImageUrl && <FluidImage
        style={bgStyles}
        src={backgroundImageUrl}
      />}
    </div>
  );
};

interface LinkedPanelProps extends PanelProps {
  to: string
}

export const LinkedPanel = (props: LinkedPanelProps): React.Node => {

  const wrapperCss = css`
    > div {
      border-bottom: solid 1px #f0f0f0 !important;
      :hover {
        background-color: #f0f0f0;
      }
      transition: background-color 0.5s ease;
    }
  `;

  let {to, children, ...rest} = props;

  return (
    <Link to={to} css={wrapperCss}>
      <Panel borderRadius="0.5rem" padding={0} backgroundColor="#fafafa" border="solid 1px #f0f0f0" {...rest}>
        <Centerer>
          {children}
        </Centerer>
      </Panel>
    </Link>
  );
};
