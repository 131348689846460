//@flow
import type { Node } from "react";
import React from "react";
import Emoji from 'react-emoji-render';

import ClientLogos from './client-logos';
import Pricing from './pricing';
import FrequentQuestions from './faqs';
import { FillerPortal } from '../../../placeholders/index';
import { Column, Row, Section } from '../../../../utils/layout/grid';
import ContactForm from '../../../forms/contact/index';
import EmailForm from '../../../forms/email/index';

import type { ProjectType } from '../../../../utils/types/project_type';
import ProjectTypes from '../../shared/project-types';

export function CommonExtended({projectType}: {projectType: ProjectType}): Node {
  return (
    <>
      <Pricing/>
      <ClientLogos />
      <FrequentQuestions/>
      {/*<DesignTeam /> */}
      <PrimaryCTA />
      <ProjectTypes>
        <div className='spaced text-center'>
          <h2>Other Project Types</h2>
        </div>
      </ProjectTypes>
    </>
  );
}

export function DesignTeam(): Node {
  return (
    <Section padded>
      <Row vcenter>
        <Column size={8}>
          <ul className='flex-list' style={{'paddingLeft': '48px'}}>
            <li><FillerPortal /></li>
            <li><FillerPortal /></li>
            <li><FillerPortal /></li>
            <li><FillerPortal /></li>
          </ul>
          <ul className='flex-list' >
            <li><FillerPortal /></li>
            <li><FillerPortal /></li>
            <li><FillerPortal /></li>
            <li><FillerPortal /></li>
            <li><FillerPortal /></li>
          </ul>
        </Column>
        <Column size={4}>
          <h1>We love design</h1>
          <h4>Made with <Emoji text="💖" /> in Seattle!</h4>
          <p>We've designed hundreds of decks and thousands of slides for companies large and small. You're an expert at your business. </p>
          <p>We realize your great design. We're better together.</p>
          <EmailForm buttonLabel="Request Demo"/>
        </Column>
      </Row>
    </Section>
  );
}

export function PrimaryCTA(): Node {
  return (
    <Section padded>
      <Row>
        <Column readable>
          <h1 className='text-center'>Let's get started.</h1>
          <ContactForm/>
        </Column>
      </Row>
    </Section>
  );
}
