//@flow
import React from "react";
import type { Node } from "react";

import { Column, Readable, Row, Section } from "../../utils/layout/grid";
import { PullQuote, ImageCaption, LogoImage } from "../../components/ui";
import { useCaseStudiesIndex } from '../../utils/graphql/case_studies';
import LayoutCaseStudy from '../../components/layouts/layout-case-study';

const EasternBank =  (): Node => {

  let caseStudy = useCaseStudiesIndex('eastern-bank');

  return (
    <LayoutCaseStudy caseStudy={caseStudy} heroUrl='case-studies/eastern-bank/hero.png'>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h1>Quick and efficient</h1>
              <p>
                Kyle Costello, VP Digital Marketing, and his group of marketing managers at Eastern Bank use Lightboard as an extension of their team to deliver high-quality marketing assets quickly and collaboratively.
              </p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
              <PullQuote src="case-studies/eastern-bank/kyle-costello.jpg" name='Kyle Costello' title='Vice President, Digital Marketing'>
                <p>
                  Lightboard feels like an extension of our team.
                </p>
              </PullQuote>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <LogoImage src="logos/eastern-bank.png" alt="Eastern Bank logo" width={300}/>
              <p>Founded in 1818, Eastern Bank is the largest, oldest mutual bank in the country with $10 billion in assets and over 120 locations in eastern Massachusetts and southern and coastal New Hampshire. Eastern Bank offers banking, investments, and insurance with the size, stability, technology, and knowledge to deliver custom-tailored financial solutions to their customers in a convenient way.</p>
              <h2>Business Situation</h2>
              <p>Eastern Bank retains a mid-sized creative agency, but discovered that it wasn't prepared to agilely produce the volume of everyday marketing materials they needed to effectively communicate with their customers.</p>
              <p>With no designers on their marketing team, Eastern Bank needed a cost-effective solution that would yield high-quality marketing design materials quickly and efficiently.</p>
              <h2>Discovery</h2>
              <p>After spending months experimenting with different freelance designers and freelance networks, Eastern Bank discovered Lightboard through a Facebook ad and visited the website to learn more. They were impressed with Lightboard's marketing design portfolio and decided to give Lightboard a small, discrete project as a test.</p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid >
          <Column>
            <ImageCaption src="case-studies/eastern-bank/email-band.png"/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h2>Path to Success</h2>
              <p>Eastern Bank's first project with Lightboard was a small one—a hero image for a newsletter. They were so impressed with the turnaround time and high-quality design that they quickly began to rely on Lightboard for all of their everyday marketing design needs, increasing the size and complexity of projects.</p>
              <p>So when Eastern Bank completed a rebrand with their creative agency earlier this year, they knew they could count on Lightboard to quickly execute the new branding across all of their many creative marketing assets and templates.</p>
            </Readable>
            <ImageCaption src="case-studies/eastern-bank/icon-illustration.png">
              Custom icon illustration
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h2>Benefits</h2>
              <h4>100% Brand Assurance</h4>
              <p>Because of Lightboard's deep experience working with brand guidelines and implicit knowledge of the tenets of Brand DNA, Eastern Bank has been able to rely on Lightboard to create a cohesive, consistent brand experience for their customers across all of their consumer touchpoints.</p>
              <p>Lightboard's account managers and software ensured that the dozen designers that have worked on Eastern Bank's projects have adhered to the brand standards— matching colors, typography, and photography across print and digital.</p>
            </Readable>
            <ImageCaption src="case-studies/eastern-bank/email-heroes.jpg">
              Email hero examples
            </ImageCaption>
            <Readable>
              <h4>Fast & Nimble Execution</h4>
              <p>Eastern Bank's business requires frequent customer communication—and a high volume of everyday marketing assets. To communicate new programs and priorities to their customers, Eastern Bank often needs B2C marketing assets designed within 48 hours.</p>
              <p>Lightboard's simple, easy-to-use web-based platform allows Kyle and his entire team to submit a new job request at any time and efficiently communicate and collaborate with their Lightboard account manager to meet deadlines.</p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <PullQuote src="case-studies/eastern-bank/kyle-costello.jpg" name='Kyle Costello' title='Vice President, Digital Marketing'>
              <p>The value that Lightboard's work generates for Eastern Bank is tremendous.</p>
            </PullQuote>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h4>Expanded Design Resources</h4>
            <p>Eastern Bank has recognized a 20% uplift in email response rates when their direct-to-consumer emails are beautifully designed, but struggled to get them produced regularly. Finding available freelancers can be time-consuming, and working with big creative agencies-of-record on small everyday marketing design can be cost-prohibitive.</p>
            <p>With a fixed marketing budget for the year, leveraging Lightboard as an extension of their design team ensures Eastern Bank gets these vital everyday marketing assets reliably and cost-effectively produced and implemented, reaching more customers more often.</p>
          </Column>
        </Row>
      </Section>
    </LayoutCaseStudy>
  );
};

export default EasternBank;
