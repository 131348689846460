//@flow
import type { SerializedStyles } from "../../../node_modules/@emotion/utils/src/types";import * as React from "react";

import { css } from '@emotion/react';

import iconA from './bars/icon-bar-a.png';
import iconB from './bars/icon-bar-b.png';
import iconC from './bars/icon-bar-c.png';

import seattleA from './bars/seattle-bar-a.png';
import seattleB from './bars/seattle-bar-b.png';
import seattleC from './bars/seattle-bar-c.png';
import seattleD from './bars/seattle-bar-d.png';
import seattleE from './bars/seattle-bar-e.png';

import Sizes from '../../utils/config/breakpoints.json';

type HeroProps = {
  id:string,
  count:number,
  seattle?: boolean
}

const seattleSet = {'a':seattleA, 'b':seattleB, 'c':seattleC, 'd':seattleD, 'e':seattleE};
const iconSet = {'a':iconA, 'b':iconB, 'c':iconC};

const barAngle = 3;

const wrapper = css`
  position: absolute;
  bottom: 0px;
  right: -160px;
  z-index: 102;
  transform:  skew(${(barAngle*-1)}deg) translate(0, 0);
  overflow: hidden;
  min-width: 1100px;
`;

const barCss = css`
  float: left;
  background-size: contain;
  height: 90vh;
  width: 120px;
  margin-right: 1rem;
  transition: transform 0.5s 0.05s ease-out;
  @media (max-width: ${Sizes.tablet}) {
    display: none;
  }
`;

class HeroBar extends React.Component< HeroProps, {style:any, mounted: boolean}> {

  state: { mounted: boolean, style: string } = {style: '', mounted: false};

  componentDidMount() {
    let style = this.buildStyle(100);
    this.setState({style, mounted: true});
    setTimeout(() => {
      this.redraw();
    }, this.props.count*25);
  }

  buildStyle(transform:number): SerializedStyles {

    let {seattle, id} = this.props;
    let { mounted } = this.state;
    let set = (seattle) ? seattleSet : iconSet;
    let imageUrl = set[id];
    let bgRepeat = (seattle) ? 'no-repeat' : 'repeat-y';
    if (seattle && (id==='a') && mounted) { transform = 20; }
    let height = (seattle) ? 'height: 800px;' : '';

    return css`
      background-image:url(${imageUrl});
      transform: translate(0, ${transform}%);
      ${height} background-repeat: ${bgRepeat};`;
  }

  redraw() {
    let transform = 90 - (Math.floor(Math.random()*25) + (5 * this.props.count));
    let style = this.buildStyle(transform);
    this.setState({style});
  }

  render(): React.MixedElement{
    return <div css={[barCss,this.state.style]}/>;
  }
}

class HeroBars extends React.Component<{ seattle?: boolean }, {mounted: boolean}> {
  state: { mounted: boolean } = { mounted: false };
  componentDidMount() {
    this.setState({mounted: true});
  }

  render(): React.Node {
    const { seattle } = this.props;

    let bars = [1, 2, 3, 4, 5, 6, 7, 8];
    let set = (seattle) ? ['b', 'c', 'e', 'a', 'e', 'd', 'c','e'] : ['a', 'b', 'c'];

    return (
      <div css={wrapper}>
        {this.state.mounted && bars.map(count => {
          return <HeroBar key={count} id={set[count % set.length]} count={count} seattle={seattle} />;
        })}
      </div>
    );
  }
}

export default HeroBars;
