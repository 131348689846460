import * as React from "react";
import { css } from '@emotion/react';

type Props = {
  size:number,
  thin?: boolean,
  altFont?: boolean,
  fontFamily?: string,
  inline?: boolean,
  textTransform?: string,
  children: React.Node
};

export const StyledText = ({size=1, thin, altFont, fontFamily, inline, textTransform='uppercase', children}: Props) => {
  let fontWeight = (thin) ? 200 : 700;

  let _fontFamily = 'acumin-pro';

  if (fontFamily) {
    _fontFamily = fontFamily;
  }

  if (altFont) {
    fontWeight = 100;
    _fontFamily = 'park-lane';
  }

  let cssSize = css({
    'display': (inline) ? 'inline': 'block',
    'fontFamily': _fontFamily,
    'fontSize': `${size}rem`,
    'fontWeight': fontWeight,
    'lineHeight': `${size*1.1}rem`,
    'margin': `0 6px 0 0`,
    'padding': 0,
    'textTransform': textTransform
  });

  return <div css={cssSize}>{children}</div>;
};

export const BigDollar = ({amount, discount}:{amount:String, discount?: string}) => {
  return (
    <>
      <StyledText altFont inline size={6}><sup>$</sup></StyledText>
      <StyledText fontFamily='mr-eaves-xl-modern' inline size={12}>{amount}</StyledText>
      <StyledText inline size={2}>/hr</StyledText>
    </>
  );
};
