//@flow
import * as React from 'react';
import { Section, Row, Centerer } from "../../../utils/layout/grid";
import { Panel } from '../../containers/panel';
import { Link } from 'gatsby';
import { GiantButton } from '../../forms/buttons/index';
import { css } from '@emotion/react';

const PortfolioCallout = (): React.Node => {

  const ghostCss = css`width:100%;height:100%;position:absolute;background-color:rgba(255,255,255,0.8);`;

  return (
    <Section>
      <Row fluid>
        <Panel height={300} padding={0} backgroundImageUrl='portfolio/portfolio-banner.png'>
          <div css={ghostCss}>
          <Centerer>
            <Link to="/portfolio">
              <GiantButton>Our Portfolio</GiantButton>
            </Link>
          </Centerer>
          </div>
        </Panel>
      </Row>
    </Section>
  );
};

export default PortfolioCallout;
