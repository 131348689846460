//@flow
import { useStaticQuery, graphql } from 'gatsby';
import type { CaseStudy } from '../types/case_study';

let caseStudies;
let caseStudiesIndex;

function setup() {
  const { allCaseStudiesJson } = useStaticQuery(
    graphql`
      query {
        allCaseStudiesJson (sort: {fields:priority}) {
          edges {
            node {
              ...caseStudyDetails
            }
          }
        }
      }
    `
  );
  caseStudiesIndex = {};
  caseStudies = allCaseStudiesJson.edges.map(({node}) => {
    caseStudiesIndex[node.slug]=node;
    return ({ ... node});
  });
}

export const useCaseStudiesIndex = (slug: string):CaseStudy => {
  if (!caseStudiesIndex) setup();
  return caseStudiesIndex[slug];
};


export const useCaseStudies = ():CaseStudy[] => {
  if (!caseStudies) setup();
  return caseStudies;
};

export const caseStudyFragment: any = graphql`
  fragment caseStudyDetails on CaseStudiesJson {
    slug
    pageTitle
    companyName
    metaDescription
    shortText
    hidden
    priority
    color
    projectTypes {
      id
      score
    }
    stats {
      stat
      plus
      prefix
      percent
      label
    }
  }
`;
