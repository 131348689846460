//@flow
import type { Node } from "react";
import React from "react";
import { useLayoutEffect } from "react";
import { Readable } from "../utils/layout/grid";
import { motion, useSpring } from "framer-motion";
import { css } from '@emotion/react';
import { Spread } from "../utils/layout/grid/spread/wrapper";
import { SpreadPanel } from "../utils/layout/grid/spread/item";
import { FluidImage } from "../components/ui/images/fluid";
import { rgb } from "color";
import LayoutBasic from "../components/layouts/layout-basic";
import Sizes from "../utils/config/breakpoints.json";


const pageCss = css`
  @media (max-width: ${Sizes.breakpointMobile} ) {
    .mobile-hidden {
      display: none;
    }
  }
`;

const trendCss = css`
    h1 {
      font-family: 'playfair-display', serif;
      font-weight: 900;
      font-size: 4rem;
      margin-bottom: 2rem;
    }
    p {
      font-family: 'Century Gothic', sans-serif;
      font-size: 1.8rem;
      font-weight: 300;
      line-height: 1.4;
      margin-bottom: 2rem;
      letter-spacing: 0.01rem;
    }
    padding: 4rem 10rem 8rem 10rem;
    max-width: 1200px;
    margin: 4rem auto 40rem auto;
    border-width: 14px;
    border-style: solid;
    @media (max-width: ${Sizes.breakpointMobile} ) {
      h1 {
        font-size: 2.5rem;
      }
      p {
        font-size: 1.6rem;
      }
      border-width: 8px;
      padding: 4rem 2rem 4rem 2rem;
      margin: 2rem 2rem 6rem 2rem;
    }
`;

type TrendProps = {
  id: string,
  calloutId?: string,
  calloutColor?: string,
  children?: Node,
  backgroundColor?: string,
  borderColor?: string,
  fontColor?: string,
  tailImg?: string
}

const TrendWrapper = (
  {
    id,
    calloutId,
    calloutColor = '#FF4752',
    children,
    backgroundColor = '#0076DA',
    borderColor = '#0a48ab',
    fontColor = '#FFF',
    tailImg
  }: TrendProps
) => {
  calloutColor = rgb(backgroundColor).darken(0.10).string();



  return (
    <div
      id = {id}
      css= {trendCss}
      style={{
        color: fontColor,
        textAlign: 'left',
        borderColor: `${borderColor}`,
        boxShadow: '0px 0px 12px 12px rgba(0,0,0,0.10)',
        backgroundColor,
        position: 'relative'
      }}
      >
        <a name={id} />
        {calloutId && <div className='mobile-hidden' style={
          {position: 'absolute',
          top: '-8rem',
          left: '-8rem',
          textAlign: 'center',
          backgroundColor: calloutColor,
          borderRadius: '40rem',
          width: '20rem',
          height: '20rem',
          color: '#FFF',
          transform: 'rotate(6deg)',
          boxShadow: `0px 0px 12px 12px rgba(0,0,0,0.10), inset 0 0 0 10px ${rgb(calloutColor).darken(0.2).string()},  2px 2px 0 2px ${rgb(calloutColor).darken(0.25).string()}`,
          }}>
          <h1 style={{
            fontSize: '20rem',
            fontWeight: '900',
            color: rgb(calloutColor).darken(0.35).string(),
            lineHeight: '0.5',
            textShadow: `1px 1px 2px ${rgb(calloutColor).darken(0.2).string()}`,

          }}>{calloutId}</h1>
        </div>}
      {children}
      {tailImg && <div className='mobile-hidden' style={{
        position: 'absolute',
        bottom: '-8rem',
        right: '-8rem',
        width: '20rem',
        height: '20rem',
        transform: 'rotate(6deg)',
        }}>
        <FluidImage src={tailImg} />
      </div>}
    </div>
  );
};


const TrendIntro = (): Node => {

  return (
    <TrendWrapper id="intro" backgroundColor="#FF96A2" borderColor="#FF4752" >
      <Spread flopped gutters vcenter>
        <SpreadPanel>
          <FluidImage src="playbook/project-process.png" />
        </SpreadPanel>
      <SpreadPanel>
      <Readable>

        <p css={css`
          ::first-letter {
            font-size: 12rem;
            font-family: 'playfair-display', serif;
            font-style: italic;
            font-weight: 900;
            float: left;
            line-height: 0.8;
            padding-right: 2rem;
            padding-bottom: 1rem;
          }
        `}>
        One of the neatest things about Lightboard is that we have a view into the day-to-day operations of hundreds of marketing teams. When we first passed 52 Membership customers, we realized that every week we were delivering 52 weeks worth of design—a year's worth of design for a small marketing team. In 2023, we delivered several years worth of design every week.</p>

        <p>While that unfortunately doesn't give us time-traveling skills, it does give us a great view into B2B marketing operations. Here are a few trends that emerged in 2023 and we expect to continue in 2024. </p>
      </Readable>

      </SpreadPanel>
      </Spread>
    </TrendWrapper>

  );
};

const TrendAI = (): Node => {

  return (
    <TrendWrapper id="ai" calloutColor="#FFE52C" backgroundColor="#FFE52C" borderColor="#F7CE52" fontColor="#192332" calloutId="1"  tailImg="playbook/speech-bubbles-a.png"  >
       <Readable>
        <FluidImage className="spaced" src="playbook/robot-machine.png" />
        <h1>Practical Applications of AI </h1>
        <p>Hand wringing over super-intelligent AI is mainstream news, but we're seeing practical applications of generative AI in at least two places for our customers: stock photography and copywriting.</p>

        <p>We've all spent hours looking for Just. The. Right. Stock. Photo. With Midjourney and DALL-E—and a little prompting practice—you can generate a passable stock photo with specificity that would be impossible without a custom photo shoot. Sure, every once in a while a person might have six fingers or three arms, but with a careful eye and a little editing we can make images that will blend right in with "real" photographs.</p>

        <p>We've also seen generative AI help customers with the content structure of a website, or get “unstuck” writing a document. A chat bot can't really “think” (yet!)—but if you give it raw material, it can do a great job of synthesizing concepts and creating outlines. It's also very good at some of the more technical tricks of copywriting, like reducing the number of characters or coming up with ten synonyms for “brainstorm” that start with the letter M (I know thesauruses exist but it certainly gives you something to mull over, muse on, meditate on, map out, manifest, manufacture, materialize, mindstorm, mastermind, and marinate on).</p>

      </Readable>
    </TrendWrapper>

  );
};

const TrendWebflow = (): Node => {
  return (
    <TrendWrapper id="webflow" calloutId="2" tailImg="playbook/speech-bubbles-c.png">
      <Readable>
      <FluidImage className="spaced" src="playbook/web-collaboration.png" />
      <h1>Everyone is on Webflow</h1>
      <p>For the last decade—or longer—WordPress has been the dominant platform for professional websites. WordPress has a robust ecosystem of themes and plugins—and that unvetted jungle of software has become an unattractive burden. There are so many plugins that managing a WordPress website is a game of whack-a-mole; update one plugin and another breaks. No one wants to wake up to a random failure because an automated update crashed their site. </p>

      <p>Webflow has decisively fixed that problem with a simple offering. There's just one hosting option for Webflow and very few plugins. The beauty of a Webflow site is that they're almost impossible to break and the backend experience is consistent from one site to another. That's appealing both to developers and customers because it reduces the learning curve and maintenance burden. </p>

      <p>While it's not without problems, we've had very little difficulty convincing customers to transition to Webflow after they've tried it out on their own. In the past year we've launched dozens of Webflow sites, but only two new WordPress sites (and updated only a handful). It's also been increasingly difficult to find WordPress developers—they'd all prefer to work with Webflow. </p>
      </Readable>
    </TrendWrapper>
  );
};

const TrendFigma = (): Node => {
  return (
    <TrendWrapper id="figma" calloutId="3" backgroundColor="#FF96A2" borderColor="#FF4752" tailImg="playbook/speech-bubbles-b.png">
      <Readable>
        <FluidImage className="spaced" src="playbook/cart.png" />
        <h1>Approachable Design Tools</h1>
        <p>We've seen a lot of design tools try to take on Adobe; but none have saturated the market like Figma (and that's probably why Adobe bought them). </p>

        <p>Designers loved using Sketch—but customers did not. No one liked using InVision (except for a few product managers…), and designers turned up their black-turtlenecked noses at lightweight tools like Miro, Marvel, and even Adobe's own XD.</p>

        <p>Figma threaded the needle by being approachable enough for marketers while still being powerful enough for designers. That it's cloud-based, multi-user, and updates everywhere all at once so that no one ever has an out-of-date design file has made it an increasingly dominant platform. We see Figma in use with nearly 50% of our customers.</p>

        <p>On the heels of Figma is Canva's emergence as a professional design tool. Canva once was the place where hokey coffee shop fliers, novelty t-shirts, and folksy yard signs were born and raised. But they've increasingly aimed at the professional market and improved their set of templates and template-building tools that we're seeing more and more requests around Canva for presentations, display ads, and social media.</p>

        <p>We'll often design templates for customers to use in Canva; the design tools are powerful enough that we can make something professional, and the editing tools are robust enough that our customers can make use of them. I expect to see more overlap between Figma and Canva as they each try to grow to reach their lofty valuations (or acquisition price if the Adobe deal closes).</p>
      </Readable>
    </TrendWrapper>
  );
};

const TrendTransitionDocs = (): Node => {
  return (
    <TrendWrapper id="docs" calloutId="4"  tailImg="playbook/paper-feature.png">
      <Spread>
        <SpreadPanel>
          <FluidImage src="playbook/project-vortex.png" />
        </SpreadPanel>
        <SpreadPanel>
          <Readable>
            <h1>Turnover and Transitions</h1>

            <p>The last three years were unprecedented for tech workers; between WFH, RIF, and RTO we've seen increased turnover within our customer's marketing teams. The changes are often abrupt—leadership lays off an entire marketing department, or a teammate quits with little notice. In some cases, we're the only ones left that know how to access the website or send a newsletter.</p>

            <p>Unplanned transitions are chaotic and expensive, and they can be helped with a living document that outlines the basics of how a marketing team works—not just the logo files, colors, and fonts, but operational details like where the email list is stored, how to view the landing page analytics, and vendor contacts and contracts. In one extreme case, a customer discovered an unused Hubspot annual plan only when it came up for renewal: $24k. </p>

            <p>Usually if an entire marketing team has been dismissed, the operational roles are folded into a sales or accounts department who don't always care about brand guidelines and cobble together frankenstein presentations of old, new, and extra old branding (that we dutifully try to unwind). We prize our personal relationships with our customers (they often bring us with them to their next job) and hate to see their careful work go to waste. Layoffs are rarely fair or well done, and very few people are dedicated enough to write a transition document after their life has been upended by a company—so the best time to start a document is right now.</p>
          </Readable>
        </SpreadPanel>
      </Spread>


    </TrendWrapper>

  );
};

const TrendConclusion = (): Node => {
  return (
    <TrendWrapper id="conclusion">
      <Readable>
      <h1>And onto 2024...</h1>

      <p>Looking ahead to 2024, we'll see more and more AI agents gluing together different pieces of marketing operations. And Google's new spam rules will change the outreach playbook for many teams, perhaps creating an even faster return to in-person events. </p>
      <p>But one thing we're sure about for the coming year—we'll be right here to deliver design you'll love. </p>
      <p>We look forward to working with you in 2024!</p>
      <FluidImage className="spaced" src="playbook/design-love.png" />
      </Readable>
    </TrendWrapper>

  );
};

const TwentyTwentyFour = (): Node => {

  const scrollY = useSpring(0, { damping: 200, stiffness: 1000 });

  useLayoutEffect(() => {
    scrollY.onChange(latest => {
      window.scrollTo(0, latest);
    });

  }, [scrollY]);


  const TrendChooser = ({trendId, trendIndex, children}: {trendId:string, trendIndex: number, children:Node}): Node => {

    function clickHandler() {
      const targetElement = document.getElementById(trendId);
      if (targetElement) {
        const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
        scrollY.set(targetPosition - 150);
      }
    }

    let chooserColor = '#FF4752';

    return (
      <motion.div onClick={clickHandler}
      initial={{
        scale: 1,
        y: 125,
      }}
      animate={{ y: 0 }}
      transition={{ type: "spring", stiffness: 100, delay: 0.1*trendIndex }}
      whileHover={{
        scale: 1.2,
        transition: { duration: 0.5 },
      }}
        style={{
          textAlign: 'center',
          marginRight: '2rem',
          backgroundColor: chooserColor,
          borderRadius: '20rem',
          width: '10rem',
          height: '10rem',
          color: '#FFF',
          cursor: 'pointer',
          transform: 'rotate(6deg)',
          boxShadow: `0px 0px 6px 6px rgba(0,0,0,0.10), inset 0 0 0 4px ${rgb(chooserColor).darken(0.2).string()},  2px 2px 0 2px ${rgb(chooserColor).darken(0.25).string()}`,
        }}>
        <h1 style={{
          fontSize: '8rem',
          fontWeight: '900',
          color: rgb(chooserColor).darken(0.35).string(),
          lineHeight: '1.2',
          textShadow: `1px 1px 2px ${rgb(chooserColor).darken(0.2).string()}`,

        }}>
          {children}
        </h1>
      </motion.div>
    );
  };

  const Headline = ({backgroundColor}: {backgroundColor: string}): Node => {
    let headlineCss = css`
        margin: 16rem auto 12rem auto;
        color: ${rgb(backgroundColor).darken(0.33).string()};
        text-shadow: 1px 1px 1px ${rgb(backgroundColor).lighten(0.2).string()};
        h1 {
          font-family: 'playfair-display', serif;
          font-weight: 900;
          font-size: 8rem;
        }
        h4 {
          font-size: 4.0rem;
        }

        @media (max-width: ${Sizes.breakpointMobile} ) {
          margin: 4rem auto 4rem auto;
          padding: 0 2rem 0 2rem;
          h1 {
            font-size: 6rem;
          }
          h4 {
            font-size:3rem;
          }
        }
        `;
    return (
      <div css={headlineCss}>
        <h1>Marketing Trends from 2023</h1>
        <h4>by Brad Bouse, Lightboard co-founder</h4>
      </div>
    );
  };

  const backgroundColor = "#0C3667";
  return (
    <LayoutBasic title="2023 B2B Marketing Trends">
      <div css={pageCss} style={{
        backgroundColor: backgroundColor ,
        padding: "5rem 0 20rem 0",
        marginBottom: "-200px"
      }}
      >
        <motion.div className='mobile-hidden' style={{
          position: 'fixed',
          bottom: 20,
          left: 20,
          zIndex: 1004,
        }}>

          <div style={{display: 'flex'}}>
            <TrendChooser trendId="ai" trendIndex={0}>
              1
            </TrendChooser>
            <TrendChooser trendId="webflow"  trendIndex={1}>
              2
            </TrendChooser>
            <TrendChooser trendId="figma"  trendIndex={2}>
              3
            </TrendChooser>
            <TrendChooser trendId="docs"  trendIndex={3}>
              4
            </TrendChooser>
          </div>
        </motion.div>
        <motion.div
          style={{textAlign: 'center'}}
          transition={{ type: "inertia", velocity: 100 }}
        >
          <Headline backgroundColor={backgroundColor} />
          <TrendIntro />
          <TrendAI />
          <TrendWebflow />
          <TrendFigma />
          <TrendTransitionDocs />
          <TrendConclusion />
          <Readable>
            <FluidImage className="spaced" src="playbook/signholders.png" />
          </Readable>
        </motion.div>
      </div>
      </LayoutBasic>
  );
};

export default TwentyTwentyFour;
