//@flow
import * as React from "react";
import { Row, Column, Section } from '../../../utils/layout/grid';
import { useProjectTypes } from '../../../utils/graphql/project_types';
import { DefaultButton } from '../../forms/buttons/index';
import { FluidImage } from '../../ui/images/fluid';
import { Link } from 'gatsby';

const ProjectTypesDetails = ({children}: {children?: React.Node}): React.Node => {
  let projectTypes = useProjectTypes();
  return (
    <Section padded>
      {projectTypes.map(projectType => {
        if (!projectType.featured) return;
        return (
          <Row key={projectType.slug} vcenter>
            <Column size={3} textCenter>
              <FluidImage src={`project-icons/${projectType.icon}.png`} style={{'margin':'3rem auto'}}/>
            </Column>
            <Column size={9}>
              <h4>{projectType.title}</h4>
              <p>{projectType.description}</p>
              <ul className='flex-list headroom'>
                <li><Link to={projectType.examples}><DefaultButton>Portfolio</DefaultButton></Link></li>
                <li><a href={projectType.slug}><DefaultButton>Details</DefaultButton></a></li>
              </ul>
            </Column>
          </Row>);
      })}
    </Section>
  );
};

export default ProjectTypesDetails;
