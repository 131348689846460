//@flow
import * as React from "react";

import LayoutBasic from '../../layouts/layout-basic';
import { AssetPreview } from './asset-preview';
import { Section, Row, Column } from '../../../utils/layout/grid';
import Breadcrumbs from '../../ui/breadcrumbs/index';

const AssetDetail = ({ pageContext }: {pageContext: Object}):React.Node => {
  let { asset } = pageContext;
  return (
    <LayoutBasic pageTitle={`Download ${asset.title}`} pageDescription={asset.description} solidMenu>
      <Breadcrumbs base={{url:'/downloads', title:'Downloads'}}>
        {asset.title}
      </Breadcrumbs>
      <Section padded>
        <Row>
          <Column>
            <AssetPreview asset={asset}/>
          </Column>
        </Row>
      </Section>
    </LayoutBasic>
  );
};

export default AssetDetail;
