//@flow
import type { Node } from "react";

import React from "react";
import { CaseStudies } from '../../components/pages/downloads';

import { Column, Row, Section } from "../../utils/layout/grid";
import RequestDownloads from '../../components/pages/downloads/request-downloads';
import PortfolioCallout from '../../components/pages/shared/portfolio-callout';
import LayoutLifted from '../../components/layouts/layout-lifted';
import Breadcrumbs from '../../components/ui/breadcrumbs/index';

const CaseStudyTemplates = (): Node => {

  const desc = "No one can tell your story better than a happy customer. Case studies give your best customers an opportunity to share the benefits of your product with the world.";

  let portal={'url':'lightboard-icon.png', 'color':'#225691'};

  let breadcrumbs = (<Breadcrumbs base={{url:'/downloads', title:'Downloads'}}>
    Case Study Templates
  </Breadcrumbs>);

  return (
    <LayoutLifted portal={portal} breadcrumbs={breadcrumbs} pageTitle="Free Marketing Design Downloads" pageDescription={desc} heroUrl="downloads/case-studies-hero.png" onViewEvent="downloads-case-studies" solidMenu disableRetarget>
      <Section>
        <Row>
          <Column readable>
            <h1>Case Study Templates</h1>

            <p>No one can tell your story better than a happy customer. Case studies give your best customers an opportunity to share the benefits of your product with the world.</p>

            <p>And while it's great for your self-esteem to hear great things about your work, they also resonate with your potential clients who want real-world examples of the benefits you bring.</p>

            <p>Having case studies on your site or available as PDFs is an integral part of a smart advocacy marketing plan. Making sure that your case studies all have the same look and feel lends credibility and professionalism to your brand.</p>

            <p>If you need help writing your case study, here's <a href="/blog/case-study-interview/">our guide to conducting a great customer interview</a> and <a href="/blog/how-to-structure-a-case-study-that-converts/">tips on writing a case study that converts</a>.</p>

            <p>Here's six great case study templates you can use to get started. If you need a custom template designed, we're <a href="/projects/documents">here to help</a>.</p>

          </Column>
        </Row>
      </Section>
      <Section padded className='spaced'>
        <Row fluid>
          <Column>
            <CaseStudies/>
          </Column>
        </Row>
      </Section>
      <RequestDownloads/>
      <Section inset padded>
        <Row fluid>
          <Column>
            <PortfolioCallout/>
          </Column>
        </Row>
      </Section>
    </LayoutLifted>
  );
};

export default CaseStudyTemplates;
