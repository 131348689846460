//@flow
import * as React from "react";
import { Row, Column, Section } from '../../../utils/layout/grid';
import { useProjectTypes } from '../../../utils/graphql/project_types';
import { PrimaryButton } from '../../forms/buttons/index';
import { FluidImage } from '../../ui/images/fluid';
import { Panel } from '../../containers/panel';

const ProjectTypes = ({children}: {children?: React.Node}): React.Node => {
  let projectTypes = useProjectTypes();

  let defaultIntro = (
    <div className='text-center footroom'>
      <h2>What can we design for you?</h2>
      <p>Our team of designers, developers, illustrators, and animators can deliver (almost) any type of project.</p>
    </div>);

  return (
    <Section padded>
      <Row>
        <Column>
          {children ? children : defaultIntro}
        </Column>
      </Row>
      <Row balancedGutters>
        {projectTypes.map(projectType => {
          if (!projectType.featured) return;
          return (<Column size={4} key={projectType.slug} textCenter>
            <a href={projectType.slug}>
              <Panel height={320} >
                <FluidImage src={`project-icons/${projectType.icon}.png`} width={200} style={{'margin':'auto'}}/>
                <h4>{projectType.title}</h4>
                <p>{projectType.shortDescription}</p>
                <div className='spaced-half'>
                  <PrimaryButton>Details</PrimaryButton>
                </div>
              </Panel>
            </a>
          </Column>);
        })}
      </Row>
    </Section>
  );
};

export default ProjectTypes;
