//@flow
import * as React from "react";
import Breadcrumbs from '../ui/breadcrumbs/index';
import type { LiftedLayoutProps } from './layout-lifted';
import LayoutLifted from './layout-lifted';
import PortfolioFooter from '../pages/portfolio/portfolio-footer';

interface Props extends LiftedLayoutProps {
  breadcrumbsTitle?: string,
  heroUrl?: string,
}

const LayoutPortfolio = (props:Props): React.Node => {

  let { breadcrumbsTitle, children, heroUrl, ...rest } = props;

  let breadcrumbs;

  if (breadcrumbsTitle) {

    breadcrumbs = (<Breadcrumbs base={{url:'/portfolio', title:'Portfolio'}}>
      {breadcrumbsTitle}
    </Breadcrumbs>);
  }

  return (
    <LayoutLifted
      liftedHeight="-70vh"
      heroUrl={heroUrl}
      breadcrumbs={breadcrumbs}
      {...rest}
      fadeHero={true}
      solidMenu
    >
      {children}
      <PortfolioFooter/>
    </LayoutLifted>
  );
};

export default LayoutPortfolio;
