//@flow

import * as React from "react";
import { css }  from '@emotion/react';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { Link } from 'gatsby';

const colors = {
  red: ['#f91f2e', '#fa515c'],
  blue: ['#3dabfb', '#6fc1fc'],
};

type Color = 'red' | 'blue';

export default (props: { to?: string, arrowIcon?: boolean, color: Color, children: any }): React.Node => {
  const [primary, lightened] = colors[props.color];
  const linkCss = css({
    backgroundColor: primary,
    padding: '1em 3rem',
    fontSize: '2rem',
    fontFamily: 'mr-eaves-xl-modern',
    border: 'none',
    textTransform: 'none',
    transition: 'background-color 0.5s ease, box-shadow 0.5s ease',
    boxShadow: 'extrude-shadow(1, darken($button-bg-color, 10))',
    margin: '1rem auto auto auto',
    color: 'white !important',
    lineHeight: '22px',
    minHeight: '32px',
    borderRadius: '4px',
    textDecoration: 'none',
    display: 'inline-block',
    span: {
      verticalAlign: 'center'
    },
    '&:hover': {
      border: 'none', //yes, we need this again to override global a:hover styles
      backgroundColor: lightened,
      boxShadow: `1px 1px 0 ${primary},2px 2px 0 ${primary},3px 3px 0 ${primary},4px 4px 0 ${primary},5px 5px 0 ${primary},6px 6px 0 ${primary}`
    }
  });

  let icon;

  if (props.arrowIcon) {
    icon = <FaLongArrowAltRight style ={{ verticalAlign: 'middle' }} />;
  }

  let content = (<>
    <span css={css`margin-right: 1rem;`}>{props.children}</span>
    {icon}
  </>);

  if (props.to) {
    return <Link to={props.to} css={linkCss}>{content}</Link>;
  } else {
    return <div css={linkCss}>{content}</div>;
  }

};
