//@flow
import * as React from 'react';
import CaptureForm from '../capture_form';
import Error from '../../../components/containers/error';
import useTracking from '../../../utils/track/use_tracking';
import LoadingButton from '../buttons/loading-button';
import Emoji  from 'react-emoji-render';
import ConfettiDrop from '../../ui/confetti-drop';

type Props = {
  buttonLabel?: string,
  formLocation?: string
};

const EmailForm = ({
  buttonLabel='Submit',
  formLocation
}: Props):React.Node => {
  const track = useTracking();

  function onFocus(fieldName: string) {
    track.event('form-focus', { 'location': formLocation, 'field': fieldName });
  }

  return (
    <CaptureForm formLocation={formLocation} render={({ phase, email, errorMessage, setEmail, onSubmit }) => {

      if (phase==='submitted') {
        return (
          <>
            <ConfettiDrop/>
            <div className='form-friendly'>
              <h2><Emoji text="😍" /><span>Awesome! You'll hear from us shortly.</span></h2>
            </div>
          </>);
      }

      return (
        <form className='form-friendly form-single-line' onSubmit={onSubmit}>
          <input
            type="email"
            placeholder="Your Work Email"
            value={email}
            onFocus={() => onFocus('email')}
            onChange={e => setEmail(e.target.value)}
          />
          <LoadingButton loading={(phase==='submitting')}>{buttonLabel}</LoadingButton>
          {(phase === 'error') && <Error>{errorMessage || <span>Shoot, we had a problem saving your email address.</span>}</Error>}
        </form>);
    }} />
  );
};

export default EmailForm;
