//@flow
import * as React from "react";
import GridContext from '../context';
import { Readable } from '../readable';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import columnStyles from './styles';

type ColumnProps = {
  children?: React.Node,
  className?: string,
  css?: any,
  size?: number,
  half?: boolean,
  min? : boolean,
  max? : boolean,
  mobileSize?: number,
  readable?: boolean,
  relative?: boolean,
  textCenter?: boolean
}

const blacklist = { min: true, max: true, size: true };

const excludeMinMaxProps = (prop: string) => !blacklist[prop] && isPropValid(prop);

const ColumnLayout = styled('div', { shouldForwardProp: excludeMinMaxProps})(columnStyles);

export const Column = (props: ColumnProps): React.MixedElement => {
  const { readable, children} = props;

  return (
    <GridContext.Consumer>
      {(context) => (
        <ColumnLayout {...context} {...props}>
          {readable && <Readable>{children}</Readable>}
          {!readable && children}
        </ColumnLayout>
      )}
    </GridContext.Consumer>
  );
};

export const ColumnHalf = (props: ColumnProps): React.MixedElement => (<Column {...props} size={0.5} half />);
export const ColumnMin = (props: ColumnProps): React.MixedElement => (<Column {...props} min />);
export const ColumnMax = (props: ColumnProps): React.MixedElement => (<Column {...props} max />);
