//@flow
import { useStaticQuery, graphql } from 'gatsby';
import type { AssetDownload } from '../../types/downloads';
export const useCaseStudiesDownloads = ():AssetDownload[] => {
  const { allCaseStudyTemplatesJson } = useStaticQuery(
    graphql`
      query {
        allCaseStudyTemplatesJson (sort: {fields:priority}) {
          edges {
            node {
              slug
              title
              description
            }
          }
        }
      }
    `
  );
  let caseStudies = allCaseStudyTemplatesJson.edges.map(caseStudy => (
    {
      downloadType: 'case-study-templates',
      typeDescription:'Case Study',
      fileExtension: 'zip',
      ...caseStudy.node
    }));
  return caseStudies;
};
