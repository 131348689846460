//@flow
import React from "react";
import type { Node } from "react";

import { Column, Row, Section } from "../../utils/layout/grid";
import { ImageCaption, LogoImage } from "../../components/ui";
import LayoutCaseStudy from '../../components/layouts/layout-case-study';
import { useCaseStudiesIndex } from '../../utils/graphql/case_studies';
import CompareImage from '../../components/ui/images/compare-image';

const BallerTV =  (): Node => {

  let caseStudy = useCaseStudiesIndex('baller-tv');

  return (
    <LayoutCaseStudy caseStudy={caseStudy} heroUrl='case-studies/baller-tv/hero.png'>
      <Section>
        <Row>
          <Column readable>
            <h2>Design Support For Growing Teams</h2>
            <p>Startups know far too well that time and resources are finite, and building a design team can be a distraction when you're focusing on rapid growth.
              Before BallerTV was ready to hire a team of in-house designers, they needed a solution to keep up with their demand and workload for everyday design.</p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column readable>
            <LogoImage src="logos/baller-tv.png" width={300}/>
            <p>BallerTV delivers live coverage, replays, and highlights of
              amateur athletic events. With unprecedented access to game footage and sports media opportunities, BallerTV
              is rapidly expanding the spectrum of sports coverage with more footage and insights for student-athletes.</p>
            <h2 className='headroom'>Business Situation</h2>
            <p>BallerTV needed a design  partner able to help them get up and running before they were ready to build their
              own team of designers and developers.</p>
            <p>Lightboard was there to create templated HTML emails, infosheets, display ads, landing pages, and sales collateral.
              Our ongoing design support allowed BallerTV to grow their brand awareness, and grow into the design team they needed.
            </p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/baller-tv/ads.png'/>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column readable>
            <h2>From Wireframe to Finished Webpage</h2>
            <p>BallerTV shared wireframes of each webpage they they needed designed and developed.
              With a general webpage structure and copy, Lightboard's team was able to quickly produce
              landing pages that got BallerTV's name out in the world.</p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid >
          <Column>
            <CompareImage fluid left="case-studies/baller-tv/frametofinal-b.png" right="case-studies/baller-tv/frametofinal-c.png"/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>Mobile Ready</h2>
            <p>Our designs were mobile-first. BallerTV's audience want access to sports
              highlights and replays from anywhere, so a great mobile experience was a pre-requisite.</p>
            <p>BallerTV's website is packed with videos and tons of content, so it was crucial to make
              the mobile version clean and usable on smaller screens.</p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/baller-tv/mobile-home.png'/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>A Seamless Mobile Experience</h2>
            <p>With BallerTV's marketing websites approved, we moved on to tackling the UX and design for an admin portal for BallerTV's contractors. Using Sketch to prototype the portal's user interface, we designed and implemented 17 screens of mobile flow.</p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/baller-tv/mobile-portal.png'>
              App sign-in, scheduling, event manager, and payment screens for BallerTV's contractor portal.
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>Supporting Digital Strategy</h2>
            <p>With the web, mobile, and app user experience and design accomplished, Lightboard turned attention
              to supporting other customer touch points like HTML emails and pricing pages.
            </p>

            <p>By creating a complete and cohesive design motif throughout all customer experiences,
              Lightboard fully supported BallerTV in their design efforts. Now, any experience with BallerTV
              gives the customer the same, natural feel for their brand.</p>

            <p>Lightboard was an ideal design solution for BallerTV who needed an extension of their team to
              help them grow and establish their brand before they could take their design in-house. Lightboard provided
              design, development, and implementation across their digital experiences. When BallerTV was ready to grow,
              it was a seamless hand-off to their internal team.</p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid >
          <Column>
            <ImageCaption src='case-studies/baller-tv/site-overview.png'/>
          </Column>
        </Row>
      </Section>
    </LayoutCaseStudy>
  );
};

export default BallerTV;
