//@flow
import styled from '@emotion/styled';

const backgroundGradient=['#055598','#003963'];

export const PricingBadge: any =  styled.div({
  margin: '2rem 1rem 0 1rem',
  borderRadius: '1rem',
  border: 'solid 1px',
  borderColor: `#3E75F7`,
  padding: '2rem 1rem',
  textAlign: 'center',
  background: `linear-gradient(to bottom,${backgroundGradient[0]},${backgroundGradient[1]})`,
  color: '#FFF',
  h1 : {
    borderBottom: `solid 0px #FFF`,
    padding: '0 0 0.5rem 0',
    margin: '0 2rem 1rem 2rem'
  },
  'h2.pricing' : {
    borderTop: `solid 1px #003963`,
    fontSize: '6rem',
    fontWeight: '800',
    padding: '1rem 0 0.5rem 0',
    margin: '2rem 2rem 0rem 2rem'
  }
});
