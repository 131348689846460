// @flow
import type { Node } from "react";
import React from "react";
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useSiteMetadata } from '../../../utils/graphql/site_metadata';
import AppContext from '../../../utils/track/context';
import { PrimaryButton } from '../../forms/buttons/index';

type DestinationProps = {
  registered: boolean,
  fetched: boolean,
  lbAppHost: string,
}

const destination = ({ registered, fetched, lbAppHost }: DestinationProps) => {
  if (!fetched) return '#';
  return `${lbAppHost}${registered ? '/' : '/register'}`;
};

const size = 24;

const Avatar = styled.div(({avatarUrl}) => ({
  right: -(size / 2),
  margin: 'auto 0',
  borderRadius: size / 2,
  height: size,
  width: size,
  backgroundPosition: `center`,
  backgroundSize: `cover`,
  backgroundImage: `url(${avatarUrl})`,
}));

type ButtonProps = {
  avatarUrl?: string,
  registered: boolean,
  fetched?: boolean,
};

const signInCaption = 'Sign In';
const signUpCaption = 'Sign Up';

type CaptionProps = {
  fetched: boolean,
  registered: boolean,
};

const Caption = ({fetched, registered}: CaptionProps) => (
  <div css={css`
    flex-grow: 1;
    flex-shrink: 0;
  `}>
    {fetched ? ( registered ? signInCaption : signUpCaption ) : <>&nbsp;</>}
  </div>
);

const DesktopGoToAppButton = ({ avatarUrl, registered, fetched }: ButtonProps ) => (
  <button css={css`
    min-width: 12rem;
    padding: 0.75rem 1rem !important;
  `}>
    <div css={css`
      display: flex;
      align-items: center;
      width: 100%
    `}>
      <Caption fetched={fetched || false} registered={registered} />
      {avatarUrl && <Avatar avatarUrl={avatarUrl} />}
    </div>
  </button>
);

const MobileGoToAppButton = ({ registered, avatarUrl, fetched}: ButtonProps) => (
  <PrimaryButton css={css`
  `}>
    <Caption fetched={fetched || false} registered={registered} />
  </PrimaryButton>
);

const GoToAppButton = ({ desktop }: {desktop?: boolean}):Node => {
  const Button = desktop ? DesktopGoToAppButton : MobileGoToAppButton;

  let { lbAppHost } = useSiteMetadata();

  return (
    <AppContext.Consumer>
      {({ registered, fetched, avatarUrl }) => (
        <a href={destination({ registered, fetched, lbAppHost })}>
          <Button registered={registered} fetched={fetched} avatarUrl={avatarUrl} />
        </a>
      )}
    </AppContext.Consumer>
  );
};

export default GoToAppButton;
