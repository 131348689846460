//@flow
import type { Node } from "react";
import React from "react";
import { withPrefix } from 'gatsby';
import { FluidImage } from '../components/ui/images/fluid';

import Layout from "../components/layouts/layout-basic";
import { HeroVideo, HeroAngle } from "../components/heroes";
import { Column, Spread, SpreadPanel, Readable, Row, Section } from "../utils/layout/grid";
import { ExtrudedText } from '../components/ui';
import EmailForm from '../components/forms/email';
import colors from '../utils/config/colors';
import ProjectTypes from '../components/pages/shared/project-types';
import StartCta from '../components/pages/shared/start-cta';
import PortfolioCallout from '../components/pages/shared/portfolio-callout';
import ExtrudedButton from '../components/ui/extruded-button';

const ColoringBook = ({ data }: { data: Object }): Node => {
  let primaryHeroColor = colors.primaryHero;
  let secondaryHeroColor = colors.secondaryHero;

  return (
    <Layout pageTitle="Marketing Coloring Book from Lightboard" pageDescription="Marketing is stressful. Blow off some steam with this coloring book." solidMenu>
      <HeroVideo
        mp4={withPrefix('/videos/coloring-book-timelapse.mp4')}
        webm={withPrefix('/videos/coloring-book-timelapse.webm')}
      />
      <HeroAngle backgroundColor={secondaryHeroColor} patchColor={primaryHeroColor}>
        <Spread fluid vcenter>
          <SpreadPanel>
            <Readable left>
              <ExtrudedText headlineText="Color!" extrudeColor={secondaryHeroColor} />
              <h1>A coloring book for marketers.</h1>
              <p>Marketing is stressful. Blow off some steam with this coloring book.</p>
              <p>Maybe the best coloring book ever. Maybe.</p>
              <a href={withPrefix("/pdfs/coloring-book-preview.pdf")} target="_blank" rel="noreferrer">
                <ExtrudedButton arrowIcon color='red'>Download Preview</ExtrudedButton>
              </a>
            </Readable>
          </SpreadPanel>
          <SpreadPanel>
            <FluidImage src='coloring-book/stacked-pages.png' />
          </SpreadPanel>
        </Spread>
      </HeroAngle>
      <HeroAngle backgroundColor={primaryHeroColor}>
        <Spread flopped fluid vcenter>
          <SpreadPanel>
            <ExtrudedText headlineText="Want one?" extrudeColor={primaryHeroColor} />
            <Readable left>
              <p>Add your email below to be the first to know when it's ready to ship.</p>
              <EmailForm buttonLabel='ADD ME!' />
            </Readable>
          </SpreadPanel>
          <SpreadPanel>
            <FluidImage src='coloring-book/book-cover.png' />
          </SpreadPanel>
        </Spread>
      </HeroAngle>
      <HeroAngle backgroundColor="#ffffff" bodyColor="#000000">
        <Spread fluid vcenter>
          <SpreadPanel>
              <Readable left short>
                <ExtrudedText headlineText="About Us" extrudeColor="#FFFFFF"/>
                  <p>Lightboard is a design agency that works as an extension of your marketing team. Based in Seattle, we've completed thousands of projects for marketing departments across the country with companies like Autodesk, BloomReach, and Trello.</p>
                  <ExtrudedButton arrowIcon to='/portfolio' color='blue'>See our portfolio</ExtrudedButton>
              </Readable>
          </SpreadPanel>
          <SpreadPanel>
              <FluidImage src='home/portfolio.png'/>
          </SpreadPanel>
        </Spread>
      </HeroAngle>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h2>How we can help you</h2>
              <p>As a marketer, you've got a lot to manage. Analytics. Copywriting. Strategy. And on top of it all, you've got to manage designers.</p>
              <p>We can help.</p>
              <p>With Lightboard, you never need to worry about finding a designer or managing a project again. Our account managers learn about you and your company, and make sure every project we deliver is on-time and on-brand—and they're just a phone call or email away whenever you have a question.</p>
              <p>Through our browser-based collaboration software, all of your projects are at your fingertips. Invite your co-workers to create and review projects, and rest easy knowing we'll deliver great design with clear budgets and reliable schedules.</p>
              <p>Join the hundreds of companies—like 3M, Autodesk, Flexport, ShareThis, and Trello—that are switching to Lightboard.</p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <PortfolioCallout/>
      </Section>
      <ProjectTypes/>
      <StartCta/>
    </Layout>
  );
};

export default ColoringBook;
