 // @flow
import * as React from "react";

import { Section, Row, Column, Spread, SpreadPanel, Readable } from "../../utils/layout/grid";
import { FillerBlock, FillerText } from "../placeholders";
import CompareImage from '../ui/images/compare-image';
import { Centerer } from '../../utils/layout/grid';

const Demo = ():React.Node => {
  return (
    <>
      <Section gutters>
        <Row fluid>
          <Column>
            <FillerBlock height={600}>
              <Centerer>
                <h2> This is a hero.</h2>
              </Centerer>
            </FillerBlock>
          </Column>
        </Row>
      </Section>
      <Section gutters>
        <Row>
          <Column>
            <div className='text-center spaced-2x'>
              <h2> Centered Text</h2>
              <Readable>
                <p><FillerText/></p>
                <p><FillerText/></p>
              </Readable>
            </div>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <p className='spaced'>
              <FillerText/>
              <FillerText/>
              <FillerText/>
              <FillerText/>
              <FillerText/>
            </p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <p className='spaced'><FillerText/>
              <FillerText/>
              <FillerText/>
              <FillerText/>
              <FillerText/></p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Spread gutters fluid vcenter alignCenter>
        <SpreadPanel>
          <FillerBlock height={300} />
        </SpreadPanel>
        <SpreadPanel>
            <Readable align='right'>
              <h2> Two-up (bleed + center-aligned)</h2>
              <p><FillerText/></p>
            </Readable>
        </SpreadPanel>
      </Spread>
      <Spread gutters fluid vcenter alignCenter flopped>
        <SpreadPanel>
          <FillerBlock height={300} />
        </SpreadPanel>
        <SpreadPanel>
            <Readable align='right'>
              <h2> Two-up (flopped bleed + center-aligned)</h2>
              <p><FillerText/></p>
            </Readable>
        </SpreadPanel>
      </Spread>
      <Section>
        <Row>
          <Column>
            <h2 className='text-center spaced'>The Grid</h2>
          </Column>
        </Row>
      </Section>
      <Section gutters>
        <Row>
          <Column size={6}>
            <FillerBlock/>
          </Column>
          <Column size={6}>
            <FillerBlock/>
          </Column>
        </Row>
      </Section>
      <Section gutters>
        <Row>
          <Column size={4}>
            <FillerBlock/>
          </Column>
          <Column size={4}>
            <FillerBlock/>
          </Column>
          <Column size={4}>
            <FillerBlock/>
          </Column>
        </Row>
      </Section>
      <Section gutters>
        <Row>
          <Column size={3}>
            <FillerBlock/>
          </Column>
          <Column size={3}>
            <FillerBlock/>
          </Column>
          <Column size={3}>
            <FillerBlock/>
          </Column>
          <Column size={3}>
            <FillerBlock/>
          </Column>
        </Row>
      </Section>
      <Section gutters>
        <Row >
          <Column size={2}>
            <FillerBlock/>
          </Column>
          <Column size={2}>
            <FillerBlock/>
          </Column>
          <Column size={2}>
            <FillerBlock/>
          </Column>
          <Column size={2}>
            <FillerBlock/>
          </Column>
          <Column size={2}>
            <FillerBlock/>
          </Column>
          <Column size={2}>
            <FillerBlock/>
          </Column>
        </Row>
      </Section>
      <Section padded spaced>
        <Row>
          <Column textCenter>
            <h2>Compare Images (remote URL)</h2>
            <CompareImage className='spaced' left="//portfolio.lightboard.io/before-after/nova-before-large.png" right="//portfolio.lightboard.io/before-after/nova-after-large.png"/>
          </Column>
        </Row>
      </Section>
      <Section padded spaced>
        <Row>
          <Column textCenter>
            <h2>Compare Images (fluid)</h2>
            <CompareImage className='spaced' fluid left="case-studies/usermind/before-after-a.png" right="case-studies/usermind/before-after-b.png"/>
          </Column>
        </Row>
      </Section>
    </>
  );
};

export default Demo;
