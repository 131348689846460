//@flow
import type { Node } from "react";
import React from "react";
import { Column, Row, Section } from "../../utils/layout/grid";
import LayoutLifted from '../../components/layouts/layout-lifted';

const AutodeskDemo =  (): Node => {
  //TODO: this is WIP
  return (
    <LayoutLifted heroUrl='case-studies/redshift/hero.png' pageTitle="Lightboard + Autodesk">
      <Section>
        <Row>
          <Column readable>
            <h1>Lightboard + Autodesk</h1>
            <p>Autodesk has worked with Lightboard since April, 2016, completing more than 300 projects for four teams and 35 members.</p>
            <h4>How it works</h4>
            <ul>
              <li>Add budget to your Lightboard account</li>
              <li>Invite your team</li>
              <li>Request projects</li>
              <li>Review and iterate through Lightboard platform</li>
            </ul>

            <h4>Highlights</h4>
            <ul>
              <li>Design team skilled in illustration, animation, layout, and web development</li>
              <li>Experience with Autodesk brand standards, and multiple brands (Redshift, AutoCAD SMB, AutoCAD AEC)</li>
              <li>We're already set up in Ariba as a Vendor</li>
              <li>We're already set up with Autodesk accounts and DAM/Draftr access</li>
            </ul>
          </Column>
        </Row>
      </Section>
    </LayoutLifted>
   );
};

export default AutodeskDemo;
