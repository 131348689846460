// @flow
import * as React from 'react';
import styled  from '@emotion/styled';

import Panel, { FlipPanel } from './panel';

type Props = {
  background: string,
  borderWidth: string,
  characterWidth: ?string,
  prevValue: string,
  step: number,
  textColor: string,
  value: string,
};

/* eslint-disable indent */
const Character = styled.div`
  background: ${({ background }: { background: string }): string => background};
  display: flex;
  justify-content: center;
  width: ${({ characterWidth }: { characterWidth: ?string }): string =>
    characterWidth || 'initial'};
  padding: 0.5em;
  position: relative;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.45);
  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    border-bottom: rgba(0,0,0,0.25) 1px solid;
  }
`;

export default (
  {
    background,
    borderWidth,
    characterWidth,
    prevValue,
    step,
    textColor,
    value
  }: Props,
): React.MixedElement => (
  <Character background={background} borderWidth={borderWidth} characterWidth={characterWidth}>
    <Panel position="top" background={background} textColor={textColor} value={value} />
    <Panel position="bottom" background={background} textColor={textColor} value={prevValue} />
    {prevValue !== value && (
      <FlipPanel
        direction="out"
        duration={step / 1000}
        position="top"
        background={background}
        textColor={textColor}
        value={prevValue}
      />
    )}
    {prevValue !== value && (
      <FlipPanel
        direction="in"
        duration={step / 1000}
        position="bottom"
        background={background}
        textColor={textColor}
        value={value}
      />
    )}
  </Character>
);
