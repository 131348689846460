//@flow
import * as React from "react";
import Layout from '../components/layouts/layout-basic';
import { Readable } from '../utils/layout/grid';
import { FluidImage } from '../components/ui/images/fluid';
import withApiFetch from "../utils/with_api_fetch";
import type { ApiFetch } from "../utils/with_api_fetch";
import type { ComponentType } from "react";

class NotFound extends React.Component<{ apiFetch: ApiFetch}> {
  render(): React.MixedElement {
    return (
      <Layout skipLookup solidMenu status={404}>
        <Readable>
          <div className="headroom-2x text-center">
            <FluidImage src="site/404-pencil.png" alt="Page not found" />
            <h2 className='headroom'>Page Not Found</h2>
            <p>Sorry, we couldn't find this page.</p>
            <p><a href="/">Back to Lightboard</a></p>
          </div>
        </Readable>
      </Layout>
    );
  }
}

export default (withApiFetch(NotFound): ComponentType<any>);
