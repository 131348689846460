import React from 'react';

export const toSentence = (list: string[]) => {
  if (list.length===1) {
    return <span>{list[0]}</span>;
  } else if (list.length===2) {
    return <span>{list[0]} and {list[1]}</span>;
  } else {
    return <span>{list.slice(0, list.length - 1).join(', ')}, and {list.slice(-1)}</span>;
  }
};
