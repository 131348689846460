//@flow
import type { Node } from 'react';
import * as React from "react";
import Emoji from 'react-emoji-render';
import { css } from '@emotion/react';
import Confetti from "../components/ui/confetti";

import Layout from "../components/layouts/layout-basic";
import { Column, ColumnMax, ColumnMin, Row, Readable, Section } from "../utils/layout/grid";
import ContactForm from '../components/forms/contact';
import EmailForm from '../components/forms/email/index';
import WhyLightboard from '../components/pages/shared/why-lightboard';
import BeforeAfter from '../components/pages/shared/before-after';
import colors from '../utils/config/colors';
import { StyledText } from '../components/ui/styled-text';

const Marvels = (): Node => {
  let blueSection = css({
    'backgroundColor':colors.headerColor,
    'position': 'relative'
  });

  return (
    <Layout pageTitle="Marketing Marvels" onViewEvent="marvels" solidMenu>
      <Section padded>
        <Row>
          <Column textCenter readable>
            <StyledText size={10} fontFamily='reklame-script' textTransform="none">
              <span>Hi there!</span>
              <Emoji text="👋" />
            </StyledText>
            <h2>Welcome Marketing Marvels Viewers!</h2>
            <p>Enter your email below to get the details about our Marketing Marvels discount.</p>
          </Column>
        </Row>
      </Section>
      <Section  padded css={blueSection}>
        <Row fluid>
          <Column>
            <Readable>
              <Section>
                <Row vcenter>
                  <ColumnMin>
                    <Emoji style={{'fontSize':'8rem'}} text="😍" />
                  </ColumnMin>
                  <ColumnMax>
                    <h4 style={{'color': '#FFFFFF'}}>Save $750 as a Marketing Marvel viewer</h4>
                    <EmailForm  buttonLabel='Learn More' formLocation='marvels-demo'/>
                    <small style={{'color':'#FFFFFF'}}>Discount available through April 1, 2019.</small>
                  </ColumnMax>
                </Row>
              </Section>
            </Readable>
          </Column>
        </Row>
      </Section>
      <WhyLightboard/>
      <BeforeAfter/>
      <Section css={blueSection}>
        <Confetti/>
        <div style={{'position':'relative', 'padding':'8rem'}}>
          <Row>
            <Column>
              <Readable>
                <Section css={css`background-color:#ffffff; padding: 4rem; border-radius: 0.5rem;z-index:10001;position:relative;`}>
                  <Row>
                    <Column>
                      <ContactForm formLocation="marvels"/>
                    </Column>
                  </Row>
                </Section>
              </Readable>
            </Column>
          </Row>
        </div>
      </Section>
    </Layout>
  );
};

export default Marvels;
