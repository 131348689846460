//@flow
import type { Node } from "react";
import React from "react";
import Layout from "../../components/layouts/layout-basic";
import { Link } from 'gatsby';
import { HeroFull } from "../../components/heroes";
import { Column, Readable, Row, Section } from "../../utils/layout/grid";
import FeaturesLayout from '../../components/pages/shared/features-layout';
import { CommonCtas } from '../../components/pages/shared/common-ctas';
import { useSiteMetadata } from '../../utils/graphql/site_metadata';

const SalesTeams =  (): Node => {
  let { lbAppHost } = useSiteMetadata();
  return (
    <Layout pageTitle="How Lightboard helps Sales Teams" pageDescription="We pair you with an experienced account manager who will take the time to understand your brand, budget, and goals.">
      <HeroFull backgroundImageUrl='customer-profiles/sales-teams.jpg'>
        <h4>How Lightboard Helps</h4>
        <h2>Sales Teams</h2>
        <p>Look your best with polished your sales materials.</p>
      </HeroFull>
      <Section>
        <Row>
          <Column>
            <Readable className='headroom'>
              <h1>More Lightboard & Sales Teams</h1>
              <p>We pair you with an experienced account manager who will take the time to understand your brand, budget, and goals.</p>
              <p>Invite your team to Lightboard, and you can all request and collaborate on the design that drives your marketing campaigns—presentations, one-sheets, email templates, and landing pages.</p>
              <p>We have experience working with the marketing platforms you use—from Hubspot to Salesforce to WordPress—so we can hit the ground running.</p>
              <p><Link to="/tour">Take a tour</Link> to learn more, or <a href={`${lbAppHost}/register`}>create an account</a> to get started.</p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <FeaturesLayout featureSet={['customizedCollateral', 'abTest', 'teamCollaborate', 'designDepot']} />
      <CommonCtas/>
    </Layout>
  );
};

export default SalesTeams;
