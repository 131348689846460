import type { CommonLayoutProps  } from '../../types';
import combineStyles from '../../utils/combine_styles';

const itemStyles = (props: CommonLayoutProps) => {
  let left = {
    order: 1,
  };

  let right = {
    order: 2,
  };

  if (props.alignCenter) {
    left.textAlign = 'right';
    right.textAlign = 'left';
  }

  let desktop: Object = {
    width: '50%',
  };

  let mobile: Object = {
    width: '100%',
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: 'auto'
  };

  const [a,b] =  (props.flopped) ? [2,1] : [1,2];

  if (props.gutters) {
    mobile.padding = '0 0 1rem 0';
    desktop.padding = '0 1rem 2rem 1rem';
  }

  if (props.vcenter) {
    desktop.alignItems = 'center';
  }

  desktop[`&:nth-of-type(${a})`] = left;
  desktop[`&:nth-of-type(${b})`] = right;

  return combineStyles(900, [[mobile, desktop]]);
};

export default itemStyles;
