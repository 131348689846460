//@flow
import React from "react";
import type { Node } from "react";


import { Column, Row, Section, Readable } from "../../utils/layout/grid";
import { ImageCaption, LogoImage } from "../../components/ui";
import LayoutCaseStudy from '../../components/layouts/layout-case-study';
import { useCaseStudiesIndex } from '../../utils/graphql/case_studies';
import { FluidImage } from '../../components/ui/images/fluid';
import { Link } from "gatsby";

const Redshift =  (): Node => {

  let caseStudy = useCaseStudiesIndex('redshift');

  return (
    <LayoutCaseStudy caseStudy={caseStudy} heroUrl="case-studies/redshift/hero.png">
      <Section>
        <Row>
          <Column readable>
            <h1>Defining An Illustration Style</h1>
            <p>Autodesk's Redshift blog partners with Lightboard to develop a unique illustration style for their editorial content.</p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column readable>
            <LogoImage className='footroom-half' src="logos/redshift.png" width={300}/>
            <p>Redshift explores the expanding forefront of architecture, construction, infrastructure, and manufacturing technology, focusing on the way the very nature of design is shifting. To give their content a distinctive and recognizable visual feel, Redshift worked with Lightboard to create needed a unique illustration style.</p>
            <p>Redshift is supported by Autodesk, creator of software including AutoCAD, Revit, Maya, 3ds Max, Fusion 360, SketchBook, and more.</p><br />

          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid >
          <Column>
            <ImageCaption src='case-studies/redshift/single-image-01.png'>
              Illustration for article, <a href = 'https://www.autodesk.com/redshift/staying-motivated-as-an-engineer/'>
              "What is your rocket fuel? Staying Motivated as an Engineer"</a> (Bunzel, A.) 2019.
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>Design Direction</h2>
            <p>Redshift asked Lightboard to create a unique, flexible illustration style that would adapt to digital and print–and cover a range of scenes from characters to cityscapes.</p>
            <p>As a design partner, Lightboard ensures Redshift's illustrations feel consistent. Every final illustration appears as though it came from
              the same source. The style is defined by bold, simple shapes and strong colors. Textures and sharp lines of darker tones help to add
              dimension to the otherwise flat design aesthetic, providing a distinctive visual brand language for Redshift.</p>
            <p></p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid gutters>
          <Column size={8}>
            <FluidImage src='case-studies/redshift/single-image-04.png'/>
          </Column>
          <Column size={4}>
            <FluidImage style={{'marginBottom':'0.5rem'}} src='case-studies/redshift/single-image-03.png'/>
            <FluidImage src='case-studies/redshift/single-image-02.png'/>
          </Column>
        </Row>
        <Row fluid gutters>
          <ImageCaption size={6.15} src='case-studies/redshift/single-image-05.png'>
            Illustration for article, <a href = 'https://www.autodesk.com/redshift/diversity-in-artificial-intelligence/'> "Why Adding Diversity in
            Artificial Intelligence Is Nonnegotiable"</a>.
          </ImageCaption>
          <ImageCaption size={5.85} src='case-studies/redshift/cityscape-feature.png'>
            Illustration for article, <a href = 'https://www.autodesk.com/redshift/megacities-of-the-future/'> "Cities of 2050:
            Data and Technology Will Fuel the Megacities of the Future"</a>.
          </ImageCaption>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>Defining a Style Guide</h2>
            <p>With the volume of illustrations we needed to produce for Redshift, we needed to formalize the look and feel with a style guide. Our goal: every illustration appears to be drawn by the same hand, no matter how many different people contributed.</p>
            <p>Building a style guide for illustration is a challenge because of the breadth of possibilities. We distilled the attributes of the illustration style down to  a few key traits: texture, shapes, and color.</p>
            <p>With the guide in hand, the Redshift editorial team can maintain consistency across the editorial illustrations, and helps to quickly get a new illustrator up to speed.</p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid >
          <ImageCaption size={8} compact src='case-studies/redshift/branding-01.png'/>
          <ImageCaption size={4} compact src='case-studies/redshift/branding-02.png'/>
        </Row>
      </Section>
      <Section>
        <Row fluid vcenter>
          <Column size={6}>
            <ImageCaption src='case-studies/redshift/editor-content.png'/>
          </Column>
          <Column size={6}>
            <Readable left>
              <h2>Inspiration From Editorial</h2>
              <p>The Redshift team shares a copy of the full article with us well before we start work on the illustration.</p>
              <p>After reading the article, most projects start with a brief kickoff call with our Creative Services Manager, the illustrator for the project, and the Redshift editor to flesh out the scenes for the article. We start with rough sketches to confirm direction, and then deliver the polished work.</p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid >
            <ImageCaption size={6} src='case-studies/redshift/sketches.png'>
              Our editorial illustrations start with sketches.
            </ImageCaption>
            <ImageCaption size={6} src='case-studies/redshift/toolkit.png'>
              Sketches are refined into polished spot illustrations.
            </ImageCaption>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>A Design Partnership</h2>
            <p>After more than a year, dozens of articles and more than a hundred illustrations, we're proud of our role in building the Redshift aesthetic.</p>

            <p>We love working with the team at Redshift. Take a look at our <Link to="/case-studies/redshift-motion-graphics">motion graphics for Redshift </Link> and our work with the <Link to="/case-studies/autocad">AutoCAD team</Link>.</p>
          </Column>
        </Row>
      </Section>
    </LayoutCaseStudy>
  );
};

export default Redshift;
