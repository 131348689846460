//@flow
import React from "react";
import type { Node } from "react";

import { PresentationCovers } from '../../components/pages/downloads';

import { Column, Row, Section } from "../../utils/layout/grid";
import { Link } from 'gatsby';
import RequestDownloads from '../../components/pages/downloads/request-downloads';
import PortfolioCallout from '../../components/pages/shared/portfolio-callout';
import LayoutLifted from '../../components/layouts/layout-lifted';
import Breadcrumbs from '../../components/ui/breadcrumbs/index';

const PresentationCoversList = (): Node => {
  const desc = "PowerPoint cover images, free to download and use.";

    let breadcrumbs = (<Breadcrumbs base={{url:'/downloads', title:'Downloads'}}>
      Presentation Covers
    </Breadcrumbs>);
    let portal={'url':'lightboard-icon.png', 'color':'#225691'};
  return (
    <LayoutLifted breadcrumbs={breadcrumbs} portal={portal} heroUrl='downloads/presentation-cover-hero.jpg' pageTitle="Free Marketing Design Downloads" pageDescription={desc} onViewEvent="downloads-presentation-covers" solidMenu disableRetarget>
      <Section>
        <Row>
          <Column readable>
            <h1>Presentation Cover Images</h1>
            <p> A cover page is a quick and easy way to add polish to your presentation. Here are ten free PowerPoint cover image templates you can download and use.</p>
            <p>The cover image sets the tone for your presentation—you don't want to dive right into the content—and is a great opportunity to start your deck off on the right foot.</p>
            <p>For more ideas, take a look at <Link to="/portfolio/presentations">our presentation design portfolio</Link> and if you need help designing your next project, we're <Link to="/projects">here to help</Link>.</p>
          </Column>
        </Row>
      </Section>
      <Section padded spaced>
        <Row fluid>
          <Column>
            <PresentationCovers />
          </Column>
        </Row>
      </Section>
      <RequestDownloads/>
      <Section padded>
        <Row fluid>
          <Column>
            <PortfolioCallout/>
          </Column>
        </Row>
      </Section>
    </LayoutLifted>
  );
};

export default PresentationCoversList;
