//@flow
import * as React from "react";
import { css } from '@emotion/react';
import { Section, Row, Column } from '../../../utils/layout/grid';
import HeroFull from '../../heroes/hero-full';
import Sizes from '../../../utils/config/breakpoints.json';

type Props = {
  breadcrumbs?: React.Node,
  portal?: React.Node,
  fadeHero?: boolean,
  heroImageUrl?: string,
  heroHeight: string,
  liftedHeight?: string,
  children: React.Node
}

const portalCss = css`
  z-index: 4;
  width: 240px;
  height: 240px;
  border-radius: 240px;
  background-color: #ffffff;
  box-shadow: 1px 2px 4px 2px rgba(0,0,0,0.15);
  position: absolute;
  left: 50%;
  top: -120px;
  transform: translate(-50%, 0%);
  overflow: hidden;
`;

const LiftedContent = ({portal, breadcrumbs, heroImageUrl, heroHeight, liftedHeight='-30vh', fadeHero, children}: Props): React.Node => {

  function renderPortal() {
    return <div css={portalCss}>{portal}</div>;
  }

  let innerCss = css({
    'paddingTop':(portal) ? '160px' : '0.5rem',
    'position': 'relative',
    'zIndex': '5'
  });

  let breadcrumbCss = css({
    'marginTop': (portal) ? '-110px' :'2rem',
    'marginBottom': (portal) ? '100px' :'2rem',
    'marginLeft':'6rem',
    '@media (max-width: 1100px)': {
      'display': 'none'
    }
  });

  let contentCss= css({
    'marginTop': liftedHeight,
    'position': 'relative',
    'z-index': '101'
  });

  let liftPanelCss = css`
    position: absolute;
    z-index: 1;
    width: 100%;
  `;

  let liftFillCss = css`
    background-color: #FFF;
    border-radius: 1rem;
    position: relative;
    z-index: 2;
    width: 102%;
    height: 90vh;
    margin-left: -1%;
    @media (max-width: ${Sizes.contentWidth}) {
      width: 100%;
      margin-left: auto;
    }
  `;

  let liftShadowCss = css`
    z-index:1;
    content: ' ';
    position: absolute;
    width: 104%;
    height: 90vh;
    left: -2%;
    top: -2px;
    background: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0));
    filter: blur(14px);
    @media (max-width: ${Sizes.contentWidth}) {
      width: 100%;
      left: 0;
    }
  `;

  return (
    <>
      <HeroFull backgroundImageUrl={heroImageUrl} more={false} height={heroHeight} fadeout={fadeHero}/>
      <div css={contentCss}>
        <div css={liftPanelCss} >
          <Section>
            <Row>
              <Column>
                <div style={{'position':'relative'}}>
                  <div css={liftFillCss}/>
                  <div css={liftShadowCss}/>
                </div>
              </Column>
            </Row>
          </Section>
        </div>
        {portal && renderPortal()}
        <div css={innerCss}>
          {breadcrumbs && <div css={breadcrumbCss}>{breadcrumbs}</div>}
          {children}
        </div>
      </div>
    </>
  );
};

export default LiftedContent;

