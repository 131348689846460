// @flow
import type { Node } from "react";
import React from "react";
import { Link } from "gatsby";
import { css } from '@emotion/react';

import * as Styles from "./footer.module.styl";
import { FaHeart, FaInstagram, FaDribbble, FaTwitter } from 'react-icons/fa';

import { Section, Row, Column } from "../../../utils/layout/grid";
import { useSiteMetadata } from '../../../utils/graphql/site_metadata';

type Props = {
  minimalFooter?: boolean
}

const Footer = ({minimalFooter}: Props ): Node => {

  let { lbAppHost } = useSiteMetadata();
  let links: any = {  };

  links.lightboard = [
    ["Pricing", "/pricing"],
    ["Portfolio", "/portfolio"],
    ["Reviews", "/reviews"],
    ["Tour", "/tour", { desktopOnly: true }]
  ];

  links.company = [
    ["About Us", "/about"],
    ["Contact", "/contact"],
    ["Careers", "/jobs"],
    ["Blog", "/blog/", {external: true}]
  ];

  links.help = [
    ["Our Case Studies", "/case-studies"],
    ["Marketing Teams", "/design-for/marketing-managers"],
    ["Design Teams", "/design-for/design-teams"]
  ];

  links.start = [
    ["Presentations", "/projects/presentations"],
    ["Branding", "/projects/branding", {external: true}],
    ["Websites", "/projects/websites"],
    ["Illustration", "/projects/illustration", {external: true}],
    ["Custom", "/projects/custom", {external: true}]
  ];

  function buildLink(title: any, path: any, opts: any = { external: false, desktopOnly: false}) {
    const styles = opts.desktopOnly ? css`@media(max-width: 900px) { display: none }` : undefined;
    return opts.external ? <a href={path} css={styles}>{title}</a> : <Link to={path} css={styles}>{title}</Link>;
  }

  type ColumnSet = [string, string, ?{external?: boolean, desktopOnly?: boolean}][];

  function renderColumn(set: ColumnSet , label: string) {
    return (
      <Column size={2} mobileSize={6} className={Styles.linkColumn}>
        <small>{label}</small>
        <ul>
          {set.map(([title, path, external]) => <li key={`${title}-${path}`}>
            {buildLink(title, path, external)}
          </li>)}
        </ul>
      </Column>
    );
  }

  return (
    <div className={Styles.footer}>
      <div className={Styles.body}>
        <Section>
          <Row>
            <Column size={3} mobileSize={12}>
              <div className={Styles.legal}>
                <a href={`${lbAppHost}/register`}> Register</a>{" "}|{" "}
                <a href={`${lbAppHost}/login`}> Sign In</a>
                <p className='headroom'>
                  Made with <FaHeart className={Styles.iconHeart}/> in <Link className='quiet' to="/seattle"> Seattle</Link>, WA.
                  <br/>2015-2024 Lightboard, Inc.
                </p>
                <small className='headroom'>
                  <Link className='quiet' to="/terms">Terms of Service</Link>{" "}|{" "}
                  <Link className='quiet' to="/privacy"> Privacy Policy</Link>
                </small>
              </div>
            </Column>
            {!minimalFooter && renderColumn(links.lightboard, "Lightboard")}
            {renderColumn(links.company, "Company")}
            {renderColumn(links.help, "How we help")}
            {renderColumn(links.start, "Start A Project")}

            <Column size={1} className={Styles.linkColumn}>
              <div className={Styles.footerIcons}>
                <a className='no-link-decoration' title="@lightboarding on Twitter" href="//twitter.com/lightboarding" rel="noopener noreferrer" target="_blank">
                  <FaTwitter/>
                </a>
                <a className='no-link-decoration' title="@lightboarding on Instagram" href="//instagram.com/lightboarding" rel="noopener noreferrer" target="_blank">
                  <FaInstagram/>
                </a>
                <a className='no-link-decoration' title="@lightboard on Dribbble" href="//dribbble.com/lightboard" rel="noopener noreferrer" target="_blank">
                  <FaDribbble/>
                </a>
              </div>
            </Column>
          </Row>
        </Section>
      </div>
      <div className={Styles.footerSeal} />

    </div>
  );
};

export default Footer;
