//@flow
import * as React from "react";
import styled from '@emotion/styled';

import { BleedImage, FluidImage } from '../../ui/images/fluid';
import type { PortfolioSet, PortfolioAsset } from "../../../utils/types";

const Wrapper = styled.div({
  transition: 'transform ease 0.5s',
  [":hover"] : {
    transform: 'scale(1.025)'
  }
});

const PortfolioAssetDisplay = ({set, asset, count, bleed, height}: { set: PortfolioSet, asset: PortfolioAsset, count?: number, bleed?: boolean, height?: string}): React.Node => {
  let side = (count && (count % 2) ===0) ? "right" : "left";
  if (bleed) {
    return (
      <Wrapper>
        <BleedImage src={asset.src} height={height} side={side}/>
      </Wrapper>);
  } else {
    return (
      <Wrapper>
        <FluidImage src={asset.src} />
      </Wrapper>);
  }
};

export default PortfolioAssetDisplay;
