//@flow
import * as React from "react";
import type { ProjectType } from '../../utils/types/project_type';
import { CommonExtended } from '../pages/projects/common/index';
import Breadcrumbs from '../ui/breadcrumbs/index';
import type { LiftedLayoutProps } from './layout-lifted';
import LayoutLifted from './layout-lifted';
interface Props extends LiftedLayoutProps {
  pageTitle?: string,
  projectType: ProjectType
}

const LayoutProjectTypes = (props:Props): React.Node => {
  let { children, pageTitle, projectType, ...rest } = props;

  let breadcrumbs = (
    <Breadcrumbs base={{url:'/projects', title:'Services'}}>
      {projectType.title}
    </Breadcrumbs>
  );

  return (
    <LayoutLifted
      pageTitle={pageTitle || projectType.title}
      pageDescription={projectType.shortDescription}
      breadcrumbs={breadcrumbs}
      liftedHeight='-80vh'
      solidMenu
      fadeHero
      {...rest} >
      {children}
      <CommonExtended projectType={projectType}/>
    </LayoutLifted>
  );
};

export default LayoutProjectTypes;

