//@flow
import React from "react";
import type { Node } from "react";

import { Link } from 'gatsby';
import { Column, Row, Section } from "../../utils/layout/grid";
import { ImageCaption, LogoImage, PullQuote} from "../../components/ui";
import { useCaseStudiesIndex } from '../../utils/graphql/case_studies';
import LayoutCaseStudy from '../../components/layouts/layout-case-study';
import CompareImage from '../../components/ui/images/compare-image';

const Usermind =  (): Node => {

  let caseStudy = useCaseStudiesIndex('usermind');

  return (
    <LayoutCaseStudy caseStudy={caseStudy} heroUrl="case-studies/usermind/hero.png">
      <Section>
        <Row>
          <Column readable>
            <h1>A Quick and Efficient Brand Refresh</h1>
            <p>Usermind turned to Lightboard to refresh their brand identity to better reflect how their customer journey insights platform
              was innovating industry enterprise sales. Lightboard's team of designers quickly and cost-effectively refreshed Usermind's
              brand in three months, using our <Link to="/memberships">Membership</Link> program.</p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <PullQuote src="case-studies/usermind/david-henry.jpg" name='David Henry' title='Senior Product Marketing Manager'>
                <p>
                We work very closely with the Lightboard team to go from idea to concept to design for our brand refresh. Usermind
                is a complex SaaS product that can be hard to explain and the Lightboard team does a great job at taking a fuzzy
                concept and turning it into something much more meaningful.
                </p>
            </PullQuote>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <LogoImage src="logos/usermind.png" width={300}/>
            <p>Founded in 2013 in Seattle, Usermind provides the first unified data infrastructure platform for understanding and orchestrating the customer journey.
              Built for non-developers, Usermind makes it simple to integrate enterprise applications, map data between them,
              automate end-to-end processes, measure their impact, and take instant action to improve business outcomes.</p>
            <h2 className='headroom'>Business Situation</h2>
            <p>After raising a <a href="https://venturebeat.com/2018/01/17/usermind-raises-23-5-million-for-companies-to-gain-insights-through-connecting-enterprise-applications/">$23.5M Series C</a>, the Usermind team wanted to refresh their brand to reflect their premier status as a business operations platform. Focusing on design that would support their growing sales and marketing teams, Lightboard completed Usermind's rebrand with a brisk <strong>3-month sprint</strong>.</p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/usermind/overview.png'/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>Discovery and Design Exploration</h2>
            <p>The first part of our branding process is discovery: identify the customer's goals, understand the customer's market, and propose options to move forward. To start this project, Lightboard conducted extensive research into Usermind's competitors to identify trends and messaging that would inform Usermind's visual positioning.</p>
            <p>We presented Usermind with several different branding directions for discussion with variations focusing on modern, bold, bright, and dynamic visual identity styles.</p>
            <p>As design partners, Usermind and Lightboard began to hone the key features that redefined their brand's character.</p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid >
          <Column>
            <ImageCaption src='case-studies/usermind/identity-approaches.png'>
              Rebranding exploration, finding Usermind's unique brand positioning.
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/usermind/identity-brandbook.png'/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>Design Refinement</h2>
            <p>Client feedback and iterative design exploration shaped the overall look of Usermind's new visual identity. Once our teams arrived at a
              look and feel both sides were excited about, various marketing materials were used to further refine additional brand touch points.</p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <ImageCaption size={12} src='case-studies/usermind/cfd.png'>
            Rebranding Usermind's marketing and sales collateral, like presentations.
          </ImageCaption>
        </Row>
        <Row>
          <Column readable>
            <h2>Execution</h2>
            <p>Usermind's refined visual identity makes use of dynamic images, crisp typography, an energetic color palette,
              and a mature illustration style. Lightboard applied this design direction across Usermind's entire brand,
              updating the company's website, presentations, case studies, PDFs, iconography set, and more.</p>
          </Column>
        </Row>
        <Row>
          <Column>
            <ImageCaption src='case-studies/usermind/icon-site-highlight.png'>
              Brand elements, like iconography, reinforced as a website hero image.
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>Engaging Elements</h2>
            <p>Icons play a key role strengthening Usermind's brand. These eye-catching visual cues
              help reinforce the integral aspects of Usermind's customer experience platform, while visually
              communicating customer journey ideas quickly and easily.</p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column>
            <ImageCaption src='case-studies/usermind/iconset.png'>
              Custom iconography in Usermind's bold primary and secondary brand colors.
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>A Visual Language</h2>
            <p>By creating engaging, unambiguous, and flexible icons in Usermind's vibrant
              primary and secondary colors, these simplified visuals help communicate how Usermind
              is able to reveal useful business insights through a unified customer experience journey.</p>
            <p>These icons are easily adaptable to work in other contexts, like hero image scenes, to
              further connect with audiences and create a compelling visual story.</p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid >
          <Column>
            <ImageCaption src='case-studies/usermind/site-pages.png'/>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column readable>
            <h2>A Website Experience Reimagined</h2>
            <p>The most significant undertaking of Usermind's rebrand was reenvisioning their website.
              The process involved intensive information re-architecture. Through multiple wireframe
              and page design mock-ups, we were able to determine a revised page-layout and content strategy to reflect
              Usermind's new brand. The new website features a simple and user-friendly page structure that allows feature
              content and images to shine.</p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid>
          <Column>
            <CompareImage fluid left="case-studies/usermind/before-after-a.png" right="case-studies/usermind/before-after-b.png"/>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column readable>
            <h2>Benefits</h2>
            <h4>Speed & Quality</h4>
            <p>Usermind wanted to inject new energy into their existing brand to better reflect its positioning as a leading customer engagement platform.
              Lightboard was a reliable and cost effective design partner that enabled Usermind to refresh their brand from start to finish.</p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <PullQuote src="case-studies/usermind/david-henry.jpg" name='David Henry' title='Senior Product Marketing Manager'>
                <p>
                We consider Lightboard to truly be an extension of our own team. I've worked with a number of different agencies and designers
                in the past and I have never had the efficient, personalized experience that we get working with Devin and Meagan. Their service,
                combined with the streamlined Lightboard app, makes it very easy to collaborate and deliver on projects.
                </p>
            </PullQuote>
          </Column>
        </Row>
      </Section>
    </LayoutCaseStudy>
  );
};

export default Usermind;
