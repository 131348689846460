//@flow
import type { Node } from "react";
import React from "react";
import { Section, Row, Column, Readable } from '../../../utils/layout/grid';
import CompareImage from '../../ui/images/compare-image';
import { Link } from 'gatsby';

const BeforeAfter = ():Node => {
  return (
    <>
      <Section padded>
        <Row>
          <Column textCenter readable>
            <h2>See the difference design makes.</h2>
            <p>Great design begins with a conversation. Once we understand what you'd like to see, we can take any raw material–a Word document, an outline or just a thumbnail sketch–and turn it into a finished product.</p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column textCenter>
            <Readable>
              <h3>Spice up a Sales Deck</h3>
              <p>Turn an everyday slide into something remarkable.</p>
            </Readable>
            <div className='spaced'>
              <CompareImage fluid left="before-after/fpsb-before.png" right="before-after/fpsb-after.png"/>
            </div>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column textCenter>
            <Readable>
              <h3>Transform an Idea into a Custom Illustration</h3>
              <p>Illustration that sets your brand apart.</p>
            </Readable>
            <div className='spaced'>
              <CompareImage fluid left="before-after/nova-before.png" right="before-after/nova-after.png"/>
            </div>
          </Column>
        </Row>
        <Row>
          <Column textCenter>
            <p className='headroom'>More about <Link to="/case-studies/nova">this project for Nova Credit</Link>.</p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable textCenter>
            <div className='spaced'>
              <h3>A Brand Makeover</h3>
              <p>Refresh your entire brand–from logo to website.</p>
            </div>
          </Column>
        </Row>
        <Row>
          <Column size={6}>
            <h4>Before</h4>
          </Column>
          <Column size={6}>
            <h4>After</h4>
          </Column>
        </Row>
        <Row>
          <Column>
            <CompareImage fluid left="before-after/usermind-presentation-before.png" right="before-after/usermind-presentation-after.png"/>
          </Column>
        </Row>
        <Row>
          <Column textCenter>
            <p className='headroom'>More about <Link to="/case-studies/usermind">this project for Usermind</Link>.</p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column size={6}>
            <h4>Before</h4>
          </Column>
          <Column size={6}>
            <h4>After</h4>
          </Column>
        </Row>
        <Row>
          <Column>
            <CompareImage fluid left="case-studies/areametrics/overview-a.png" right="case-studies/areametrics/overview-b.png"/>
          </Column>
        </Row>
        <Row>
          <Column textCenter>
            <p className='headroom'>See more about <Link to="/case-studies/areametrics">this project for AreaMetrics</Link></p>
          </Column>
        </Row>
      </Section>
    </>
  );
};

export default BeforeAfter;
