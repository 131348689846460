// @flow
import * as React from 'react';
const { Component } = React;

let stack: Function[] = [];

function addToStack(handler: Function) {
  if (stack.length === 0) {
    window.addEventListener('keydown', globalKeyHandler);
  }

  stack = stack.filter(f => f !== handler).concat([handler]);
}

function removeFromStack(handler: Function) {
  stack = stack.filter(f => f !== handler);

  if (stack.length === 0) {
    window.removeEventListener('keydown', globalKeyHandler);
  }
}

function globalKeyHandler(e: KeyboardEvent) {
  const handler = stack[stack.length - 1];
  if (handler) {
    handler(e);
  }
}

type Props = {
  onLeft?: Function,
  onRight?: Function,
  onEsc?: Function,
  onEnter?: Function,
  onDown?: Function,
  onUp?: Function,
  onTab?: Function,
  onAnyKey?: Function,
}

export default class KeyboardNav extends Component<Props> {
  props: Props;

  componentDidMount() {
    addToStack(this.onKey);
  }

  componentWillUnmount() {
    removeFromStack(this.onKey);
  }

  getHandler(keyCode: number): ?Function {
    if (keyCode === 9) {
      return this.props.onTab ;
    }
    if (keyCode === 13) {
      return this.props.onEnter;
    }
    if (keyCode === 27) {
      return this.props.onEsc;
    }
    if (keyCode === 37) {
      return this.props.onLeft;
    }
    if (keyCode === 38) {
      return this.props.onUp;
    }
    if (keyCode === 39) {
      return this.props.onRight;
    }
    if (keyCode === 40) {
      return this.props.onDown;
    }
  }

  onKey: ((e: KeyboardEvent) => void) = (e: KeyboardEvent) => {
    const name = ((document.activeElement || {}).nodeName || '').toLowerCase();

    if ((name === 'textarea' || name === 'input') && e.keyCode !== 27 && e.keyCode !== 9) return;
    const handler = this.getHandler(e.keyCode);
    if (!handler) {
      if (this.props.onAnyKey) this.props.onAnyKey(e);
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    handler(e);
  };

  render(): any {
    return null;
  }
}

