//@flow
import { useEffect } from "react";
import * as React from "react";
import ConfettiGenerator from 'confetti-js';

const WrappedConfetti = ({canvasId='confetti-canvas'}: {canvasId?: string}): React.Node => {

  useEffect(() => {
    console.log('canvasId', canvasId);
    const confettiSettings = {
      "target": canvasId,
      "max": "36",
      "size": "6",
      "animate": true,
      "props": [
        { "type": "svg", "src": "/images/confetti/curly.svg" },
        { "type": "svg", "src": "/images/confetti/lightning.svg" },
        { "type": "svg", "src": "/images/confetti/eye.svg" },
        { "type": "svg", "src": "/images/confetti/circle.svg" }
      ],
      "colors": [[255,255,255]],
      "clock": "4",
      "rotate": true
    };
    const confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();
    return () => confetti.clear();
  });

  return (
    <div style={{'position':'absolute', 'width':'100%', 'height': '100%', 'zIndex':'1'}} >
      <canvas id={canvasId}/>
    </div>
  );
};

export default WrappedConfetti;

