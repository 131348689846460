//@flow
import React from "react";
import type { Node } from "react";


import { Column, Readable, Row, Section } from "../../utils/layout/grid";
import { PullQuote, ImageCaption, LogoImage } from "../../components/ui";

import { useCaseStudiesIndex } from '../../utils/graphql/case_studies';
import LayoutCaseStudy from '../../components/layouts/layout-case-study';

const OneEnergy = (): Node => {

  let caseStudy = useCaseStudiesIndex('one-energy-renewables');

  return (
    <LayoutCaseStudy caseStudy={caseStudy} heroUrl='case-studies/one-energy-renewables/hero.png'>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h1>Complete Rebranding</h1>
              <p>
                Jen Barnes, Vice President of Marketing at OneEnergy Renewables, turned to Lightboard for a complete branding package. After winning the project from far more established agencies, Lightboard's team of designers quickly and cost-effectively built a new brand package and website for OneEnergy.
              </p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <PullQuote src="case-studies/one-energy-renewables/jen-barnes.png" name='Jen Barnes' title='Vice President, Marketing'>
                <p>Truly, thank you. It's a difficult challenge to deliver a brand refresh and this was a huge, huge win!</p>
              </PullQuote>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <LogoImage src="logos/one-energy-renewables.png" alt="OneEnergy Renewables logo" className="headroom" width={300} />
              <p>OneEnergy Renewables, founded in 2009, is a privately held company actively developing a significant pipeline of utility scale solar projects nationwide.</p>
              <p>As a certified B-corp, OneEnergy enthusiastically embraces and meets rigorous standards of business, social, and environmental performance, accountability, and transparency.</p>
              <h2>Business Situation</h2>
              <p>In 2016, OneEnergy had an aging brand in need of a refresh. With a limited budget and no time to spare, Jen turned to Lightboard for high-quality, cost-effective design she needed to develop and execute a complete rebrand for OneEnergy Renewables.</p>
              <h2>Branding</h2>
              <p>Because this was a full rebranding package and not just a logo, we knew we needed to proceed thoughtfully and carefully. Aside from the vibrant orange they were currently using, every design element of the brand was open for discussion.</p>
              <p>After several meetings with Jen to understand the goals and aesthetic, we settled on a clean, mid-century modern tone for the brand.</p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <ImageCaption src="case-studies/one-energy-renewables/logo-inspiration.jpg" imageTitle="Logo Inspiration">
              One of the mood boards in the rebranding process
            </ImageCaption>
            <ImageCaption src="case-studies/one-energy-renewables/logo-options.png" imageTitle="Logo Options">
              First round of logo explorations
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section inset>
        <Row fluid  className='padded-y'>
          <Column size={6}>
            <ImageCaption src="case-studies/one-energy-renewables/logo-finalists.png"/>
          </Column>
          <Column size={6}>
            <ImageCaption src="case-studies/one-energy-renewables/logo-finalists.png"/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <p className="headroom">As we narrowed the choices, the differences became more and more subtle.</p>
              <p>But one option stood out, and the choice was unanimous.</p>
            </Readable>
            <ImageCaption src="case-studies/one-energy-renewables/logo-final.jpg">
              Final Logo
            </ImageCaption>
            <Readable>
              <h2>Website Design</h2>
              <p>With the logo finalized, we could move on to designing the rest of the package. We built out the brand guidelines and moved on to the next highest priority: redesigning the website.</p>
              <p>The website would be clean and simple&mdash;but in simplicity lay a challenge. How to ensure that the minimal, modern lines are properly carried over to the mobile view? How will Jen and her team update the content?</p>
              <p>We embarked on building a 100-page WordPress website, fully responsive and customizable, with a custom-designed theme we built from scratch. By the end of the project, four different engineers and two designers worked on pieces of the site, producing a solid final product.</p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <ImageCaption src="case-studies/one-energy-renewables/website.png" imageTitle="OneEnergy Website">
              One Energy Website
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h2>Marketing Collateral</h2>
              <p>After the website, we completed the brand with collateral&mdash;envelopes, packaging, and sales templates. With the brand guidelines locked into our system, we were able to quickly knock out the case study, sales sheets, and PowerPoint templates Jen needed to arm her sales team with great design.</p>
            </Readable>
            <ImageCaption src="case-studies/one-energy-renewables/collateral.png" imageTitle="OneEnergy Presentation Templates">
              OneEnergy Collateral
            </ImageCaption>

            <ImageCaption src="case-studies/one-energy-renewables/presentation-1.png" imageTitle="OneEnergy Presentation Templates">
              OneEnergy Presentation Templates
            </ImageCaption>
            <ImageCaption src="case-studies/one-energy-renewables/presentation-2.png" imageTitle="OneEnergy Presentation Templates">
              OneEnergy Presentation Templates
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h2>A full package</h2>
              <p>A complete redesign is never an easy task, but Lightboard's team of designers&mdash;always able to find the right person for the job&mdash;and collaboration software made for smooth sailing on a massive project.</p>
              <PullQuote src="case-studies/one-energy-renewables/jen-barnes.png" name='Jen Barnes' title='Vice President, Marketing'>
                <p>Because you nailed the design and worked so hard on the presentation to get it right with us, it was cake. Wish you could have been a fly on the wall!</p>
              </PullQuote>
            </Readable>
          </Column>
        </Row>
      </Section>
    </LayoutCaseStudy>
  );
};

export default OneEnergy;

