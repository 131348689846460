//@flow
import * as React from "react";
import { Section, Row, Column } from '../../../utils/layout/grid';
import { Link } from 'gatsby';
import { GiantButton } from '../../forms/buttons/index';
import { css } from '@emotion/react';
import { FaLongArrowAltRight } from 'react-icons/fa';
import type { PortfolioSet, PortfolioAsset } from "../../../utils/types";
import PortfolioAssetDisplay from './portfolio-asset-display';
import PortfolioAssetDetails from './portfolio-asset-details';

import {
  BrandingPreview,
  DisplayAdsPreview,
  IllustrationsPreview,
  PresentationPreview,
  PrintPreview,
  WebPreview,
  BrandingHeader,
  DisplayAdsHeader,
  IllustrationsHeader,
  PresentationHeader,
  PrintHeader,
  WebHeader
} from './portfolio-headers';
import PortfolioMenu from "./portfolio-menu";

const headers = {
  'branding': <BrandingHeader/>,
  'display-ads': <DisplayAdsHeader/>,
  'illustrations': <IllustrationsHeader/>,
  'presentations': <PresentationHeader/>,
  'print': <PrintHeader/>,
  'web': <WebHeader/>
};

const previews = {
  'branding': <BrandingPreview/>,
  'display-ads': <DisplayAdsPreview/>,
  'illustrations': <IllustrationsPreview/>,
  'presentations': <PresentationPreview/>,
  'print': <PrintPreview/>,
  'web': <WebPreview/>
};


const PortfolioGallery = (
  {set, bleed, featured, height, limit, columnSize=6, standalone=false, location}:
  { set: PortfolioSet,
    bleed?: boolean,
    featured?: boolean,
    height?: string,
    limit?: number,
    columnSize?: number,
    standalone?: boolean,
    location?: Object}): React.Node => {

  let hero, imageSet;

  if (featured) {
    imageSet = set.images.filter(asset => asset.featured);
  } else {
    imageSet = set.images;
  }

  function displayImages(images: Array<PortfolioAsset>, set:PortfolioSet) {
    return images.map((asset: PortfolioAsset, count: number) => {
      if (count === limit) {
        return (
          <Column key={set.key + "-final"} size={columnSize} textCenter>
            <div className='padded-x'>
              <Link to={`/portfolio/${set.key}`}>
                <GiantButton css={css`max-width:350px;margin:6rem auto;`}>All {set.title}<FaLongArrowAltRight css={css`height:2rem; width:2rem;`}/></GiantButton>
              </Link>
            </div>
          </Column>
        );
      }

      if (limit && (count > limit)) return;
      return (<Column size={asset.size || columnSize} key={set.key + "-" + asset.id}>
        <Link to={asset.url}>
          <PortfolioAssetDisplay set={set} asset={asset} count={count} bleed={bleed} height={height} />
        </Link>
        <PortfolioAssetDetails src={asset}/>
      </Column>);
    });
  }

  if (standalone) {
    return (
      <>
        <Section gutters balancedGutters>
          <Row vcenter>
            <Column textCenter>
              {headers[set.key]}
            </Column>
          </Row>
        </Section>
        <Section>
          <Row>
            <Column textCenter>
              <PortfolioMenu location={location}/>
            </Column>
          </Row>
        </Section>
        <Section spaced>
          <Row gutters>
            {displayImages(imageSet, set)}
          </Row>
        </Section>
      </>

    );
  } else {
    return (
      <>
        <Section gutters balancedGutters>
          <Row vcenter>
            <Column size={columnSize}>
              {previews[set.key]}
            </Column>
            {displayImages(imageSet, set)}
          </Row>
        </Section>
      </>
    );
  }
};

export default PortfolioGallery;
