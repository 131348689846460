//@flow
import * as React from "react";
import { Column, Row, Section } from "../../../utils/layout/grid";
import PortfolioGallery  from './portfolio-gallery';
import { FluidImage } from '../../ui/images/fluid';
import LayoutBasic from '../../layouts/layout-basic';
import { Link } from 'gatsby';

import KeyboardNav from '../../../utils/layout/keyboard_nav';
import { navigate } from 'gatsby';

import * as styles from './portfolio-detail.module.styl';
import { Helmet } from 'react-helmet';
import { MdChevronLeft, MdChevronRight, MdClose } from 'react-icons/md';
import { getPortfolioImage, getPortfolioSet } from "../../../utils/graphql/portfolio";
import type { PortfolioAsset, PortfolioSet } from "../../../utils/types";

const PortfolioDetail = ({pageContext}: { pageContext: Object}): React.Node => {
  let { set, image, next, previous } = pageContext;

  const portfolioSet: PortfolioSet = getPortfolioSet(set.key);
  const asset: PortfolioAsset = getPortfolioImage(set.key, image.id);

  let pathRoot = `portfolio/${portfolioSet.key}`;

  function imagePath(image: {id: string}) {
    if (!image) return pathRoot;
    return `${pathRoot}/${image.id}`;
  }

  let nextPath     = imagePath(next);
  let previousPath = imagePath(previous);
  let currentPath  = imagePath(image);

  let title = `${portfolioSet.title} Portfolio: ${asset.title || currentPath}`;
  let description = `Example of ${portfolioSet.title} design from Lightboard. ${asset.title || ''} ${asset.description || ''} (${currentPath})`;

  let doNext = navigate.bind(null,`/${nextPath}`);
  let doPrevious = navigate.bind(null, `/${previousPath}`);
  let doClose = navigate.bind(null, `/${pathRoot}`);

  return (
    <LayoutBasic pageTitle={title} pageDescription={description} onViewEvent="portfolio-item" solidMenu>
      <Helmet>
          <style  type="text/css">{`
            html {
              overflow-y: hidden;
              overflow-x: hidden;
            }
          `}</style>
      </Helmet>
      <KeyboardNav onLeft={doPrevious} onRight={doNext} onEsc={doClose}/>
      <div className={styles.portfolioNav}>
        <div className={styles.navBack} onClick={doPrevious} ><MdChevronLeft/></div>
        <div className={styles.navForward} onClick={doNext}><MdChevronRight/></div>
        <div className={styles.navClose} onClick={doClose}><MdClose/></div>
      </div>
      <div className={styles.portfolioDetail} >
        <Link to={`/${imagePath(next)}`}>
          <FluidImage src={`${currentPath}.${asset.extension || portfolioSet.extension}`} className={styles.portfolioImage} imgStyle={{'objectFit':'contain'}} />
        </Link>
      </div>
      <div className={styles.portfolioScreen} onClick={doClose}/>
      <Section>
        <Row fluid>
          <Column>
            <PortfolioGallery set={portfolioSet} />
          </Column>
        </Row>
      </Section>
    </LayoutBasic>
  );
};

export default PortfolioDetail;
