//@flow
import * as React from "react";
import SplitFlapDisplay from './split-flap';

type State = {
  count: number
};

type Props = {
  goal: number,
  increment?: number,
  startPercent?: number
}

class CounterText extends React.Component< Props, State> {
  state: State = { 'count': 0 };
  intervalId: any;

  componentDidMount() {
    let { goal } = this.props;
    this.setState({count:Math.floor((goal*0.8))});
    this.intervalId = setInterval(() => {
      this.randomize();
    }, 1200);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  randomize: (() => void) = () => {
    let { count } = this.state;
    let addAmount = (count > this.props.goal) ? 200 : 500;
    let add = Math.floor(Math.random()*addAmount);
    this.setState({count:(count+add)});
  };

  render(): any {
    let { count } = this.state;
    return (
      <SplitFlapDisplay
        background='#003e84'
        borderColor='#1e7ee9'
        borderWidth='1px'
        characterSet={SplitFlapDisplay.NUMERIC}
        characterWidth='3.5rem'
        fontSize='5em'
        step={100}
        textColor='#fff'
        value={count.toString()}
      />
    );
  }
}

export default CounterText;
