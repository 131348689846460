// @flow
import * as React from 'react';
import GridContext from '../context';
import styled from '@emotion/styled';
import sectionStyles from './styles';
import type { OffsetValue } from '../../types';
import pick from '../../utils/pick';

type SectionProps = {
  balancedGutters?: boolean,
  children?: React.Node,
  css?: any,
  style?: any,
  className?: string,
  textCenter?: boolean,
  gutters?: boolean,
  spaced?: OffsetValue,
  padded?: OffsetValue,
  inset?: boolean
};

const StyledSection = styled.section(sectionStyles);

const getContext = pick.bind(null, ['balancedGutters', 'gutters', 'textCenter', 'vcenter']);

export const Section = (props: SectionProps): React.MixedElement => (
  <GridContext.Consumer>
    {context => (
      <GridContext.Provider value={{ ...context, ...getContext(props) }}>
        <StyledSection {...props} />
      </GridContext.Provider>
    )}
  </GridContext.Consumer>
);
