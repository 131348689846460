// @flow
import * as React from 'react';
import fetch from './fetch';
import { graphql, StaticQuery } from "gatsby";

const query = graphql`
query {
  site {
    siteMetadata {
      lbAppHost
    }
  }
}
`;

const apiFetch = (lbAppHost: string, url: string, opts: any): any => {
  if (!lbAppHost) {
    return Promise.resolve({});
  }
  opts = Object.assign({ credentials: 'include'}, opts || {});

  return fetch(`${lbAppHost}/api${url}`, opts).then(result => (result && result.json && result.json()) || {}, () => ({}));
};

export type ApiFetch = (path: string, opts?: Object) => Promise<Object>;

type InjectedProps = {
  apiFetch: ApiFetch
}

export default function withApiFetch<
 PassedProps,
 InnerType: React.ComponentType<PassedProps>
> (
  Component: InnerType
): React.ComponentType<any> {
  return (props: any) => (
    <StaticQuery
       query={query}
       render={data => <Component apiFetch={apiFetch.bind(null, data.site.siteMetadata.lbAppHost)} {...props} />}
     />
  );
}
