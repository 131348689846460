//@flow
import * as React from "react";
import Layout from '../components/layouts/layout-basic';
import { Column, Row, Section } from "../utils/layout/grid";
import { useProjectTypes } from '../utils/graphql/project_types';
import { Panel } from '../components/containers/panel';
import { PrimaryButton } from '../components/forms/buttons/index';
import { FluidImage } from '../components/ui/images/fluid';
import PortfolioCallout from '../components/pages/shared/portfolio-callout';
import HowItWorks from '../components/pages/shared/how-it-works';
import { PrimaryCTA } from '../components/pages/projects/common/index';
import LogosAndQuotes from '../components/pages/shared/logos-and-quotes';

const StartPage = ():React.Node => {

  let projectTypes = useProjectTypes();

  return (
    <Layout pageTitle="What we can do: Lightboard Project Types" pageDescription="See the types of projects Lightboard can help you design." solidMenu>
      <Section gutters>
        <Row>
          <Column textCenter>
            <div className='spaced-2x'>
              <h1>Let's start a project.</h1>
              <p>At Lightboard we focus on B2B marketing design, and we help our customers with anything from branding to websites.</p>
            </div>
          </Column>
        </Row>
        <Row balancedGutters gutters vcenter>
         {projectTypes.map(projectType =>
            <Column size={3} key={projectType.slug}>
              <a href={projectType.slug}>
                <Panel height={320}>
                  <FluidImage src={`project-icons/${projectType.icon}.png`} width={200} style={{'margin':'auto'}}/>
                  <h4>{projectType.title}</h4>
                  <p>{projectType.shortDescription}</p>
                  <div className='spaced-half'>
                    <PrimaryButton>Details</PrimaryButton>
                  </div>
                </Panel>
              </a>
            </Column>)}
        </Row>
      </Section>
      <LogosAndQuotes/>
      <PortfolioCallout/>
      <HowItWorks/>
      <PrimaryCTA/>
    </Layout>
  );
};

export default StartPage;

