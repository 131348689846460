//@flow
import * as React from "react";

import LayoutProjectTypes from '../../../layouts/layout-project-types';
import { Section, Row, Column } from '../../../../utils/layout/grid';
import EmailForm from '../../../forms/email/index';
import { Link } from 'gatsby';
import { useSiteMetadata } from '../../../../utils/graphql/site_metadata';
import { ExtrudedText, FluidImage } from '../../../ui';
import colors from '../../../../utils/config/colors.json';
import PortfolioCallout from '../../shared/portfolio-callout';
import CompareImage from "../../../ui/images/compare-image";

const PresentationsProject = ({pageContext}: {pageContext:Object}): React.Node => {

  let { projectType } = pageContext;
  let { lbAppHost } = useSiteMetadata();

  let projectCreateLink = `${lbAppHost}/projects/${projectType.appLink}/create`;

  return (
    <LayoutProjectTypes pageTitle="Professional presentation design Lightboard" projectType={projectType} heroUrl="case-studies/usermind/hero.png">
      <Section>
        <Row>
          <Column readable textCenter>
            <FluidImage src="project-types/presentations/presentations-hero.png"/>
            <h1>Add some polish to your next presentation.</h1>
            <p>You never get a second chance to make a first impression. Look your best with a professionally designed presentation.</p>
            <p> PowerPoint, Keynote, Google Slides–no problem.</p>
            <p>Sales decks, demo day presentations, investor decks–whatever you need, we'll take care of you.</p>
            <p>We provide same day estimates, and can start work within a business day. We've designed thousands of decks for customers large and small, and we're ready to work with you.</p>
            <div className='spaced'>
              <EmailForm buttonLabel="Request Estimate" formLocation="project-type:presentations=hero"/>
            </div>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column readable>
            <div className='text-center'>
              <ExtrudedText fontSize={6} extrudeSize={8} headlineText="How it works" color="#FFF" extrudeColor={colors.primaryHero} />
            </div>
            <p>Lightboard is an online agency based in Seattle, Washington. We work as an extension of your marketing team, and bill by the hour or at a discounted monthly rate (more about <Link to='/pricing'>pricing</Link>).</p>

            <p>We work with B2B teams at companies like Autodesk, Bloomreach, and Trello, and specialize in marketing design (see our <Link to="/case-studies">case studies</Link> and <Link to="/portfolio">portfolio</Link>).</p>

            <p>All projects are run through our project management software by one of our experienced Creative Services Managers. We've completed thousands of projects, and we make sure your project is delivered on-time, on-budget, and looking great by our team of designers and developers.</p>

            <p>If you already have a brief, you can <a href={projectCreateLink}>submit a request</a> and we'll be in touch shortly. We usually can provide an estimate same business day.</p>

            <p>We're also happy to <Link to='/request-demo'>give you a demo</Link> or just explain how we work.</p>

            <p><Link to='/schedule'>Schedule a time with us</Link>.</p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid>
          <Column size={12}>
            <Link to='/case-studies/usermind'>
              <FluidImage src="case-studies/usermind/cfd.png" />
            </Link>
          </Column>
        </Row>
      </Section>
      <Section spaced>
        <Row>
          <Column readable textCenter>
            <p>See examples of our work in <Link to="/portfolio/presentations">our portfolio</Link> or read how we helped <Link to='/case-studies/usermind'>Usermind redesign their collateral</Link> or <Link to="/case-studies/share-this">ShareThis polish their sales decks</Link>.</p>
          </Column>
        </Row>
      </Section>
      <Section>
          <Row>
            <Column readable>
              <h2>How it works</h2>
              <ul className='bullet-list'>
                <li><a href={projectCreateLink}>Create a request</a>. Describe what you need and upload your current presentation. If you're not ready yet, you can <Link to="/request-demo">request a demo</Link>.</li>
                <li>Your account manager will follow up with any questions and work with you to find a pricing option that fits your budget.</li>
                <li>Our designers will start right away. You'll review the first round in as little as two business days.</li>
                <li>Collaborate with your designer and account manager until the deck is perfect.</li>
              </ul>
              <h2>The details</h2>
              <ul className='bullet-list'>
                <li>Any type of presentation: sales, capabilities, investor pitch, conference, reports.</li>
                <li>Optional master slides/themes to be the building blocks for future use.</li>
                <li>Keynote, PowerPoint, InDesign or PDF.</li>
                <li>Iterate back and forth until your design is perfect.</li>
                <li>Designated designer and account manager.</li>
                <li>Editable files delivered to you.</li>
              </ul>
              <div className='spaced'>
                <EmailForm buttonLabel="Request Estimate" formLocation="project-type:presentations=details"/>
              </div>
            </Column>
          </Row>
      </Section>
      <Section padded>
        <Row>
          <Column readable textCenter>
            <h3>See the difference design makes.</h3>
            <p>Design can turn an everyday slide into something remarkable. Give your next presentation the polish it deserves with custom design, or have us create a template for your entire team to use. Read how we helped <Link to="case-studies/usermind">Usermind redesign all of their collateral</Link>, and grab a few ideas from our <Link to="/portfolio">portfolio</Link>.</p>
          </Column>
        </Row>
        <Row>
          <Column>
            <div className='spaced'>
              <CompareImage fluid left="before-after/fpsb-before.png" right="before-after/fpsb-after.png"/>
            </div>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <PortfolioCallout/>
      </Section>
    </LayoutProjectTypes>);
};

export default PresentationsProject;
