//@flow
import type { Node } from "react";
import React from "react";
import { Section, Row, Column, Spread, SpreadPanel} from "../../../utils/layout/grid";
import { LogoImage } from '../../ui';
import CaseStudyCarousel from './case-study-carousel';

const LogosAndQuotes = (): Node => {
  return (
    <Section padded spaced inset>
      <Row>
        <Column readable textCenter>
          <h2>Join the great companies switching to Lightboard.</h2>
          <p>We start design within a business day, and are finished before your agency would have even started. We've
          completed thousands of projects for companies large and small, and we're ready to work with you.</p>
        </Column>
      </Row>
      <Spread vcenter>
        <SpreadPanel>
          <LogoImage src='logos/client-logos-a.png' alt="Lightboard Client Logos" />
        </SpreadPanel>
        <SpreadPanel>
          <LogoImage src='logos/client-logos-b.png' alt="Lightboard Client Logos" />
        </SpreadPanel>
      </Spread>
      <Row>
        <Column readable className='spaced-2x'>
          <CaseStudyCarousel />
        </Column>
      </Row>
    </Section>);
};

export default LogosAndQuotes;
