//@flow
import * as React from "react";

import LayoutProjectTypes from '../../../layouts/layout-project-types';
import { Section, Row, Column } from '../../../../utils/layout/grid';
import EmailForm from '../../../forms/email/index';
import { Link } from 'gatsby';
import { useSiteMetadata } from '../../../../utils/graphql/site_metadata';
import { ExtrudedText, BleedImage, FluidImage } from '../../../ui';
import colors from '../../../../utils/config/colors.json';

const WebsiteProject = ({pageContext}: {pageContext:Object}): React.Node => {

  let { projectType } = pageContext;
  let { lbAppHost } = useSiteMetadata();

  let projectCreateLink = `${lbAppHost}/projects/${projectType.appLink}/create`;

  return (
    <LayoutProjectTypes pageTitle="Design and build a new website with Lightboard" projectType={projectType} heroUrl="case-studies/usermind/hero.png">
      <Section>
        <Row>
          <Column readable textCenter>
            <FluidImage src="project-types/website/website-hero.png"/>
            <h1>A new look for your home on the web.</h1>
            <p>Whether you need a complete website or just a landing page, we can help you design, develop, and launch it.</p>
            <div className='spaced'>
              <EmailForm buttonLabel="Request Estimate" formLocation="project-type:website=hero"/>
            </div>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column readable>
            <div className='text-center'>
              <ExtrudedText fontSize={6} extrudeSize={8} headlineText="How it works" color="#FFF" extrudeColor={colors.primaryHero} />
            </div>
            <p>Lightboard is an online agency based in Seattle, Washington. We work as an extension of your marketing team, and bill by the hour or at a discounted monthly rate (more about <Link to='/pricing'>pricing</Link>).</p>

            <p>We work with B2B teams at companies like Autodesk, Bloomreach, and Trello, and specialize in marketing design (see our <Link to="/case-studies">case studies</Link> and <Link to="/portfolio">portfolio</Link>).</p>

            <p>All projects are run through our project management software by one of our experienced Creative Services Managers. We've completed thousands of projects, and we make sure your project is delivered on-time, on-budget, and looking great by our team of designers and developers.</p>

            <p>If you already have a brief, you can <a href={projectCreateLink}>submit a request</a> and we'll be in touch shortly. We usually can provide an estimate same business day.</p>

            <p>We're also happy to <Link to='/request-demo'>give you a demo</Link> or just explain how we work.</p>

            <p><Link to='/schedule'>Schedule a time with us</Link>.</p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid>
          <Column size={4}>
            <Link to="/case-studies/areametrics">
              <BleedImage src="case-studies/areametrics/site.png" side="left" height="66vh"/>
            </Link>
          </Column>
          <Column size={4}>
            <Link to="/case-studies/baller-tv">
              <BleedImage src="case-studies/baller-tv/site-overview.png" side="right" height="66vh"/>
            </Link>
          </Column>
          <Column size={4}>
            <Link to="/case-studies/usermind">
              <BleedImage src="case-studies/usermind/site-pages.png" side="left" height="66vh"/>
            </Link>
          </Column>
        </Row>
      </Section>
      <Section spaced>
        <Row>
          <Column readable textCenter>
            <p>See examples of our work in <Link to="/portfolio/web">our portfolio</Link> or read how we helped <Link to="/case-studies/usermind">Usermind build their marketing website</Link> or <Link to="/case-studies/areametrics">AreaMetrics develop a whole new look</Link>.</p>
          </Column>
        </Row>
      </Section>
    </LayoutProjectTypes>);
};

export default WebsiteProject;
