// @flow
import type { CommonLayoutProps  } from '../../types';
import paddingAndSpacing from '../../utils/padding_and_spacing';

type Props = {
  fluid?: boolean,
  vcenter?: boolean,
} & CommonLayoutProps;

const wrapperStyles = (props: Props): any => ({
  margin: '0 auto',
  ...paddingAndSpacing(props),
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  alignItems: props.vcenter ? 'center' : '',
  maxWidth: props.fluid ? '100%' : '1100px'
});

export default wrapperStyles;
