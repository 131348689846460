//@flow
import type { Node } from "react";
import React from "react";
import { Section, Row, Column, Spread, SpreadPanel, Readable } from '../../../utils/layout/grid';
import { FluidImage } from '../../ui/images/fluid';
import { Link } from 'gatsby';

const WhyLightboard = (): Node => {

  return (
    <>
      <Section padded>
        <Row>
          <Column>
            <Readable short>
              <div className='text-center'>
                <h2>Why Lightboard?</h2>
                <p>Marketing design, on-demand.</p>
                <p>Experienced designers and account managers, powered by our own world-class collaboration software&mdash;just a click away.</p>
              </div>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Spread padded flopped vcenter>
        <SpreadPanel>
          <FluidImage src={'illustrations/monitor.png'} />
        </SpreadPanel>
        <SpreadPanel>
          <Readable short right>
            <h3>Always available, always awesome.</h3>
            <p>We're fast. We start design within a business day and are finished before your last agency would have even started.</p>
            <p>Need an illustrator for one project and an animator for the next? Need a web developer and a print expert? No problem. We always have the right person for the project.</p>
          </Readable>
        </SpreadPanel>
      </Spread>
      <Spread padded vcenter>
        <SpreadPanel>
          <FluidImage src={'illustrations/presentation.png'} />
        </SpreadPanel>
        <SpreadPanel>
          <Readable short>
            <h3>Project management...for free</h3>
            <p>Every customer has a dedicated account manager. That's someone who understands your brand and workflow, and makes sure every project is smooth and efficient.</p>
            <p>We become part of your team, giving you time to focus on the millions of other important things on your plate.</p>
          </Readable>
        </SpreadPanel>
      </Spread>
      <Spread flopped vcenter>
        <SpreadPanel>
          <FluidImage src={'illustrations/documents.png'} style={{ 'height': '400px' }} imgStyle={{ 'height': '400px', 'objectFit': 'contain' }} />
        </SpreadPanel>
        <SpreadPanel>
          <Readable right short>
            <h3>Simple Pricing</h3>
            <p>With our <Link to='/memberships'>Memberships</Link> we offer flat, monthly pricing at a fraction of the cost of hiring a full-time designer.</p>
            <p>Our account managers work with you to plan your month, quarter, or year of projects, and then work hand-in-hand with your team to deliver efficiently and cost-effectively.</p>
          </Readable>
        </SpreadPanel>
      </Spread>
    </>
  );

};

export default WhyLightboard;
