//@flow
import type { Node } from "react";
import React from "react";
import { graphql } from 'gatsby';
import { LogoImage } from '../components/ui';

import Layout from '../components/layouts/layout-basic';
import { HeroAngle, HeroBlurb } from '../components/heroes';
import EmailForm from '../components/forms/email';

import { ExtrudedText, PullQuote } from '../components/ui';
import colors from '../utils/config/colors';

import {Section, Row, Column, Spread, SpreadPanel, Readable} from "../utils/layout/grid";
import HeroBars from '../components/heroes/hero-bars';
import { Link } from 'gatsby';
import PortfolioCallout from '../components/pages/shared/portfolio-callout';
import ContactForm from '../components/forms/contact/index';
import ProjectTypesDetails from '../components/pages/shared/project-types-details';
import type { Review } from "../utils/types/review";

const SeattleBars = function() {
  return <HeroBars seattle={true}/>;
};

function buildQuote(review: Review) {
  return (
    <Column size={6} key={review.id}>
      <PullQuote inline title={review.role} company={review.company} name={review.name} headshot={review.headshotImg?.childImageSharp?.gatsbyImageData}>
        {review.quote}
      </PullQuote>
    </Column>
  );
}

const Seattle = ({data}: {data: Object}): Node => {
  let primaryHeroColor = colors.primaryHero;

  return (
    <Layout pageTitle="Lightboard: Seattle Marketing Design Agency">
      <HeroAngle backgroundColor={primaryHeroColor} heroBars={SeattleBars}>
        <HeroBlurb>
          <Readable left>
            <ExtrudedText headlineText="We 💘 Seattle" extrudeColor={primaryHeroColor}/>
            <h1>Marketing design, on-demand.</h1>
            <p>Lightboard gives your team superpowers.</p>
            <p>Experienced designers and account managers, powered by our own world-class collaboration software—just a click away.</p>
            <div className='spaced'>
              <EmailForm formLocation="seattle" buttonLabel="Request Demo" />
            </div>
          </Readable>
        </HeroBlurb>
      </HeroAngle>
      <Section>
        <Row>
          <Column>
            <Readable className='spaced-2x'>
              <h1 className='underscore'>Roots in the Seattle Design Community</h1>
              <p>Lightboard was founded in 2015 in Seattle. We were part of the <a href="https://techstars.com/">Techstars</a> class of 2015, and our founders have long been involved in the Seattle design and web development community.</p>

              <p>We've worked with dozens of Seattle companies to deliver great design, and we're always delighted to work with a new team in our home town. <Link to='/contact'>Contact us</Link> to set up a time to chat—we might even be able to set up an in-person meeting to get to know you. Our office is in downtown—within sight of the Space Needle.</p>

              <div className='text-center spaced-2x'>
                <h1>Serving the Seattle Marketing Community</h1>
                <small>A FEW OF OUR SEATTLE AREA CUSTOMERS</small>
              </div>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Spread vcenter>
          <SpreadPanel>
            <LogoImage src='logos/seattle-logos-a.png' alt="Lightboard Client Logos"/>
          </SpreadPanel>
          <SpreadPanel>
            <LogoImage src='logos/seattle-logos-b.png' alt="Lightboard Client Logos"/>
          </SpreadPanel>
          </Spread>
        <Spread vcenter>
          <SpreadPanel>
            <LogoImage src='logos/seattle-logos-c.png' alt="Lightboard Client Logos"/>
          </SpreadPanel>
          <SpreadPanel>
            <LogoImage src='logos/seattle-logos-d.png' alt="Lightboard Client Logos"/>
          </SpreadPanel>
        </Spread>
      </Section>
      <Section gutters>
        <Row>
          <Column textCenter>
            <h1 className='spaced'>Our customers, in their own words</h1>
          </Column>
        </Row>
        <Row>
          {data.seattleReviews.edges.map(review => buildQuote(review.node))}
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <p>Curious how it works? Read case studies about how we've helped Seattle companies <Link to="/case-studies/glowforge">Glowforge</Link> and <Link to="/case-studies/one-energy-renewables">OneEnergy Renewables</Link> with great design.</p>
          </Column>
        </Row>
        <Row>
          <Column readable>
            <h1 className='underscore headroom'>How we can help</h1>
            <p>Starting a project with Lightboard is simple. Find a project type below that matches what you need, and fill out a short form describing your project.</p>

            <p>For example, if you'd like us to redesign your PowerPoint sales deck, upload your current PowerPoint file and a few examples of PowerPoint presentations that you like. We'll read through the brief and send you an estimate—and if we have any questions we'll follow up with an email or a phone call.</p>

            <p>Once we have an approved estimate, we'll assign a designer and start work. You should receive your first round of design within a few business days.</p>

            <p><Link to="/difference">Read more</Link> about our process or choose a project type below.</p>
          </Column>
        </Row>
      </Section>
      <ProjectTypesDetails/>
      <Section inset padded>
        <h1 className='text-center spaced'>Not sure what you need? Take a look at our portfolio.</h1>
        <PortfolioCallout/>
      </Section>
      <Section padded>
        <Row>
          <Column readable>
            <ContactForm/>
          </Column>
        </Row>
      </Section>
    </Layout>);
};

export default Seattle;

export const pageQuery: any = graphql`
  query {
    seattleReviews: allReviewsJson(filter: {seattle: {eq: true}}) {
      totalCount
      edges {
        node {
          ...reviewDetails
        }
      }
    }
}`;
