//@flow
import type { Node } from "react";
import React from "react";
import ContactForm from "../components/forms/contact";

import { Column, Row, Section } from "../utils/layout/grid";

import { Link } from "gatsby";
import ClientLogos from '../components/pages/projects/common/client-logos';
import WhyLightboard from '../components/pages/shared/why-lightboard';

import Lottie from "lottie-react";
import SealAnimation from "../animations/seal-animation.json";
import LayoutLifted from "../components/layouts/layout-lifted";

const AboutPage = ():Node => {

  return (
    <>
      <div style={{position:"absolute", zIndex:"1", top:"-200px", width:"100%", height:"150vh", backgroundColor:"#0475DB"}}>
        <Lottie animationData={SealAnimation} loop={false} initialSegment={[0,80]} />
      </div>

      <LayoutLifted pageTitle="About Lightboard" liftedHeight="-50vh" pageDescription="Lightboard is an agency built around software to efficiently deliver small projects for marketing campaigns." onViewEvent="about">
        <Section padded style={{'boxShadow': 'inset 0px -10px 15px -5px rgba(0, 0, 0, 0.2)'}}>
          <Row>
            <Column readable>

              <h1 className='headroom'>A new kind of agency.</h1>

              <p>The agency model is broken. Traditional agencies work well with large-scale projects, but break down on smaller projects where the timeline is measured in days instead of months.</p>

              <p>Modern marketing campaigns are built on small projects with many deliverables.
              The challenge of small projects is logistics&mdash;getting the information
              together for the project, coordinating the design team and client
              stakeholders, and efficiently producing the deliverables.</p>

              <p>We built Lightboard from the ground up to handle small
              projects, and pride ourselves on delivering quality craftsmanship reliably and
              efficiently. We've done it thousands of times for hundreds of
              customers, and we'd love to find out how we could help you.</p>

              <p>We work as an extension of an in-house design team, collaborating
              with marketing managers to produce presentations (PowerPoint, Keynote, Google Slides), case
              studies, PDFs, and eBooks. We also design and develop websites for popular platforms like Webflow, WordPress, HubSpot, Marketo, and Shopify.</p>
            </Column>
          </Row>
        </Section>
        <ClientLogos/>
        <Section padded>
          <Row>
            <Column readable>

              <h2>History</h2>

              <p>We founded Lightboard in 2015. Since then, we've
              completed thousands of projects for clients across the United
              States. We're headquartered in <Link to="/seattle">Seattle, WA</Link>.</p>

              <p>Read about our launch in <a href="https://www.geekwire.com/2015/lightboard/">Geekwire</a>. We were part of the Seattle <a href="https://techstars.com">Techstars accelerator</a> in 2015.</p>

              <p>Curious to learn more? Watch this interview with our CEO, <a href="https://www.linkedin.com/in/bbouse/">Brad Bouse</a>, to see how Lightboard works: </p>

              <iframe width="560" height="315" src="https://www.youtube.com/embed/aaGoY4Rg71A" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

            </Column>
          </Row>
        </Section>
        <WhyLightboard/>
        <Section>
          <Row>
            <Column readable>
              <ContactForm formLocation="about"/>
            </Column>
          </Row>
        </Section>
      </LayoutLifted>
    </>
  );
};

export default AboutPage;
