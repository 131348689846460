import * as React from "react";
import { css } from '@emotion/react';
import { Centerer, Column, Row, Section, Spread, SpreadPanel, Readable } from "../../../utils/layout/grid";
import { BleedImage, FluidImage } from '../../ui/images/fluid';
import EmailForm from '../../forms/email/index';
import { Panel } from '../../containers/panel';

import { StyledText as Text } from '../../ui/styled-text';

const TourSlide = ({children}: {children:React.Node}) => {

  return (
    <Panel height='100vh' color='#ffffff' padding={0} css={css`background-position: bottom center;`} backgroundImageUrl="tour/background-grid.png">
      <Centerer css={css`z-index:10001;min-width:100vw;`}>
        {children}
      </Centerer>
    </Panel>
  );
};

export const SlideAlwaysAwesome = () => (
  <TourSlide>
    <Section>
      <Row fluid>
        <Column textCenter>
          <Text size={8.1}>LIGHTBOARD</Text>
          <Text size={2.7} thin> ALWAYS AWESOME, ALWAYS AVAILABLE</Text>
          <Text size={7.7}>DESIGN TEAM</Text>
          <Text size={2.6} thin> FOR SALES & MARKETING DEPARTMENTS</Text>
        </Column>
      </Row>
    </Section>
  </TourSlide>
);

export const SlideAtAGlance = () => (
  <TourSlide>
    <Spread fluid vcenter>
      <SpreadPanel>
        <Readable right>
          <h1>At A Glance</h1>
          <p>Lightboard is a turnkey design solution for sales and marketing teams.</p>
          <p>When you request a project, we'll send you a flat-rate estimate, assign a great designer, and our account managers run the project for you.</p>

          <h1>Key Features</h1>
          <ul>
            <li>Request and review projects online.</li>
            <li>Design starts within a business day.</li>
            <li>Pay via credit card or ACH.</li>
            <li>Stellar designers, based in Seattle.</li>
          </ul>
        </Readable>
      </SpreadPanel>
      <SpreadPanel>
        <BleedImage src="tour/laptop-mockup.png" height="75vh" side="right" fadeIn={false} />
      </SpreadPanel>
    </Spread>
  </TourSlide>
);

export const SlideWhatWeDo = () => (
  <TourSlide>
    <Spread fluid flopped vcenter>
      <SpreadPanel>
        <Readable left short>
          <h1> What we do</h1>
          <p>Lightboard designs sales and marketing materials. PowerPoints, case studies, eBooks, PDFS, display ads, social media, landing pages, and email templates.</p>

          <h1> Why Use Lightboard</h1>
          <p>Our account managers give you the efficiency of an agency without the overhead of fancy offices. Our distributed network of designers means we always have availability, and can match your project with the right designer. Agency-quality, bite-sized prices.</p>

          <h1>Who uses Lightboard.</h1>
          <p>Our customers are marketing managers and agencies that need a reliable and efficient design resource.</p>
        </Readable>
      </SpreadPanel>
      <SpreadPanel>
        <Readable right>
          <FluidImage src="home/lightboard-machine.png" fadeIn={false} />
        </Readable>
      </SpreadPanel>
    </Spread>
  </TourSlide>
);

import {
  FaSuitcase,
  FaWrench,
  FaCommentAlt,
  FaCloudDownloadAlt
} from 'react-icons/fa';

export const SlideHowItWorks =() => {
  const processBox = css`
    max-width: 200px;
    text-align: center;
    margin-top: 4rem;
    padding: 2rem 2rem;
    border-radius: 0.5rem;
    border: solid 1px #FFFFFF;
    p { margin-top:1rem;}
  `;

  return (
    <TourSlide>
      <Section>
          <Row>
            <Column textCenter>
            <Text size={5}>How it works</Text>
            <Text size={2.6} thin>Three steps to great design</Text>
            </Column>
          </Row>
          <Row>
            <Column size={3}>
              <div css={processBox}>
                <FaSuitcase/>
                <p>Submit a request. We'll review and send you an estimate same day.</p>
              </div>
            </Column>
            <Column size={3}>
              <div css={processBox}>
                <FaWrench/>
                <p>Our team starts work within a business day. View progress through our app.</p>
              </div>
            </Column>
            <Column size={3}>
              <div css={processBox}>
                <FaCommentAlt/>
                <p>Changes? No problem. We work with you until it's perfect.</p>
              </div>
            </Column>
            <Column size={3}>
              <div css={processBox}>
                <FaCloudDownloadAlt/>
                <p>All set? Download final assets, guaranteed to put a smile on your face.</p>
              </div>
            </Column>
          </Row>
        </Section>
    </TourSlide>
  );
};

export const SlideAwesomeDesigners =() => (
  <TourSlide>
    <Spread fluid vcenter flopped>
      <SpreadPanel>
        <div className='text-center'>
          <Text size={6} thin>our designers</Text>
          <Text size={6.3}>are awesome</Text>
        </div>
      </SpreadPanel>
      <SpreadPanel>
        <Readable short right>
          <p>Our designers are all in the United States, and in your timezone whenever possible.</p>
          <p>And most importantly, all of our projects are run by a Lightboard account manager. That means you have a professional design manager helping you get the best results. We've handled thousands of projects, and know how to get you a great final product.</p>
        </Readable>
      </SpreadPanel>
    </Spread>
  </TourSlide>
);

export const SlidePresentations =() => (
  <TourSlide>
    <Spread fluid vcenter >
      <SpreadPanel>
        <Readable short right>
          <h1>Presentations & Case Studies</h1>
          <p>Look your best for your customers.</p>
          <p>We'll give you crisp, polished design for your presentations, case studies and white papers.</p>
        </Readable>
      </SpreadPanel>
      <SpreadPanel>
        <BleedImage src="tour/portfolio-presentations.png" height="100vh" side="right" fadeIn={false}/>
      </SpreadPanel>
    </Spread>
  </TourSlide>
);

export const SlideAds = () => (
  <TourSlide>
    <Spread fluid vcenter flopped >
      <SpreadPanel>
        <Readable short left>
          <h1> Display Ads & Social Media</h1>
          <p>Amplify your ad spend with great design for Facebook, static, or animated HTML5 units.</p>
          <p>We're experienced with all of the major ad networks (Facebook, Google Display Network, DoubleClick, AdRoll, etc.) and will make sure your units will pass their approval processes.</p>
        </Readable>
      </SpreadPanel>
      <SpreadPanel>
        <BleedImage src="tour/portfolio-ads.png" height="100vh" side="left" fadeIn={false}/>
      </SpreadPanel>
    </Spread>
  </TourSlide>
);

export const SlideIllustration = () => (
  <TourSlide>
    <Spread fluid vcenter >
      <SpreadPanel>
        <Readable short right>
          <h1>Illustration & Icons</h1>
          <p>When you need to stand out from the crowd, custom illustration fits the bill.</p>
          <p>We have illustrators with a breadth of style options&mdash;perfect for whatever you need.</p>
        </Readable>
      </SpreadPanel>
      <SpreadPanel>
        <BleedImage src="tour/portfolio-illustration.png" height="100vh" side="right" fadeIn={false}/>
      </SpreadPanel>
    </Spread>
  </TourSlide>
);

export const SlideWeb = () => (
  <TourSlide>
    <Spread fluid vcenter flopped>
      <SpreadPanel>
        <Readable short left>
          <h1>Websites & Emails</h1>
          <p>Need a landing page? We have developers experienced with Wordpress, SquareSpace, Hubspot, Unbounce and more.</p>
          <p>Launching an email campaign? We can design and code HTML that will look great on mobile and desktop, even that old version of Outlook everyone hates.</p>
        </Readable>
      </SpreadPanel>
      <SpreadPanel>
        <BleedImage src="tour/portfolio-websites.png" height="100vh" side="left" fadeIn={false}/>
      </SpreadPanel>
    </Spread>
  </TourSlide>
);

export const SlideLogos = () => (
  <TourSlide>
    <Section>
      <Row fluid>
        <Column textCenter>
          <Text size={3}>We've completed thousands of projects for great clients.</Text>
        </Column>
      </Row>
      <Row>
        <Column size={6} mobileSize={6}>
          <FluidImage src="tour/logos-1.png" fadeIn={false}/>
        </Column>
        <Column size={6} mobileSize={6}>
          <FluidImage src="tour/logos-2.png" fadeIn={false}/>
        </Column>
      </Row>
      <Row>
        <Column size={6} mobileSize={6}>
          <FluidImage src="tour/logos-3.png" fadeIn={false}/>
        </Column>
        <Column size={6} mobileSize={6}>
          <FluidImage src="tour/logos-4.png" fadeIn={false}/>
        </Column>
      </Row>
    </Section>
  </TourSlide>
);

export const SlideCta = () => (
  <TourSlide>
    <Section>
      <Row>
        <Column textCenter>
          <Text size={10} thin>The Great</Text>
          <Text size={13}>Design</Text>
          <Text size={7.4} thin>you Deserve</Text>
          <div className='spaced'>
            <Readable>
              <EmailForm formLocation='tour' buttonLabel="Learn More"/>
            </Readable>
          </div>
        </Column>
      </Row>
    </Section>
  </TourSlide>
);
