//@flow
import type { Node } from 'react';
import React from "react";
type Props = {
  mp4: string,
  webm?: string
};

const HeroVideo = ({ mp4, webm }: Props): Node => (
  <video autoPlay playsInline loop muted style={{"maxWidth": "100vw", "width": "100vw"}}>
    <source src={mp4} type='video/mp4' />
    {webm && <source src={webm} type='video/webm' />}
  </video>
);

export default HeroVideo;
