// @flow
import * as React from 'react';
import styled from '@emotion/styled';
import breakpoints from '../../../utils/config/breakpoints.json';
import { Link } from 'gatsby';
import { Section, Row, Column } from '../../../utils/layout/grid';

type Props = {
  absolute?: boolean,
  base?: {
    url:string,
    title: string
  },
  fluid?: boolean,
  children?: React.Node
};

const Wrapper = styled.div(({absolute}) => {
  let styles = {
    margin: "2rem auto 2rem auto",
    fontSize: "1.4rem",
    textTransform: "uppercase",
    [breakpoints.breakpointMobile]: {
      margin: '0.5rem auto'
    }
  };
  if (absolute) {
    return {
      position: 'absolute',
      left: '2rem',
      top: '6rem',
      zIndex:'101',
      ...styles
    };
  } else {
    return styles;
  }
});

const Breadcrumbs = ({ children, absolute, base, fluid }: Props):React.Node => {

  return (
    <Section>
      <Row fluid={fluid}>
        <Column>
          <Wrapper>
            {base && <><Link to={base.url}>{base.title}</Link>{" > "}</> }
            {children}
          </Wrapper>
        </Column>
      </Row>
    </Section>
  );
};

export default Breadcrumbs;
