//@flow
import * as React from 'react';
import { css } from '@emotion/react';
import { FluidImage } from '../images/fluid';

type Props = {
  left:string,
  right:string,
  className?: string,
  fluid?: boolean,
  sliderLineWidth:number,
  handleSize:number,
  hover:boolean,
  sliderPositionPercentage:number,
  onSliderPositionChange?:Function
}
type State = {
  sliderPositionPercentage:number,
  imageWidth: number,
  isLoaded:boolean,
  loaded: { left:boolean, right: boolean }
}

const containerStyle = css`boxSizing: border-box;
  position: relative;
  width: 100%;
  overflow: hidden;`;

const underImageStyle = css`display: block;
    height: auto;
    width: 100%;`;

const boxShadow = '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)';

class CompareImage extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.container = React.createRef();
    this.underImage = React.createRef();
  }

  container: any;
  underImage: any;

  propStyles: any = {
    line:{},
    handle:{},
    arrow:{},
    leftArrow:{},
    rightArrow:{}
  };

  state: State = {
    sliderPositionPercentage: 0.5,
    imageWidth: 0,
    isLoaded: false,
    loaded: {left:false, right: false},
  };

  static defaultProps: any = {
    sliderLineWidth: 2,
    handleSize: 40,
    hover: true,
    sliderPositionPercentage: 0.5,
  };

  componentDidMount() {
    let { handleSize, sliderLineWidth } = this.props;
    this.propStyles = {
      line: {
        background: 'white',
        boxShadow: boxShadow,
        flex: '0 1 auto',
        height: '100%',
        width: `${sliderLineWidth}px`,
      },
      handle: {
        alignItems: 'center',
        border: `${sliderLineWidth}px solid white`,
        borderRadius: '100%',
        boxShadow: boxShadow,
        boxSizing: 'border-box',
        display: 'flex',
        flex: '1 0 auto',
        height: `${handleSize}px`,
        justifyContent: 'center',
        width: `${handleSize}px`,
      },
      leftArrow: {
        border: `inset ${handleSize * 0.15}px rgba(0,0,0,0)`,
        height: '0px',
        width: '0px',
        borderRight: `${handleSize * 0.15}px solid #e6e6e6`,
        marginLeft: `-${handleSize * 0.25}px`, // for IE11
        marginRight: `${handleSize * 0.25}px`,
      },
      rightArrow: {
        border: `inset ${handleSize * 0.15}px rgba(0,0,0,0)`,
        height: '0px',
        width: '0px',
        borderLeft: `${handleSize * 0.15}px solid #e6e6e6`,
        marginRight: `-${handleSize * 0.25}px`, // for IE11
      },
    };

    this.setupListeners();
  }

  componentWillUnmount() {
    this.finishSliding();
    this.container.current.removeEventListener('mouseup', this.finishSliding);
    this.container.current.removeEventListener('touchend', this.finishSliding);
  }

  onImageLoad: ((id: "left" | "right", e: any) => void) = (id: "left" | "right", e:Object) => {
    let { loaded } = this.state;
    loaded[id] = true;

    let state = { loaded, isLoaded: false, imageWidth: this.containerWidth() };

    if (loaded['left'] && loaded['right']) {
      state.isLoaded = true;
    }
    this.setState(state);
  };

  containerWidth: (() => any) = () => {
    let _containerWidth;
    if (this.props.fluid) {
      _containerWidth = this.container.current.getBoundingClientRect().width;
    } else {
      _containerWidth = this.underImage.current.getBoundingClientRect().width;
    }

    return _containerWidth;
  };

  startSliding: ((e: Event) => void) = (e:Event) => {
    if (!('touches' in e)) {
      e.preventDefault();
    }

    this.handleSliding(e);
    this.container.current.addEventListener('mousemove', this.handleSliding);
    this.container.current.addEventListener('touchmove', this.handleSliding);
  };

  finishSliding: (() => void) = () => {
    this.container.current.removeEventListener('mousemove', this.handleSliding);
    this.container.current.removeEventListener('touchmove', this.handleSliding);
  };

  handleSliding: ((event: any) => void) = (event:Object) => {
    let { onSliderPositionChange, sliderLineWidth, fluid } = this.props;
    let { imageWidth } = this.state;
    const e = event || window.event;

    let positionContainer = (fluid) ? this.container.current : this.underImage.current;

    const cursorXFromViewport = e.touches ? e.touches[0].pageX : e.pageX;
    const cursorXFromWindow = cursorXFromViewport - window.pageXOffset;
    const imagePosition = positionContainer.getBoundingClientRect();

    let pos = cursorXFromWindow - imagePosition.left;

    const minPos = 0 + (sliderLineWidth || 1) / 2;
    const maxPos = imageWidth - (sliderLineWidth || 1) / 2;

    if (pos < minPos) pos = minPos;
    if (pos > maxPos) pos = maxPos;

    let percentage = (pos/imageWidth);
    this.setState({
      sliderPositionPercentage: percentage
    });

    if (onSliderPositionChange) {
      onSliderPositionChange(percentage);
    }
  };

  setStyles: (() => any) = () => {
    let { handleSize, hover } = this.props;
    let {imageWidth, sliderPositionPercentage} = this.state;
    return {
      overImage: {
        clip: `rect(auto, ${imageWidth *
          sliderPositionPercentage}px, auto, auto)`,
        display: 'block',
        height: 'auto', // fit to the height of under image
        objectFit: 'contain',
        position: 'absolute',
        top: 0,
        width: '100%',
      },
      slider: {
        alignItems: 'center',
        cursor: !hover && 'ew-resize',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        left:
          imageWidth * sliderPositionPercentage -
          handleSize / 2 +
          'px',
        position: 'absolute',
        top: 0,
        width: `${handleSize}px`,
      }
    };
  };

  setupListeners: (() => void) = () => {
    let container = this.container.current;
    if (!container) {
      return;
    }

    container.addEventListener('touchstart', this.startSliding);
    container.addEventListener('touchend', this.finishSliding);

    if (this.props.hover) {
      container.addEventListener('mouseenter', this.startSliding);
      container.addEventListener('mouseleave', this.finishSliding);
    } else {
      container.addEventListener('mousedown', this.startSliding);
      window.addEventListener('mouseup', this.finishSliding);
    }
  };

  staticImages(): any {
    let { left, right } = this.props;
    let stateStyles = this.setStyles();

    return (
      <>
        <img
          onLoad={this.onImageLoad.bind(this, 'left')}
          src={left}
          css={underImageStyle}
          ref={this.underImage}
        />
        <img
          onLoad={this.onImageLoad.bind(this, 'right')}
          src={right}
          style={stateStyles.overImage}
        />
      </>
    );
  }

  fluidImages(): any {
    let { left, right } = this.props;
    let stateStyles = this.setStyles();
    return (
      <>
        <FluidImage src={left} css={underImageStyle} onLoad={this.onImageLoad.bind(this, 'left')} />
        <FluidImage src={right} style={stateStyles.overImage} onLoad={this.onImageLoad.bind(this, 'right')}/>
      </>
    );
  }

  render(): any {
    let { className, fluid } = this.props;
    let {line, handle, leftArrow, rightArrow} = this.propStyles;
    let stateStyles = this.setStyles();

    return (
      <div css={containerStyle} ref={this.container} className={className}>
        {!fluid && this.staticImages()}
        {fluid && this.fluidImages()}
        <div style={stateStyles.slider}>
          <div style={line} />
          <div style={handle}>
            <div style={{...leftArrow}} />
            <div style={{...rightArrow}} />
          </div>
          <div style={line} />
        </div>
      </div>
    );
  }
}

export default CompareImage;
