//@flow
import type { Node } from 'react';
import React from "react";
import { Row, Column, Section } from '../../../utils/layout/grid';
import { PrimaryButton } from '../../forms/buttons/index';
import { BleedImage, LogoImage } from '../../ui';
import { useCaseStudiesIndex } from '../../../utils/graphql/case_studies';
import type { CaseStudy } from '../../../utils/types/case_study';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import Colors from '../../../utils/config/colors';

const CaseStudyHero = ({caseStudyId}: {caseStudyId: string}): Node => {
  let caseStudy:CaseStudy = useCaseStudiesIndex(caseStudyId);

  const wrapper = css`
    border: solid 2px rgba(0,0,0,0);
    background-color: rgba(0,0,0,0);
    margin: 2rem auto;
    padding: 1rem;
    transition: border 0.5s ease, background-color 0.5s ease;
    &:hover {
      border: solid 2px ${Colors.action};
      background-color: rgba(0,0,0,0.015);
    }
    a, a:hover, a:active {
      color: ${Colors.bodyFont} !important;
    }
  `;

  return (
    <div css={wrapper}>
      <Link to={`/case-studies/${caseStudy.slug}`}  >
        <Section padded>
          <Row fluid vcenter>
            <Column size={6}>
              <BleedImage side="left" height="300" src={`case-studies/${caseStudy.slug}/hero.png`}/>
            </Column>
            <Column size={6}>
              <div style={{'margin': '2rem 2rem 2rem 6rem', 'textAlign': 'left'}}>
                <div className='spaced-half'>
                  <LogoImage src={`logos/${caseStudy.slug}.png`} width={200} style={{'margin':'0'}}/>
                </div>
                <p>{caseStudy.metaDescription}</p>
                <div className='spaced-half'>
                  <PrimaryButton>
                    Read
                    <FaLongArrowAltRight css={css`width:2rem;`}/>
                  </PrimaryButton>
                </div>
              </div>
            </Column>
          </Row>
        </Section>
      </Link>
    </div>
  );
};

export default CaseStudyHero;
