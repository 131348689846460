//@flow
import { useStaticQuery, graphql } from 'gatsby';
import type { HelpArticle } from '../types/help_article';
let helpArticles;

export const useHelpArticles = ():HelpArticle[] => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(filter:{ fileAbsolutePath: {regex:"/help/"}}) {
          totalCount
          edges {
            node {
              id
              frontmatter {
                title
                imageUrl
                briefDescription
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  );
  if (!helpArticles) {
    helpArticles = allMarkdownRemark.edges.map(helpArticle => {

      let { node } = helpArticle;
      let { title, briefDescription, imageUrl } = node.frontmatter;
      let {slug } = node.fields;
      return {title, briefDescription, slug, imageUrl};
    });
  }
  return helpArticles;
};
