import AppContext from './context';
import type { AppContextType } from './context';
import { useContext } from 'react';
import { useSiteMetadata } from '../graphql/site_metadata';

import {
  identify as mixpanelIdentify,
  track as mixpanelEvent,
  alias as mixpanelAlias,
  initTracking as mixpanelInit
} from './vendors/mixpanel';

import {
  trackPageView as hubspotPageView,
  identify as hubspotIdentify
} from './vendors/hubspot';

import {
  track as segmentEvent,
  identify as segmentIdentify
} from './vendors/segment';

function pathname() {
  if (typeof window === 'undefined') return null;
  if (!window.location) return null;
  return window.location.pathname;
}

function fbq(event: string, type: string, params?: Object) {
  if (window.fbq) {
    window.fbq(event, type, params);
  } else {
    //console.error("fbq doesn't exist");
  }
}

function gtag(eventName, params) {
  if (window.dataLayer) {
    window.dataLayer.push({'event':eventName, ...params});
  } else {
    //console.error("gtag doesn't exist");
  }
}

const trackPageView = (sessionStart: boolean) => {
  const path = pathname();
  hubspotPageView();
  mixpanelEvent('navigate', { path: path, sessionStart: sessionStart });
  segmentEvent('navigate', { path: path, sessionStart: sessionStart });
};

const trackEvent = (name: string, data: Object) => {
  mixpanelEvent(name, data);
  segmentEvent(name, data);
};

const alias = (id: string) => {
  mixpanelAlias(id);
};

const identify = (settings: Object) => {
  hubspotIdentify(settings);
  mixpanelIdentify(settings);
  segmentIdentify(settings);
};

function conversion() {
  trackEvent('conversion');
  fbq('track', 'Lead');
  gtag('email-conversion');
}

function sessionStart() {
  trackEvent('session-start', {path:pathname()});
}

function retarget() {
  trackEvent('retarget');
  fbq('track', 'PageView');
  gtag('ga-retarget');
}

function setup(facebookPixelId, mixpanelToken) {
  if (!trackingSetup) {
    mixpanelInit(mixpanelToken);
    fbq('init', facebookPixelId);
    trackingSetup = true;
    return true;
  }
  return false;
}

export type TrackingFunctions = {
  conversion: Function,
  event: Function,
  alias: Function,
  pageView: Function,
  refresh: Function,
  retarget: Function,
  setup: TrackingFunctions
};

let trackingSetup = false;

const buildTracking = (context: AppContextType): TrackingFunctions => {
  const { track, refresh } = context;
  let { facebookPixelId, mixpanelToken } = useSiteMetadata();

  if (track) {
    return {
      alias,
      conversion,
      event: trackEvent,
      identify,
      pageView: trackPageView,
      sessionStart,
      setup: setup.bind(this, facebookPixelId, mixpanelToken),
      retarget,
      refresh
    };
  }

  return {
    alias: () => { },
    conversion: () => { },
    event: () => { },
    identify: () => { },
    pageView: () => { },
    sessionStart: () => { },
    setup: () => { },
    retarget: () => { },
    refresh
  };
};

const useTracking = (): TrackingFunctions => buildTracking(useContext(AppContext));

export default useTracking;
