//@flow
import * as React from "react";
import Layout from './layout-basic';
import type { LayoutProps } from './layout-basic';
import LiftedContent from './lifted/lifted-content';

import { Panel } from '../containers/panel';
import { css } from '@emotion/react';

export interface LiftedLayoutProps extends LayoutProps {
  heroUrl?: string,
  heroHeight?: string,
  breadcrumbs?: React.Node,
  submenu?: React.Node,
  liftedHeight?: string,
  fadeHero?: boolean,
  portal?: {color:string, url: string}
}

const LayoutLifted = (props: LiftedLayoutProps): React.Node => {

  let { portal, fadeHero, heroHeight, heroUrl, liftedHeight, breadcrumbs, submenu, children, ...restProps } = props;
  let portalCss = css({
    'boxShadow': 'inset 0 0 12px 6px rgba(0,0,0,0.10)'
  });

  let Portal;
  if (portal) {
    Portal = <Panel css={portalCss} height="100%" padding={0} borderRadius='200px' backgroundImageUrl={portal.url} backgroundColor={portal.color}/>;
  }

  return (
    <Layout {...restProps} solidMenu>
      {submenu}
      <LiftedContent heroHeight={heroHeight || "85vh"} heroImageUrl={heroUrl} liftedHeight={liftedHeight} portal={Portal} fadeHero={fadeHero} breadcrumbs={breadcrumbs}>
        {children}
      </LiftedContent>
    </Layout>
  );
};

export default LayoutLifted;
