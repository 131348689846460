//@flow
import React from "react";
import type { Node } from "react";

import { Column, Row, Section } from "../../utils/layout/grid";
import { ImageCaption, LogoImage } from "../../components/ui";
import LayoutCaseStudy from '../../components/layouts/layout-case-study';
import { useShowcasesIndex } from '../../utils/graphql/showcases';

const Firstleaf =  (): Node => {

  let caseStudy = useShowcasesIndex('firstleaf');

  return (
    <LayoutCaseStudy caseStudy={caseStudy} heroUrl='case-studies/firstleaf/hero.png'>
      <Section spaced>
        <Row>
          <Column readable>
            <LogoImage src="logos/firstleaf.png" width={200} className='footroom'/>
            <p>We supported Firstleaf's digital and print marketing teams to build their DTC wine business. On the digital side, we helped with display ads, landing pages, and infographics. On the print side, we designed the postcards that were tucked into each wine box, bottle wraps, and more. </p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid >
          <Column>
            <ImageCaption src='case-studies/firstleaf/postcard-1.png'/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/firstleaf/site-pages.png'/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/firstleaf/winecard-all.png'>
              Postcards describing a bit about each wine's flavor profile in a shipment.
            </ImageCaption>
          </Column>
        </Row>
      </Section>
    </LayoutCaseStudy>
  );
};

export default Firstleaf;
