//@flow
import * as React from "react";
import { LogoImage } from '../../../ui/images/logos.js';
import { Section, Row, Column } from '../../../../utils/layout/grid';
import { Panel } from '../../../containers/panel';
import EmailForm from '../../../forms/email/index';
import Emoji from 'react-emoji-render';
import CounterText from '../../../ui/counter-text';

export default function ClientLogos():React.Node {
  return (
    <Panel
      backgroundGradient={['#055598','#003963']}
      color='#FFF'
    >
      <Section padded>
        <Row>
          <Column  textCenter readable>
            <h1 className="spaced-half">You're in great company with Lightboard.</h1>
          </Column>
        </Row>
        <Row vcenter>
          <Column size={6}>
            <LogoImage src='logos/client-logos-a.png' alt="Lightboard Client Logos" white/>
          </Column>
          <Column size={6}>
            <LogoImage src='logos/client-logos-b.png' alt="Lightboard Client Logos" white/>
          </Column>
          <Column size={6}>
            <LogoImage src='logos/client-logos-d.png' alt="Lightboard Client Logos" white/>
          </Column>
          <Column size={6}>
            <LogoImage src='logos/client-logos-c.png' alt="Lightboard Client Logos" white/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row vcenter>
          <Column size={8} mobileSize={0}>
            <div style={{'fontWeight':'800', 'minHeight':'150px'}}>
              <CounterText goal={304782123}/>
            </div>
          </Column>
          <Column size={4} mobileSize={0}>
            <div className='text-left' style={{'paddingLeft':'1rem'}}>
              <small style={{'color':'#FFF'}}>Est. 2015</small>
              <h2 style={{'fontSize':'4rem'}}>Pixels delivered*.</h2>
              <p><span>*Approximately</span><Emoji text="😉"/></p>
            </div>
          </Column>
        </Row>
        <Row>
          <Column readable textCenter>
            <div className='headroom'>
              <p>With more than 11,000 completed projects for more than 600 teams (and counting!), we've got the experience to reliably deliver what you need.</p>
              <p>Let's work together.</p>
              <div className='headroom footroom-2x'>
                <EmailForm formLocation="client-logos" buttonLabel='Request Consultation'/>
              </div>
            </div>
          </Column>
        </Row>
      </Section>
    </Panel>
  );
}
