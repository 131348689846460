//@flow
import type { Node } from "react";

import React from "react";
import { FluidImage } from '../components/ui/images/fluid';

import Layout from "../components/layouts/layout-basic";
import { HeroAngle } from "../components/heroes";
import { Column, Readable, Row, Section, Spread, SpreadPanel } from "../utils/layout/grid";

import colors from '../utils/config/colors';
import ExtrudedButton from '../components/ui/extruded-button';
import MembershipFaqs from "../components/pages/pricing/membership-faqs";
import { useMembershipFaqs } from '../utils/graphql/faqs/memberships';
import styled from '@emotion/styled';
import { Link } from "gatsby";

const ProductBorder =  styled.div({
  margin: '2rem 1rem 0 1rem',
  borderRadius: '1rem',
  border: 'solid 1px',
  borderColor: colors.borderColor,
  padding: '2rem 1rem',
  textAlign: 'center',
  h1 : {
    borderBottom: `solid 1px ${colors.borderColor}`,
    padding: '0 0 0.5rem 0',
    margin: '0 2rem 1rem 2rem'
  },
  'h2.pricing' : {
    borderTop: `solid 1px ${colors.borderColor}`,
    padding: '1rem 0 0.5rem 0',
    margin: '2rem 2rem 0rem 2rem'
  }
});

const SpecialOffer =  styled.div({
  margin: '0 1rem 2rem 1rem',
  borderRadius: '1rem',
  border: 'solid 1px',
  borderColor: colors.secondaryHero,
  padding: '2rem 3rem',
  textAlign: 'center',
});

const TwentyTwentyPromo = (): Node => {
  let { secondaryHero, bodyFont } = colors;
  let faqs = useMembershipFaqs();

  return (
    <Layout pageTitle="🎆 2020 & Lightboard & You" pageDescription="Start 2020 off on the right foot with this special offer from Lightboard." solidMenu>
      <HeroAngle backgroundColor={secondaryHero} patchColor="#FFF">
        <Spread  vcenter flopped>
          <SpreadPanel>
            <FluidImage src='illustrations/documents.png' />
          </SpreadPanel>
          <SpreadPanel >
            <Readable short right>
              <h1>Start 2020 with great design.</h1>
              <h3>A rare special offer from Lightboard, available until January 10, 2020.</h3>
              <p>Purchase a new membership by January 10 and we'll give you twice as long to use the hours.</p>
              <p>Purchase a <strong>quarterly membership</strong> and your hours won't expire until the end of June.</p>
              <p>Or purchase a <strong>monthly membership</strong>, and we'll give you until March to use them. </p>
              <p>Take advantage of year-end budget to lock down design hours for the new year.</p>
              <Link to={"/schedule"}>
                <ExtrudedButton arrowIcon color='red'>Schedule A Call</ExtrudedButton>
              </Link>
            </Readable>
          </SpreadPanel>
        </Spread>
      </HeroAngle>
      <HeroAngle backgroundColor="#FFF" bodyColor={bodyFont}>
        <Section spaced>
          <Row>
            <Column size={3}></Column>
            <Column size={6} textCenter>
              <h1>What Are Memberships?</h1>
              <Readable left>
                <p>Memberships are packages of hours, purchased in advance and used over a period of time. We sell our Memberships in increments of 25 hours per month, which is typically the equivalent of a part-time design resource for a small marketing team.</p>
                <p>In addition to a significantly discounted hourly rate, Memberships allow our design team to efficiently utilize your hours to give you the most effective use of your budget.</p>
              </Readable>
            </Column>
            <Column size={3}></Column>
          </Row>
        </Section>
        <Section>
          <Row gutters>
              <Column size={2}>
              </Column>
              <Column size={4}>
                <ProductBorder>
                  <h1>1x Quarterly</h1>
                  <h2>75 design hours</h2>
                  <Readable left>
                    <p>Best for:<br/>
                      - Brand refresh<br/>
                      - Websites<br/>
                      - Multi-person teams<br/>
                    </p>
                  </Readable>
                  <h2 className='pricing'>$6,750</h2>
                  <p>$90/hr (Save $3,150)</p>
                </ProductBorder>
              </Column>
              <Column size={4}>
                <ProductBorder>
                  <h1>1x Monthly</h1>
                  <h2>25 design hours</h2>
                  <Readable left>
                    <p>
                      Best for:<br/>
                      - Single large project<br/>
                      - Display ads<br/>
                      - Single-person teams<br/>
                    </p>
                  </Readable>
                  <h2 className='pricing'>$2,750</h2>
                  <p>$110/hr (Save $375)</p>
                </ProductBorder>
              </Column>
            </Row>
        </Section>
        <Section>
          <Row>
            <Column size={2}></Column>
            <Column size={8} textCenter>
              <SpecialOffer>
                <h2>
                  <span>🚨</span>
                  <span>Limited Time Offer: Double Your Duration</span>
                  <span>🚨</span>
                </h2>
                <p>Purchase a Membership before January 10th, 2020 and we'll give you twice as long to use your hours.</p>
                <p><strong>Quarterly</strong> memberships expire June 30th, 2020—an additional three months.  <strong>Monthly</strong> memberships expire March 31, 2020—an additional two months.</p>
              </SpecialOffer>
              <p>Membership payments are paid in full upon start of the contract. Hours rollover at contract renewal, up to 50% of the total hours in the contract. Our Studio rate, for non-Membership projects, is $125/hr. </p>
            </Column>
            <Column size={3}></Column>
          </Row>
        </Section>
        <Section spaced={2}>
          <Row>
            <Column textCenter>
              <h1>Ready to start?</h1>
              <p>Schedule a call with your account manager to see if a Membership is a good fit for your team.</p>
              <Link to={"/schedule"}>
                <ExtrudedButton color='blue'>Schedule A Call</ExtrudedButton>
              </Link>
            </Column>
          </Row>
        </Section>
        <Section spaced>
          <Row>
            <Column textCenter>
              <h1>Frequently Asked Questions</h1>
              <p>Questions we often hear about Memberships.</p>
            </Column>
          </Row>
        </Section>
        <MembershipFaqs faqs={faqs}/>
      </HeroAngle>
    </Layout>
  );
};

export default TwentyTwentyPromo;
