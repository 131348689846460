// @flow
import React from 'react';

type GridContextValue = {
  balancedGutters?: boolean,
  flopped?: boolean,
  gutters?: boolean,
  padded?: boolean,
  inset?: boolean
};

const GridContext: React$Context<GridContextValue> = React.createContext<GridContextValue>({});
export default GridContext;
