//@flow
import type { Node } from "react";
import React from "react";
import Layout from "../../components/layouts/layout-basic";

import { Link } from 'gatsby';

import { HeroFull } from "../../components/heroes";
import { Column, Readable, Row, Section } from "../../utils/layout/grid";

import FeaturesLayout from '../../components/pages/shared/features-layout';
import { CommonCtas } from '../../components/pages/shared/common-ctas';
import { useSiteMetadata } from '../../utils/graphql/site_metadata';

const DesignTeams = (): Node => {
  let { lbAppHost } = useSiteMetadata();
  return (
    <Layout pageTitle="How Lightboard helps Design Teams" pageDescription="We can provide additional design capacity for overflow projects, or function as your entire design team.">
      <HeroFull backgroundImageUrl='customer-profiles/agencies.jpg'>
        <h4>How Lightboard Helps</h4>
        <h2>Design Teams</h2>
        <p>Extra design capacity when you need it. A network of designers with a diverse skillset.</p>
      </HeroFull>
      <Section>
        <Row>
          <Column>
            <Readable className='headroom'>
              <h1>Lightboard & Design Teams</h1>
              <p>We can provide additional design capacity for overflow projects, or function as your entire design team.</p>
              <p>When you work with Lightboard, you're working with experienced design professionals. All projects are run by a Lightboard account manager, who will work closely with your accounts team.</p>
              <p>We'll give you clear estimates up-front and reliable deadlines you can take back to your team, knowing we'll be able to deliver the goods.</p>
              <p><Link to="/tour">Take a tour</Link> to learn more, or <a href={`${lbAppHost}/register`}>create an account</a> to get started.</p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <FeaturesLayout featureSet={['abTest', 'teamCollaborate', 'designDepot', 'topDesign']} />
      <CommonCtas/>
    </Layout>
  );
};

export default DesignTeams;
