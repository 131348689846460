//@flow
import * as React from "react";
import { Link } from 'gatsby';
import { FluidImage } from "../../ui/images/fluid";
import { Readable } from '../../../utils/layout/grid';

const PreviewTemplate = ({headline, slug, children}: {headline:string, slug:string, children: React.Node}) => {
  return (
    <>
      <h1 className='underscore"'>{headline}</h1>
      {children}
    </>
  );
};

const HeaderTemplate = ({headline, slug, children}: {headline:string, slug:string, children: React.Node}) => {
  return (
    <Readable>
      <FluidImage src={`portfolio/heroes/${slug}.png`} />
      <div className="spaced">
        <h1 className='underscore"'>{headline}</h1>
        {children}
      </div>
    </Readable>
  );
};

export const DisplayAdsPreview = (): React.Node => {
  return (<PreviewTemplate headline="Display Ads & Social Media" slug="display-ads">
    <p>Amplify your ad spend with great design for display ad networks or social media.</p>
  </PreviewTemplate>);
};

export const DisplayAdsHeader = (): React.Node => {
  return (<HeaderTemplate headline="Display Ads & Social Media" slug="display-ads">
    <p>Amplify your ad spend with great design for display ad networks or social media.</p>
  </HeaderTemplate>);
};

export const IllustrationsPreview = (): React.Node => {
  return (<PreviewTemplate headline="Illustration" slug="illustrations">
    <p>Stand out from the crowd with custom illustration for content marketing, process illustration, or custom icons.</p>
  </PreviewTemplate>);
};

export const IllustrationsHeader = (): React.Node => {
  return (<HeaderTemplate headline="Illustration" slug="illustrations">
    <p>Stand out from the crowd with custom illustration for content marketing, process illustration, or custom icons.</p>
  </HeaderTemplate>);
};


export const PresentationPreview = (): React.Node => {
 return (<PreviewTemplate  headline="Presentations" slug="presentations">
    <p>You never get a second chance to make a first impression. Look your best with professionally designed PowerPoint, Keynote, or Google Slides.</p>
    <p><Link to="/projects/presentations">Learn how</Link>.</p>
  </PreviewTemplate>);
};

export const PresentationHeader = (): React.Node => {
  return (<HeaderTemplate  headline="Presentations" slug="presentations">
     <p>You never get a second chance to make a first impression. Look your best with professionally designed PowerPoint, Keynote, or Google Slides.</p>
     <p><Link to="/projects/presentations">Learn how</Link>.</p>
   </HeaderTemplate>);
 };


export const PrintPreview = (): React.Node => {
 return (<PreviewTemplate headline="Documents & PDFs" slug="print">
     <p>Look your best with a professionally designed document. We'll give you a design you'll be proud to show off.</p>
  </PreviewTemplate>);
};

export const PrintHeader = (): React.Node => {
  return (<HeaderTemplate headline="Documents & PDFs" slug="print">
     <p>One-pagers, Case Studies, eBooks, Sales Sheets—whatever you call it, we'll give you a design you'll be proud to show off. </p>
    <p>See examples below or <Link to="/projects/print">request an estimate</Link>.</p>

   </HeaderTemplate>);
 };


export const WebPreview = (): React.Node => {
 return (<PreviewTemplate headline="Website Design" slug="web">
    <p>Your landing page is your front door to the world. We can help you look your best with a fresh design.</p>
    <p><Link to="/projects/websites">Learn how</Link>.</p>
  </PreviewTemplate>);
};

export const WebHeader = (): React.Node => {
  return (<HeaderTemplate headline="Website Design" slug="web">
     <p>Your landing page is your front door to the world. We can help you look your best with a fresh design.</p>
     <p><Link to="/projects/websites">Learn how</Link>.</p>
   </HeaderTemplate>);
 };


export const BrandingPreview = (): React.Node => {
  return (<PreviewTemplate headline="Branding" slug="branding">
     <p>From logo to complete brand guidelines, we can give your company the polish it deserves.</p>
   </PreviewTemplate>);
};

export const BrandingHeader = (): React.Node => {
  return (<HeaderTemplate headline="Branding" slug="branding">
     <p>From logo to complete brand guidelines, we can give your company the polish it deserves.</p>
   </HeaderTemplate>);
};
