//@flow
import type { Review } from "../utils/types/review";import type { Node } from "react";
import React from "react";
import { Link } from 'gatsby';

import Layout from '../components/layouts/layout-basic';
import { HeroAngle, HeroBlurb } from '../components/heroes';
import EmailForm from '../components/forms/email';

import { ExtrudedText, PullQuote } from '../components/ui';
import colors from '../utils/config/colors';

import {Section, Row, Column, Readable} from "../utils/layout/grid";
import HeroBars from '../components/heroes/hero-bars';
import { useReviews } from '../utils/graphql/reviews';
import CaseStudyLinks from '../components/pages/case-studies/case-studies-links';
import PortfolioCallout from '../components/pages/shared/portfolio-callout';
import StartCta from '../components/pages/shared/start-cta';

const ReviewsPage = ():Node => {

  let primaryHeroColor = colors.primaryHero;

  function buildQuote(review: Review) {
    return (
      <Column size={6} key={review.id}>
        <PullQuote inline title={review.role} name={review.name} company={review.company} headshot={review.headshotImg ? review.headshotImg.childImageSharp.gatsbyImageData : null}>
          {review.quote}
        </PullQuote>
      </Column>
    );
  }

  let reviews = useReviews();
  return (
    <Layout pageTitle="Lightboard Customer Reviews">
      <HeroAngle backgroundColor={primaryHeroColor} heroBars={HeroBars}>
        <HeroBlurb>
          <ExtrudedText headlineText="Customer Reviews" extrudeColor={primaryHeroColor}/>
          <Readable left>
            <h1>Our customers love us.</h1>
            <p>Lightboard gives your team superpowers.</p>
            <p>We've completed thousands of projects for customers in marketing departments large and small. Here's what a few of them have to say.</p>
            <div className='spaced'>
              <EmailForm formLocation="customer-reviews" buttonLabel="Request Demo" />
            </div>
          </Readable>
        </HeroBlurb>
      </HeroAngle>
      <Section>
        <Row >
          <Column>
            <Readable left className='spaced-2x'>
              <h2>Lightboard Customer Reviews</h2>
              <p>We're blushing! We love working with great people, and we're delighted they love working with us.</p>
              <p>We help our customers with PowerPoint Presentations, sales decks, case studies, email templates, landing pages and more&mdash;everything they need to run their marketing campaigns.</p>
              <p>See why they're raving&mdash;see <Link to="/portfolio">our portfolio</Link> and what <Link to="/projects">we can do for you</Link>.</p>
              <p> Here are just a few of the nice things our customers have said:</p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section gutters>
        <Row>
          {reviews.map(review => buildQuote(review))}
        </Row>
      </Section>
      <CaseStudyLinks/>
      <PortfolioCallout/>
      <StartCta/>
    </Layout>);
};

export default ReviewsPage;
