//@flow
import * as React from "react";
import type { CaseStudy } from '../../utils/types/case_study';
import Breadcrumbs from '../ui/breadcrumbs/index';

import { CaseStudyFooter } from '../pages/case-studies/case-study-footer';

import type { LiftedLayoutProps } from './layout-lifted';

import LayoutLifted from './layout-lifted';

interface Props extends LiftedLayoutProps {
  caseStudy: CaseStudy,
  heroUrl?: string
}

const LayoutCaseStudy = (props:Props): React.Node => {

  let {caseStudy, children, heroUrl, ...rest } = props;

  let breadcrumbs = (
    <Breadcrumbs base={{url:'/case-studies', title:'Case Studies'}}>
      {caseStudy.companyName}
    </Breadcrumbs>
  );

  return (
    <LayoutLifted
      pageTitle={caseStudy.pageTitle}
      pageDescription={caseStudy.metaDescription}
      onViewEvent="case-study"
      heroHeight="85vh"
      heroUrl={heroUrl}
      portal={{'url':`case-studies/${caseStudy.slug}/square.png`, 'color':caseStudy.color}}
      breadcrumbs={breadcrumbs}
      {...rest}
      solidMenu
      fadeHero
    >
      {children}
      <CaseStudyFooter/>
    </LayoutLifted>
  );
};

export default LayoutCaseStudy;
