// flow doesn't like adding stuff to window
import "./src/styles/global.styl";

export const onRouteUpdate = ({ location, prevLocation }: {location: Object, prevLocation: Object}) => {

  window.locations = window.locations || [document.referrer];
  window.locations.push(window.location.href);
  window.previousPath = window.locations[window.locations.length - 2];

  let canonElem = document.querySelector("link[rel='canonical']");
  let existingValue = canonElem.getAttribute("href");
  let baseProtocol = canonElem.getAttribute("data-baseProtocol");
  let baseHost = canonElem.getAttribute("data-baseHost");
  let ogElem = document.querySelector("link[rel='canonical']");

  if (existingValue && baseProtocol && baseHost) {
    let path = baseProtocol + "//" + baseHost + location.pathname + location.search + location.hash;
    canonElem.setAttribute("href", path);
    ogElem.setAttribute("content", path);
  }
};
