//@flow
import * as React from "react";
import styled from '@emotion/styled';
type Props = {
  children?: React.Node,
  className?: string,
  left?: boolean,
  right?: boolean,
  short?: boolean,
  breakpoint?: number,
};

export const Readable: any = styled.div(({left, right, short, breakpoint = 900}: Props) => `
  max-width: ${short ? 350 : 600}px;
  margin: auto;
  ${right ? 'margin-right: 2rem; text-align: right;': ''}
  ${left ? 'margin-left: 2rem; text-align: left;' : ''}
  p {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: auto;
    }
  }
  @media(max-width: ${breakpoint - 1}px) {
    margin: auto;
    padding: 0 2rem;
    ${(left || right) ? 'text-align: left; margin: auto 1rem;': ''}
  }
`);
