//@flow
import React from "react";
import type { Node } from "react";

import Layout from "../../components/layouts/layout-basic";
import { Column, ColumnHalf, Row, Section, Spread, SpreadPanel, Readable } from "../../utils/layout/grid";
import { FluidImage } from '../../components/ui/images/fluid';
import EmailForm from '../../components/forms/email/index';
import { BleedImage } from '../../components/ui/images/fluid';
import { Panel, LinkedPanel } from '../../components/containers/panel';
import { css } from '@emotion/react';
import { PrimaryButton } from '../../components/forms/buttons/index';

const PowerPointTemplateSLU = (): Node => {

  const desc = "You never get a second chance to make a first impression. With the South Lake Union presentation template, you'll be able to land that pitch, close that sale, or ace that presentation—every single time.";

  return (
    <Layout pageTitle="PowerPoint Template: South Lake Union Template" pageDescription={desc} solidMenu>
      <Section>
        <Row fluid vcenter>
          <Column size={4}>
            <Readable right>
              <h4 css={css`font-family:'park-lane'; font-size:3rem;`}>Introducing the</h4>
              <h2>South Lake Union PowerPoint Theme</h2>
              <h4>A free PowerPoint template made just for you (yes you!)</h4>
              <EmailForm/>
              <p>You never get a second chance to make a first impression. With the South Lake Union PowerPoint template, you'll be able to land that pitch, close that sale, or ace that presentation—every single time.</p>
            </Readable>
          </Column>
          <Column size={8}>
            <BleedImage src="downloads/slu/hero-squares.png" side="right" offset="10%" height="80vh" width="100%"/>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid>
          <Column>
            <FluidImage src='downloads/slu/slu-ppt-theme-grid.png' alt="South Lake Union PowerPoint Theme" />
          </Column>
        </Row>
      </Section>
      <Spread fluid vcenter>
        <SpreadPanel>
          <Readable short right>
            <h4>Your story</h4>
            <h2>Visualized</h2>
            <p>Need a polished, professional PowerPoint template? We got you. The South Lake Union template has everything you need to make a lasting impression. Make it your own or keep it as is. Either way, this template will engage your audience from title to thank you. ✨</p>
          </Readable>
        </SpreadPanel>
        <SpreadPanel>
          <Panel backgroundColor="#203864" padding={0}>
            <BleedImage src="downloads/slu/isometric-slides.png" side="right" offset="20%" height="50vh"/>
          </Panel>
        </SpreadPanel>
      </Spread>
      <Spread vcenter flopped fluid>
        <SpreadPanel>
          <Readable left short>
            <h2>Charts, graphs and timelines OH MY 😍</h2>
            <p>20 slides packed with device mockups, charts, timelines, and high impact stock photos to spice up your presentation. Crisp, clean, cool... you're gonna like it. 🔥</p>
          </Readable>
        </SpreadPanel>
        <SpreadPanel>
          <Panel backgroundColor="#ED7D31" padding={0}>
            <BleedImage src="downloads/slu/laptop-swot.png" side="left" height="50vh"/>
          </Panel>
        </SpreadPanel>
      </Spread>
      <Section inset padded gutters>
        <Row fluid vcenter>
          <Column size={3/10}>
            <FluidImage src='downloads/slu/slides/ppt-slu-theme-4.png' alt="South Lake Union PowerPoint Theme"  />
          </Column>
          <Column size={4/10}>
            <FluidImage src='downloads/slu/slides/ppt-slu-theme-5.png' alt="South Lake Union PowerPoint Theme"  />
          </Column>
          <Column size={3/10}>
            <FluidImage src='downloads/slu/slides/ppt-slu-theme-6.png' alt="South Lake Union PowerPoint Theme" />
          </Column>
        </Row>
      </Section>
      <Spread vcenter flopped>
        <SpreadPanel>
          <Readable>
            <h2>The South Lake Union presentation template by Lightboard.</h2>
            <p>A gift from us, to you🎁</p>
          </Readable>
        </SpreadPanel>
        <SpreadPanel>
          <BleedImage src="downloads/slu/squares-sidebar.png" side="left" height="50vh"/>
        </SpreadPanel>
      </Spread>
      <Section>
        <Panel backgroundColor="#DEEBF7" absolute height={240} zIndex={1} className='spaced-3x'/>
        <Panel zIndex={2}>
          <Row vcenter fluid>
            <ColumnHalf>
              <Readable right>
                <FluidImage src="downloads/slu/laptop-mockup.png"  />
              </Readable>
            </ColumnHalf>
            <ColumnHalf>
              <Readable left>
                <h2>Start using the South Lake Union template.</h2>
                <EmailForm/>
              </Readable>
            </ColumnHalf>
          </Row>
        </Panel>
      </Section>
      <Section padded>
        <Row>
          <Column>
            <h2 className='spaced'>Other downloads from Lightboard</h2>
          </Column>
        </Row>
        <Row gutters>
          <Column size={4}>
            <LinkedPanel to="downloads/case-study-templates" height={300} color="#FFFFFF" screenback="rgba(0,0,0,0.6)" backgroundImageUrl="downloads/case-studies-hero.png">
              <h4>Case Study Templates</h4>
              <PrimaryButton>View</PrimaryButton>
            </LinkedPanel>
          </Column>
          <Column size={4}>
            <LinkedPanel to="downloads/presentation-covers"  height={300} color="#FFFFFF" screenback="rgba(0,0,0,0.6)" backgroundImageUrl="downloads/presentation-cover-hero.jpg">
              <h4>Presentation Cover Images</h4>
              <PrimaryButton>View</PrimaryButton>
            </LinkedPanel>
          </Column>
        </Row>
      </Section>
    </Layout>
  );
};

export default PowerPointTemplateSLU;

