// @flow
import * as React from 'react';
import GridContext from '../context';
import styled from '@emotion/styled';
import rowStyles from './styles';

type RowProps = {
  css?: any,
  children?: React.Node,
  className?: string,
  fluid?: boolean,
  balancedGutters?: boolean,
  gutters?: boolean,
  vcenter?: boolean
};

const mergeContext = (parentContext: any, childContext: { balancedGutters: void | boolean, gutters: void | boolean, ... }) => {
  const context = {...parentContext};
  for (let k of Object.keys(childContext)) {
    if (typeof childContext[k] !== 'undefined') {
      context[k] = childContext[k];
    }
  }
  return context;
};

const RowLayout = styled.div(rowStyles);

export const Row = ({children, className, fluid, gutters, balancedGutters, vcenter}: RowProps): React.MixedElement => {
  if ( typeof gutters === 'undefined' && typeof balancedGutters === 'undefined') {
    return (
      <RowLayout className={className} fluid={fluid} vcenter={vcenter}>
        {children}
      </RowLayout>
    );
  }

  return (
    <GridContext.Consumer>
      {(context) => (
        <GridContext.Provider value={mergeContext(context, { gutters, balancedGutters })}>
          <RowLayout className={className} fluid={fluid} vcenter={vcenter}>
            {children}
          </RowLayout>
        </GridContext.Provider>
      )}
    </GridContext.Consumer>
  );
};
