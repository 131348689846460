//@flow
import React from "react";
import type { Node } from "react";

import { Column, Row, Section } from "../../utils/layout/grid";
import { ImageCaption, LogoImage } from "../../components/ui";
import LayoutCaseStudy from '../../components/layouts/layout-case-study';
import { useCaseStudiesIndex } from '../../utils/graphql/case_studies';
import Emoji from 'react-emoji-render';
import CompareImage from '../../components/ui/images/compare-image';

const Nova = (): Node => {

  let caseStudy = useCaseStudiesIndex('nova');

  return (
    <LayoutCaseStudy caseStudy={caseStudy} heroUrl='case-studies/nova/hero.png'>
      <Section>
        <Row>
          <Column readable>
            <h2>From Concept to Implementation—Seamlessly </h2>
            <p>The marketing team at Nova Credit came to Lightboard to create illustrations for their customer onboarding workflow. While Nova already had an established brand, they wanted to add another level of polish with custom illustrations. We were happy to help.</p>
          </Column>
        </Row>
      </Section>

      <Section padded>
        <Row>
          <Column readable>
            <LogoImage className='footroom-half' src="logos/nova.png" width={160}/>
            <p> Starting as a graduate research project at Stanford University, Nova has evolved into the premier cross-border credit reporting agency. Nova Credit uses world-class technology to enable immigrants to transport their credit history around the world. </p>

            <h2 className='headroom'>Project Scope</h2>
            <p> Nova wanted friendly, people-focused illustrations to add personality to their website. Since web development and branding was already underway, it was crucial that the new illustrations were compatible with the already-built pages. Our team of creative services managers and illustrators worked hand-in-hand with Nova's marketing and development team to ensure our deliverables would be drop-in replacements for the placeholders on the website.</p>

            <h2 className='headroom'>First step: Concept Sketches</h2>
            <p>The first step for this project was to establish the illustration style and color palette.</p>

            <p>We collected reference styles to make sure we were aligned with Nova's goals, and rough sketched the scenes for concept approval.</p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <ImageCaption src='case-studies/nova/illo-sketches.png'/>
            <ImageCaption src="case-studies/nova/cute-div.png" />
          </Column>
        </Row>
      </Section>
    <Section padded>
      <Row>
        <Column readable>
          <h2>Spot illustrations </h2>
          <p> After confirming the direction based on the sketches and color palette, we started with small spot illustrations to dial in the look and feel.</p>
        </Column>
      </Row>
    </Section>
    <Section>
        <Row fluid>
          <ImageCaption size={6} src='case-studies/nova/illo-finals.png'/>
          <ImageCaption size={6} src='case-studies/nova/illo-finals-2.png'/>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column readable>
            <h2>Scenes</h2>
            <p>After iterating on the look and feel with heads nodding on both side, we moved on to the larger, more complicated scenes required for the onboarding flow. These scenes usually had several characters interacting with super-sized web form elements to represent the different stages of onboarding (like signing in, finding a record, or building your credit score).</p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/nova/feature-1.png'/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid>
          <ImageCaption size={6} src='case-studies/nova/foursquare-full-width-1.png'/>
          <ImageCaption size={6} src='case-studies/nova/foursquare-full-width-2.png'/>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column readable>
            <h2>Handoff</h2>
            <p> With the illustrations complete, we worked with Nova to deliver web-ready assets that their development team was able to immediately implement.</p>
            <p><span>We think the result is</span><Emoji text="💯" /></p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/nova/overview.png'/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/nova/site-highlight-illo.png'/>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column readable>
            <h2>
              <Emoji text="✨"/>
              <span>Bonus! Before/After</span>
              <Emoji text="✨" />
            </h2>
            <p>We lined up the sketches with the final art to show how we progressed from first round to final art. Drag the slider from left to right to see the transformation.</p>
          </Column>
        </Row>
      </Section>
      <Section>
          <Row>
            <Column>
              <CompareImage fluid left="case-studies/nova/illo-sketches.png" right="case-studies/nova/illo-finals.png"/>
            </Column>
        </Row>
      </Section>
    </LayoutCaseStudy>
  );
};
export default Nova;
