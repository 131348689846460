//@flow
import { useStaticQuery, graphql } from 'gatsby';
import type { PortfolioSet, PortfolioAsset } from '../types/index';
let portfolioSets: PortfolioSet[];
let portfolioSetsById: {[key: string]: PortfolioSet};
let portfolioImages: {[key: string]: {[key: string]: PortfolioAsset}};

export const portfolioFragment: any = graphql`
  fragment portfolioSet on PortfolioJson {
    title
    description
    heroUrl
    key
    extension
    images {
      id
      bleed
      caseStudy
      description
      featured
      extension
      size
      title
    }
  }
`;


function setup() {
  const { allPortfolioJson } = useStaticQuery(
    graphql`
      query {
        allPortfolioJson (sort: {fields:priority}) {
          edges {
            node {
              ...portfolioSet
            }
          }
        }
      }
    `
  );
  if (!portfolioSets) {
    portfolioSets = [];
    portfolioSetsById = {};
    portfolioImages = {};
    allPortfolioJson.edges.forEach(({node}) => {
      let imageSet = buildImageSet(node);
      let set = {images: imageSet, ...node};
      portfolioSets.push(set);
      portfolioSetsById[node.key] = set;
      portfolioImages[set.key] = imageSet;
    });
  }
}

function decorateImage(set: PortfolioSet, image: PortfolioAsset):PortfolioAsset {
  image.url = `/portfolio/${set.key}/${image.id}`;
  image.src = `portfolio/${set.key}/${image.id}.${image.extension || set.extension}`;
  return image;
}

function buildImageSet(set: PortfolioSet) {
  portfolioImages[set.key] = {};
  let imageSet: {[key: string]: PortfolioAsset} = {};
  set.images.forEach((image: PortfolioAsset) => imageSet[image.id] = decorateImage(set, image));
  return imageSet;
}

export const getPortfolioSets = ():PortfolioSet[] => {
  if (!portfolioSets) setup();
  return portfolioSets;
};

export const getPortfolioSet = (setId:string): PortfolioSet => {
  if (!portfolioSetsById) setup();
  return portfolioSetsById[setId];
};

export const getPortfolioImage = (setId:string, imageId:string): PortfolioAsset => {
  if (!portfolioImages) setup();
  return portfolioImages[setId][imageId];
};

