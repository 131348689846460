//@flow
import React from "react";
import type { Node } from "react";

import Layout from "../../components/layouts/layout-basic";
import { Column, Row, Section } from "../../utils/layout/grid";

import styled from '@emotion/styled';
import ContactForm from "../../components/forms/contact";

//TODO: This page is WIP
const ProjectIndex = (): Node => {
  const MotionWrapper = styled.div`
    background-color: #172334;
    color: #FFFFFF`;


  const MotionSection = ({backgroundColor="#172334", color="#FFFFFF", children}) => {
    return <div style={{backgroundColor, color}}>
      <Section padded>
        {children}
      </Section>
    </div>;
  };

  const P = styled.p`
    font-size: 24px;`;

  const H1 = styled.h1`
    font-family: playfair-display, serif;
    font-weight: 700;
    font-style: normal;
    font-size: 72px;`;

  return (
    <Layout pageTitle="What we can do: Lightboard Project Types" pageDescription="See the types of projects Lightboard can help you design." solidMenu>
      <MotionSection backgroundColor="#192332" color="#FFFFFF">
        <Row fluid>
          <Column textCenter>
            <video autoPlay playsInline loop muted style={{"maxWidth": "1920px", "width": "90vw"}}>
              <source src="/portfolio/motion/lightboard-motion-portfolio.mp4"  type='video/mp4' />
            </video>
            <P>Recent motion work from our team.</P>
          </Column>
        </Row>
      </MotionSection>
        <MotionSection backgroundColor="#192332" color="#FFFFFF">
          <Row>
            <Column textCenter>
              <div className='spaced-2x'>
                <H1>We're here to get you moving.</H1>
                <P>Whatever you're making, motion can help you stand out in a crowd.</P>
              </div>
          </Column>
          </Row>
      </MotionSection>
      <MotionSection backgroundColor="#0475DB" color="#FFFFFF">
        <Row>
          <Column>
            <H1>How we help</H1>
            <P>We work with you from concept to final cut to produce your video.</P>
          </Column>
        </Row>
      </MotionSection>
      <MotionSection backgroundColor="#0475DB" color="#FFFFFF">
        <Row>
          <Column>
            <H1>Customer Showcase</H1>
            <H1>Bugsnag</H1>
            <P>We work with you from concept to final cut to produce your video.</P>
          </Column>
        </Row>
      </MotionSection>
      <MotionSection backgroundColor="#192332" color="#FFFFFF">
        <Row>
          <Column>
            <ContactForm formLocation="motion-page"/>
          </Column>
        </Row>
      </MotionSection>
    </Layout>
  );
};

export default ProjectIndex;
