//@flow
import React from "react";
import type { Node } from "react";


import { Column, Readable, Row, Section } from "../../utils/layout/grid";
import { PullQuote, ImageCaption, LogoImage } from "../../components/ui";
import KeyStats from "../../components/pages/case-studies/stat-boxes";

import AnimatedWaffleBot from './waffle/wafflebot-animated.gif';
import { useCaseStudiesIndex } from '../../utils/graphql/case_studies';
import LayoutCaseStudy from '../../components/layouts/layout-case-study';

const Waffle = (): Node => {

  let caseStudy = useCaseStudiesIndex('waffle');

  return (
    <LayoutCaseStudy caseStudy={caseStudy} heroUrl='case-studies/waffle/hero.png'>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h1>Streamlined Design</h1>
              <p>
                Megan Espeland, Head of Marketing at Waffle.io, relies on Lightboard to execute Waffle.io's everyday marketing designs, from landing page A/B tests to promotional stickers.
              </p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <PullQuote src='case-studies/waffle/megan-espeland.jpg' name='Megan Espeland' title='Head of Marketing'>
                <p>
                  Lightboard allows me to run my piece of the business much more quickly, efficiently, and  beautifully than if I had to use a freelancer or do it myself.
                </p>
              </PullQuote>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <LogoImage src='logos/waffle.png' alt="Waffle logo" width={300} />
              <p>Waffle.io is project management built for software developers, powered by GitHub issues and pull requests. Their goal is to make the lives of software developers better, starting with reinventing project management so it serves the needs of developers first. Waffle.io is run as a Lean Startup inside of the CA Technologies Accelerator.</p>
              <h2>Business Situation</h2>
              <p>Waffle.io has one designer on staff, who focuses on UX and engineering for the Waffle.io app. She didn't have time for marketing design, and Megan tried to find freelancers to fill that void—but she struggled to find someone who was fast, dependable, and didn't require constant management.</p>
              <h2>Discovery</h2>
              <p>Megan learned about Lightboard through a recommendation from the Techstars network. At the time she urgently needed landing page A/B tests created and, tired of spending so much of her bandwidth managing freelancers, Lightboard seemed like the perfect solution. Megan decided to give Lightboard a try and was immediately impressed that Lightboard was able to provide an estimate and start a designer that same day.</p>
              <p>As she moved forward with the project, she fell in love with Lightboard's user-friendly collaboration platform and email notifications, and how much time it saved her to have everything in one place.</p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid className='padded-y'>
          <Column>
            <ImageCaption src='case-studies/waffle/waffle-landing-pages.jpg'/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h2>Path to Success</h2>
              <p>After the initial landing page test projects, Megan started to regularly use Lightboard for Waffle's everyday marketing design needs—from big, high value projects to production-oriented small ones. Megan loves that Lightboard is available no matter the project scope, because it means that any marketing idea she dreams up can be beautifully executed.</p>
              <ImageCaption src='case-studies/waffle/waffle-display-ads.png'>
                Display ads.
              </ImageCaption>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h2>Benefits</h2>
              <h4>Significant Time Savings</h4>
              <p>Before discovering Lightboard, Megan was frustrated by how much time she spent managing and following up with design freelancers. With Lightboard's online system she can submit a project with a deadline, approve an estimate, and then move on to other strategic priorities with the confidence that her Lightboard account manager will notify her when her designs are ready. (Not to mention Lightboard delivers 2-3x faster than the freelancers and agencies she'd used in past!)</p>
              <h4>Project Example: WaffleBot—from waffle iron to robot arm</h4>
              <p>With GitHub's annual Universe conference looming, Megan quickly needed a mascot for Waffle's new product feature: a bot that automatically updates GitHub based on activity within Waffle's app. Naturally, the bot would be called WaffleBot—and Megan turned to Lightboard's illustrators to bring it to life.</p>
            </Readable>
            <ImageCaption src='case-studies/waffle/wafflebot-sketches.png'>
              First round sketches for the WaffleBot
            </ImageCaption>
            <Readable>
              <p>Working quickly, Megan and Lightboard brainstormed ideas for the WaffleBot—from an anthropomorphic waffle iron to a waffle-based pirate, before settling on the final result, a robotic arm. The project took three days from start to finish, and resulted in this delightful animation:</p>
              <div className='text-center'>
                <img src={AnimatedWaffleBot} alt='Animated Wafflebot' />
              </div>
            </Readable>
            <Readable>
              <p>Read more about the WaffleBot design process in <a href='https://lightboard.com/blog/wafflebot-mascot-illustration/'>our blog post</a>.</p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column>
            <Readable>
              <h4>Easy to Onboard—and Use</h4>
              <p>Megan loves Lightboard's software, from the project dashboard and collaboration platform to the automatic email notifications when an estimate or design is ready for review. It's fast, efficient, and super easy for her to use—which means less project management work for Megan, and more time to market Waffle.</p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <PullQuote src='case-studies/waffle/megan-espeland.jpg' name='Megan Espeland' title='Head of Marketing'>
                <p>
                  Do yourself a favor. Make your life easier. Work with Lightboard.
                </p>
              </PullQuote>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h2>More Marketing Throughput</h2>
              <p>In the past, Waffle had to forgo some of their big marketing ideas simply because they didn't have anyone to handle the execution. Now Megan is confident that she can bring to life any marketing project she dreams up because no matter what design she needs done, she knows Lightboard will deliver.</p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row className='headroom'>
          <Column>
            <KeyStats stats={caseStudy.stats} />
          </Column>
        </Row>
      </Section>
    </LayoutCaseStudy>
  );
};

export default Waffle;

