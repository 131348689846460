//@flow
import * as React from "react";
import { Row, Column, Section } from '../../../utils/layout/grid';
import { useCaseStudies } from '../../../utils/graphql/case_studies';
import { PrimaryButton } from '../../forms/buttons/index';
import { LogoImage } from '../../ui';
import { LinkedPanel } from '../../containers/panel';

const CaseStudyLinks = (): React.Node => {
  let caseStudies = useCaseStudies();
  return (
    <Section className='spaced-3x' inset padded>
      <Row>
        <Column textCenter>
          <div className='footroom'>
            <h2>Case Studies</h2>
            <p>See how we've worked with some of our great customers.</p>
          </div>
        </Column>
      </Row>
      <Row balancedGutters gutters>
        {caseStudies.map(caseStudy => {
          if (caseStudy.hidden) return;
          return (<Column size={4} key={caseStudy.slug} textCenter>
            <LinkedPanel to={`/case-studies/${caseStudy.slug}`} height={300}>
              <LogoImage src={`logos/${caseStudy.slug}.png`} width={200} style={{'margin':'auto'}}/>
              <div className='spaced-half'>
                <p>{caseStudy.shortText}</p>
                <div className='spaced-half'>
                  <PrimaryButton>View</PrimaryButton>
                </div>
              </div>
            </LinkedPanel>
          </Column>);
        })}
      </Row>
    </Section>
  );
};

export default CaseStudyLinks;
