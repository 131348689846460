//@flow
import * as React from "react";
import { css } from '@emotion/react';
import sizes from '../../utils/config/breakpoints.json';

export const HeroBlurb = ({children}: {children: React.Node}):React.Node => {
  let heroBlurb = css`
    background-color: rgba(0,0,0,0);
    margin-left: 4rem;
    @media (max-width: ${sizes.breakpointMobile}) {
      margin: auto;
      h1 {
        font-size: 2.8rem;
      }
    }
  `;

  return (
    <div css={heroBlurb}>
      {children}
    </div>
  );
};


type Props = {
  children?: React.Node,
  backgroundColor?: string,
  bodyColor?: string,
  patchColor?: string,
  noTail?: boolean,
  heroBars?: any
}

export const HeroAngle = ({
  children,
  backgroundColor='#6fc1fc',
  bodyColor='#FFFFFF',
  patchColor,
  noTail,
  heroBars
}: Props):React.Node => {

  let HeroBars;

  if (heroBars) {
    HeroBars = heroBars;
  }

  let angle = '3deg';

  let heroAngle  = css`
    min-height: 600px;
    position: relative;
    overflow: inherit;
    margin-bottom: 12vh;
    padding: 10rem 0;
    @media (max-width: ${sizes.breakpointMobile}) {
      text-align: center;
    }
  `;

  let heroContent = css`
    position: relative;
    z-index: 103;
  `;

  let angleCss = css`
    background-color: ${backgroundColor};
    color: ${bodyColor};
    a, a:hover {
      color: ${bodyColor};
      border-bottom-color: ${bodyColor};
    }
    ${noTail ? 'margin-bottom: 0;' : ''}
  `;

  let heroTailCss = css`
    position: absolute;
    left: 0;
    right: 0;
    bottom: -10rem;
    height: 20rem;
    z-index: 101;
    transform: skew(0, ${angle});
  `;

  let heroPatchCss = css`
    position: absolute;
    left: 0;
    right: 0;
    bottom: -12vh;
    height: 12vh;
    z-index: 100;
  `;

  let tailCss = css`${heroBars ? 'height: 125%; overflow: hidden;' : ''}`;

  return (
    <div css={[heroAngle, angleCss]}>
      <div css={heroContent}>
        {children}
      </div>
      {!noTail && <div css={[heroTailCss, angleCss,tailCss]}>
        {HeroBars && <HeroBars />}
      </div>}
      {patchColor && <div css={heroPatchCss} style={{'backgroundColor':patchColor}}/>}
    </div>
  );
};
