//@flow
import type { Node } from "react";
import React from "react";

import { Column, Row, Section } from "../../utils/layout/grid";
import { getPortfolioSets } from '../../utils/graphql/portfolio';
import type { PortfolioSet } from '../../utils/types';
import { FluidImage } from '../../components/ui/images/fluid';

import PortfolioMenu from '../../components/pages/portfolio/portfolio-menu';
import PortfolioGallery from '../../components/pages/portfolio/portfolio-gallery';
import LayoutPortfolio from '../../components/layouts/layout-portfolio';
import { Link } from "gatsby";

const PortfolioIndex = ({location}: {location:Object}): Node => {
  let portfolioSets: PortfolioSet[] = getPortfolioSets();
  return (
    <LayoutPortfolio heroUrl="tiles/seamless-white.png" onViewEvent="portfolio-home" pageTitle="Marketing Design Portfolio" pageDescription="Selections of our best work from our thousands of projects for B2B marketing teams." solidMenu>
      <Section>
        <Row>
          <Column readable textCenter>
            <div className='spaced'>
              <FluidImage src="portfolio/heroes/branding.png"/>
              <h1>Our Work</h1>
              <p>We've delivered thousands of projects for marketing teams across the country. From branding to websites to collateral—it's hard to choose our favorite work.</p>
              <p>Don't see what you need? We can probably help—<Link to="/contact">contact us</Link>.</p>
            </div>
          </Column>
        </Row>
      </Section>
      <Section spaced>
        <Row>
          <Column>
            <PortfolioMenu location={location}/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row vcenter>
          <Column>
            {
              portfolioSets.map(set => <PortfolioGallery set={set} key={set.title} featured limit={6} columnSize={6} location={location}/>)
              }
          </Column>
        </Row>
      </Section>
    </LayoutPortfolio>
  );
};

export default PortfolioIndex;
