
//@flow
import type { Node } from 'react';
import React from "react";
import CaseStudyLinks from './case-studies-links';
import PortfolioCallout from '../shared/portfolio-callout';
import StartCta from '../shared/start-cta';

export const CaseStudyFooter = (): Node => {
  return (
    <>
      <CaseStudyLinks/>
      <PortfolioCallout/>
      <StartCta/>
    </>
  );
};
