//@flow
import type { Node } from "react";
import React from "react";
import { Column, Row, Section } from "../../utils/layout/grid";
import { LogoImage, ImageCaption, PullQuote, } from "../../components/ui";
import LayoutCaseStudy from '../../components/layouts/layout-case-study';
import { useCaseStudiesIndex } from '../../utils/graphql/case_studies';
import CompareImage from "../../components/ui/images/compare-image";

const AreaMetrics =  (): Node => {

  let caseStudy = useCaseStudiesIndex('areametrics');

  return (
    <LayoutCaseStudy caseStudy={caseStudy} heroUrl='case-studies/areametrics/hero.png'>
      <Section>
        <Row>
          <Column readable>
            <h1>Best foot forward</h1>
            <p> Brandon and Carey Bennett, Co-Founders of AreaMetrics, used Lightboard to revamp their website and create a cohesive look for decks and other client materials.  </p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <PullQuote src="case-studies/areametrics/carey.png" name="Carey Bennett" title="Co-Founder and Chief Operating Officer">
              <p>Lightboard was like-minded with regard to AreaMetrics' startup culture&mdash;they worked fast and were agile.</p>
            </PullQuote>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <LogoImage src="logos/areametrics.png" width={180} className='footroom-half'/>
              <p>AreaMetrics provides geo-location data that powers insights for marketers and brands. Located in Seattle, WA, they access a network of in-store sensors to understand offline foot-traffic to deliver the highest quality in alternative data intelligence.</p>
              <h2 className='headroom'>Business Situation</h2>
              <p>AreaMetrics has business partners and clients across the world. Without face-to-face interactions it is imperative that the AreaMetrics website relays the company's brand and values cleanly and clearly.</p>
              <p>As a startup team of six, AreaMetrics didn't have the bandwidth to revamp their website themselves. Once the AreaMetrics team decided to revamp the company's website in February of 2018, they immediately reached out to the Lightboard team. </p>
              <p>Lightboard gathered information about the brand's goals, mission, and values, and presented AreaMetrics with three brand direction options.</p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid gutters>
          <ImageCaption compact size={4} src='case-studies/areametrics/brand-option1.png'/>
          <ImageCaption compact size={4} src='case-studies/areametrics/brand-option2.png'>
            Early design direction options.
          </ImageCaption>
          <ImageCaption compact size={4} src='case-studies/areametrics/brand-option3.png'/>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>Path to Success</h2>
            <p>Once a brand direction was established, Lightboard began applying the design to the new AreaMetrics website. After the first iteration of home page designs were revealed, they were sold. </p>
            <p>Lightboard quickly got to work on creating illustrations to showcase the AreaMetrics product. Then, utilizing those illustrations, the remaining pages were built out. Using the Lightboard app, Carey was able to easily relay comments and tweaks to each website page as it was completed to make sure that the artwork and style reflected AreaMetrics' mission and values.  </p>
          </Column>
        </Row>
      </Section>

      <Section padded>
        <Row>
          <Column textCenter>
            <h2 className='spaced'>AreaMetrics' website before and after Lightboard's redesign.</h2>
          </Column>
        </Row>
        <Row>
          <Column size={6}>
            <h4>Before</h4>
          </Column>
          <Column size={6}>
            <h4>After</h4>
          </Column>
        </Row>
        <Row>
          <Column>
            <CompareImage fluid left="case-studies/areametrics/overview-a.png" right="case-studies/areametrics/overview-b.png"/>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid gutters>
          <Column textCenter>
            <h2>A complete build-out of the website</h2>
            <p>Illustration and layout for each of the key pages.</p>
            <ImageCaption src='case-studies/areametrics/site.png'/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>Benefits</h2>
            <h4> Startup Mentality</h4>
            <p>Both Areametrics and Lightboard have roots in the Seattle start-up community. Lightboard was like-minded with regard to AreaMetrics' startup culture - they worked fast and were agile. When a new business plan opportunity opened up during the production stage, Lightboard was quick to add additional content to reflect these changes.</p>
            <h4>Design Partnership</h4>
            <p> AreaMetrics met regularly with the Lightboard design team to hash out ideas and to go over Lightboard's progress. The constant contact between teams was crucial in producing content accurately and efficiently.</p>
            <h4>Polished Product</h4>
            <p>In just three months, Lightboard was able to deliver an illustration packed website that injected new life and energy into the AreaMetrics brand. The ability to work on illustrations and web design in tandem allowed for a quick, polished turnaround on the website.   </p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid gutters vcenter>
          <ImageCaption compact size={4} src='case-studies/areametrics/illustrations-process-op2.png'/>
          <ImageCaption compact size={8} src='case-studies/areametrics/illustrations-process.png'/>
         </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h4>Visuals for a new brand </h4>
            <p>The illustrations gave AreaMetrics a new personality that will live on as the brand continues to grow. The new website gave AreaMetrics a competitive edge and positions them as a leader in the geo-location data space.</p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid gutters>
          <ImageCaption compact size={3} src='case-studies/areametrics/spot-feature-a.png'/>
          <ImageCaption compact size={3} src='case-studies/areametrics/spot-feature-b.png'/>
          <ImageCaption compact size={3} src='case-studies/areametrics/spot-feature-c.png'/>
          <ImageCaption compact size={3} src='case-studies/areametrics/spot-feature-d.png'/>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <PullQuote src='case-studies/areametrics/carey.png' name="Carey Bennett" title="Co-Founder and Chief Operating Officer">
              <p>Since launching the new website, many new clients have commented on the design and flow of the website. Lightboard played a key role in the strategic direction.</p>
            </PullQuote>
          </Column>
        </Row>
      </Section>
      <Section spaced>
        <Row fluid gutters>
          <ImageCaption compact size={12} src='case-studies/areametrics/site-highlight-contact.png'/>
        </Row>
      </Section>
    </LayoutCaseStudy>
   );
};

export default AreaMetrics;
