// @flow
import * as React from 'react';
import { FaLongArrowAltRight } from 'react-icons/fa';

import { GatsbyImage } from "gatsby-plugin-image";
import { ColumnMin, ColumnMax, Row } from '../../../utils/layout/grid';

import { getRawImage } from '../images/fluid';
import { css } from '@emotion/react';
import Color from 'color';

import Fonts from '../../../utils/config/fonts';
import Sizes from '../../../utils/config/breakpoints';
import Colors from '../../../utils/config/colors';

type Props = {
  inline?: boolean,
  name: string,
  title?: string,
  company?: string,
  src?: string,
  headshot?: Object,
  headshotFluid?: Object,
  readMore?: boolean,
  children: React.Node
};

const PullQuote = ({ name, title, company, headshot, src, headshotFluid, inline, readMore, children }: Props): React.Node => {

  const quoteWrapper = css`
    margin: 4rem auto 4rem -6rem;
    figure {
      font-family: ${Fonts.quoteFont};
      font-size: 3rem;
    }
    figcaption {
      font-family: ${Fonts.bodyFont};
      width: 66%;
      margin: 1rem 0 0 33%;
      padding: 1rem 0 0 4rem;
      border-top: dotted 1px ${ Color(Colors.bodyFont).lighten(0.6).hex()};
      p {
        font-size: 1.4rem;
        line-height: 1.2rem;
      }
      small {
        font-size: 0.8rem;
      }
      @media (max-width: ${Sizes.mobile}) {
        margin: 2rem 2rem;
        figure {
          margin: 4rem auto;
          font-size: 2.2rem;
        }
        figcaption {
          float: none;
          margin: 1rem 0;
          width: 100%;
        }
      }
  `;

  const quoteInline = css`
    margin-left: 0;
  `;

  const headshotCss = css`
    height: 64px;
    width: 64px;
    margin: 0;
    border-radius: 20rem;
  `;

  if (src) headshotFluid = getRawImage(src);

  return (
    <div css={inline ? [quoteWrapper, quoteInline] : [quoteWrapper]}>
      <figure>
        {children}
        <figcaption>
          <Row vcenter>
            {headshotFluid && <ColumnMin>
              <GatsbyImage image={headshotFluid} css={headshotCss} alt={name} />
            </ColumnMin>}
            {headshot && <ColumnMin>
              <GatsbyImage image={headshot} css={headshotCss} alt={name} />
            </ColumnMin>}
            <ColumnMax>
              <p>
                {name}
                {company && <span><br/><small>{company}</small></span>}
                {title && <span><br/><small>{title}</small></span>}
              </p>
            </ColumnMax>
            {readMore && <ColumnMin>
              <button className='spaced'>Read more <FaLongArrowAltRight/></button>
              </ColumnMin>}

          </Row>
        </figcaption>
      </figure>
    </div>
  );
};

export default PullQuote;
