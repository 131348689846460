//@flow
import React from "react";
import type { Node } from "react";

import { withPrefix, Link } from 'gatsby';

import { Column, Row, Section } from "../../utils/layout/grid";
import { ImageCaption, LogoImage, FluidVideo, PullQuote, VideoCaption } from "../../components/ui";
import LayoutCaseStudy from '../../components/layouts/layout-case-study';
import { useCaseStudiesIndex } from '../../utils/graphql/case_studies';

const RedshiftMotion =  (): Node => {

  const caseStudy = useCaseStudiesIndex('redshift');

  return (
    <LayoutCaseStudy caseStudy={caseStudy} heroUrl="case-studies/redshift-motion-graphics/hero.png">
      <Section>
        <Row>
          <Column readable>
            <h1>Motion Graphics</h1>
            Autodesk's Redshift blog partners with Lightboard to produce a <a href="https://www.autodesk.com/redshift/category/respect/"> series of videos</a> that celebrate the work of visionaries in the fields of architecture, engineering, construction and design.
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <PullQuote src="case-studies/redshift-motion-graphics/shveta.jpg" name="Shveta Berry" title="Content Marketing Manager">
              <p>The videos bring to life really abstract ideas, which is tough to do. They allow us to tackle concepts that would otherwise be really hard to show.</p>
            </PullQuote>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <LogoImage className='footroom-half' src="logos/redshift.png" width={300}/>
            <p>Redshift explores the expanding forefront of architecture, construction, infrastructure, and manufacturing technology, focusing on the way the very nature of design is shifting. To give their content a distinctive and recognizable visual feel, Redshift worked with Lightboard to create needed a unique illustration style.</p>
            <p>Redshift is supported by Autodesk, creator of software including AutoCAD, Revit, Maya, 3ds Max, Fusion 360, SketchBook, and more.</p><br />
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>Respect Series</h2>
            <p>The folks at Redshift was looking for a way to breathe new life into their series of biographical articles, <a href="https://www.autodesk.com/redshift/category/respect/"> Respect</a>.</p>
            <p>We worked with them to create short, animated pieces to go along with the articles. Based on the script and the art direction provided, we created these videos that feature simple animation and "chompable text bites." </p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column readable>
            <h2>Storyboarding</h2>
            <p>To kick off each project, Redshift supplies us with storyboards and a script for each video. We first collect imagery, then hit the ground running with animation.  </p>
          </Column>
        </Row>
        <Row>
          <Column>
            <ImageCaption src='case-studies/redshift-motion-graphics/inspiration-2up.png'>
            A moodboard for one of the videos
            </ImageCaption>
          </Column>
        </Row>
        <Row>
          <Column>
            <ImageCaption src='case-studies/redshift-motion-graphics/storyboard-2up.png'>
            A storyboard for one of the videos
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>Scene by Scene</h2>
            <p>After storyboarding, we begin to source images and create the frames that will make up the final video. The Redshift and Lightboard teams can collaborate scene by scene through the Lightboard app to refine each frame until it's perfect.</p>
          </Column>
        </Row>
        <Row fluid >
          <Column>
            <ImageCaption src='case-studies/redshift-motion-graphics/transition-highlight-full-w.png'/>
          </Column>
        </Row>
      </Section>
      <Section>
      <Row>
          <Column readable>
            <h2>Animation</h2>
            <p>Once each scene is ready to go, we begin animating. Each video is about two minutes long and must do justice to the incredible work each visionary created in their lifetimes. </p>

            <p>Once the videos are complete, we provide Redshift with the accompanying clips and social images they need to publicize the videos.</p>

            <p>We love partnering with the awesome folks at Redshift to work on these video projects! We think that an awesome partnership produces great work. Check it out:</p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column>
            <FluidVideo controls poster={withPrefix("/videos/case-studies/redshift-motion-graphics/poster-1.png")} mp4={withPrefix("videos/case-studies/redshift-motion-graphics/video-edison.mp4")} />
              <p><a href="https://www.autodesk.com/redshift/thomas-edison-accomplishments/"> Respect: Thomas Edison</a></p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column>
            <FluidVideo controls poster={withPrefix("/videos/case-studies/redshift-motion-graphics/poster-2.png")} mp4={withPrefix("videos/case-studies/redshift-motion-graphics/video-gilbreth.mp4")} />
             <p><a href="https://www.autodesk.com/redshift/lillian-gilbreth/">Respect: Lillian Gilbreth</a></p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column>
            <FluidVideo controls poster={withPrefix("/videos/case-studies/redshift-motion-graphics/poster-4.png")} mp4={withPrefix("videos/case-studies/redshift-motion-graphics/video-franklloydwright.mp4")} />
             <p><a href="https://www.autodesk.com/redshift/frank-lloyd-wright-designs/">Respect: Frank Lloyd Wright</a></p>
          </Column>
        </Row>
      </Section>
      <Section spaced>
        <Row>
          <Column>
            <h1>Supporting Illustration</h1>
            <VideoCaption mp4="/portfolio/motion/ad-circularity.mp4" autoPlay={false} controls={true}>
              <p>Circularity. Illustration</p>
            </VideoCaption>
            <VideoCaption mp4="/portfolio/motion/ad-construction.mp4" autoPlay={false} controls={true}>
              <p>Construction. Illustration</p>
            </VideoCaption>
            <VideoCaption mp4="/portfolio/motion/ad-generative-design.mp4" autoPlay={false} controls={true}>
              <p>Generative Design. Illustration</p>
            </VideoCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>Continued Collaboration</h2>
            <p>These animation projects are no small undertaking! With so many people working together, the Lightboard app is key in keeping things on track. 💯</p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <PullQuote src="case-studies/redshift-motion-graphics/shveta.jpg" name="Shveta Berry" title="Content Marketing Manager">
              <p>I like that the whole team is on the app. It makes it really easy when you have a project with a lot of moving parts... which we always do!</p>
            </PullQuote>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>A Great Partnership</h2>
            <p>We love working with the team at Redshift. Take a look at our <Link to="/case-studies/redshift">editorial illustrations for Redshift </Link> and our work with the <Link to="/case-studies/autocad">AutoCAD team</Link>.</p>
          </Column>
        </Row>
      </Section>
    </LayoutCaseStudy>
  );
};

export default RedshiftMotion;
