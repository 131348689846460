//@flow
import * as React from "react";

import Wrapper from '../../utils/track/wrapper';
import Tracking from '../../utils/track';
import Meta from './meta';
import Nav from './nav';
import Footer from './footer';

export interface LayoutProps {
  children: React.Node,
  disableRetarget?: boolean,
  solidMenu?: boolean,
  status?: number,
  pageDescription?: string,
  pageShareImage?: string,
  pageTitle?: string,
  onViewEvent?: string,
}

const LayoutBasic = function ({
    children,
    disableRetarget = false,
    solidMenu = false,
    status,
    pageDescription,
    pageShareImage,
    pageTitle,
    onViewEvent
  }: LayoutProps) : React.Node {

  return (
    <Wrapper status={status} disableRetarget={disableRetarget} >
      <Meta pageTitle={pageTitle} pageDescription={pageDescription} pageShareImage={pageShareImage}/>
      <Nav solid={solidMenu}/>
      {children}
      <Footer />
      <Tracking onViewEvent={onViewEvent}/>
    </Wrapper>
  );
};

export default LayoutBasic;
