
//@flow
import type { Node } from "react";
import React from "react";
import PortfolioCallout from '../shared/portfolio-callout';
import StartCta from '../shared/start-cta';
import ProjectTypes from './project-types';

export const CommonCtas = (): Node => {
  return (
    <>
      <PortfolioCallout/>
      <ProjectTypes/>
      <StartCta/>
    </>
  );
};
