//@flow
import * as React from "react";
import { Link } from 'gatsby';
import { FaLongArrowAltRight } from 'react-icons/fa';

import { Column, Row, Section } from "../../../utils/layout/grid";
import { AssetPreview } from './asset-preview';
import { GiantButton } from '../../forms/buttons/index';
import { css } from '@emotion/react';

export const AssetList = ({assets, limit, allUrl}: {assets: Object[], limit?: number, allUrl?: string}): React.Node => {

  function viewAll() {
    return <Column size={6} textCenter >
        <Link to={allUrl}>
          <GiantButton css={css`max-width:350px;margin:6rem auto;`}>
            View All <FaLongArrowAltRight css={css`height:2rem; width:2rem;`}/>
          </GiantButton>
        </Link>
    </Column>;
  }

  return (
    <Section gutters balancedGutters>
      <Row vcenter>
        {assets.map((asset, count) => {
          if (limit && count > (limit-1)) return;
          return (
            <Column size={6} key={asset.id}>
              <AssetPreview asset={asset}/>
            </Column>);
        })}
        {limit && limit < assets.length && viewAll()}
      </Row>
    </Section>
  );
};

