//@flow
import * as React from "react";

import { useCaseStudiesDownloads } from '../../../utils/graphql/downloads/case_studies';
import { usePresentationCoversDownloads } from '../../../utils/graphql/downloads/presentation_covers';
import { AssetList } from './asset-list';

export const CaseStudies = ({limit}: {limit?: number}): React.Node => {
  const caseStudies = useCaseStudiesDownloads();
  return <AssetList assets={caseStudies} limit={limit} allUrl='/downloads/case-study-templates'/>;
};

export const PresentationCovers = ({limit}: {limit?: number}): React.Node => {
  const presentationCovers = usePresentationCoversDownloads();
  return <AssetList assets={presentationCovers} limit={limit} allUrl='/downloads/presentation-covers'/>;
};
