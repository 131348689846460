//@flow
import React from "react";
import type { Node } from "react";

import {Row, Column, Section} from '../../utils/layout/grid';
import { useCaseStudies } from '../../utils/graphql/case_studies';
import PortfolioCallout from '../../components/pages/shared/portfolio-callout';
import ProjectTypes from '../../components/pages/shared/project-types';
import LayoutLifted from '../../components/layouts/layout-lifted';
import CaseStudyHero from '../../components/pages/case-studies/case-study-hero';
import { Link } from 'gatsby';

const CaseStudiesIndex = (): Node => {
  const caseStudies = useCaseStudies();
  const pageTitle = "How we work: Lightboard Case Studies";
  const pageDescription = "See how we've worked with some of our great customers like ShareThis, EasternBank, and Glowforge.";
  return (
    <LayoutLifted liftedHeight="-60vh" pageTitle={pageTitle} pageDescription={pageDescription} heroUrl="case-studies/usermind/hero.png" portal={{'url':'lightboard-icon.png', 'color':'#225691'}} fadeHero>
      <Section>
        <Row>
          <Column textCenter readable>
            <h1>Marketing Design Case Studies</h1>
            <p>We've worked with hundreds of marketing teams as their design partner. From branding to production design, we deliver quality work quickly and efficiently.</p>
            <p>See how we've worked with some of our great customers with these case studies, and if you'd like to know how we can help you, <Link to='/request-demo'>schedule a demo</Link>.</p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          {caseStudies.map(caseStudy => {
            if (caseStudy.hidden) return;
            return (<Column size={12} key={caseStudy.slug} textCenter>
              <CaseStudyHero caseStudyId={caseStudy.slug}/>
            </Column>);
          })}
        </Row>
      </Section>
      <PortfolioCallout/>
      <ProjectTypes/>
    </LayoutLifted>
  );
};

export default CaseStudiesIndex;
