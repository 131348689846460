//@flow
import React from "react";
import type { Node } from "react";


import { Column, Readable, Row, Section } from "../../utils/layout/grid";
import { PullQuote, ImageCaption, LogoImage } from "../../components/ui";
import KeyStats from "../../components/pages/case-studies/stat-boxes";


import { useCaseStudiesIndex } from '../../utils/graphql/case_studies';
import LayoutCaseStudy from '../../components/layouts/layout-case-study';

const ShareThis =  (): Node => {

  let caseStudy = useCaseStudiesIndex('share-this');

  return (
    <LayoutCaseStudy caseStudy={caseStudy} heroUrl='case-studies/share-this/hero.png'>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h1>An Extension of their Design Team.</h1>
              <p>
                Zoe Black, Director of Product Marketing at ShareThis, and her team use Lightboard as their in-house designer for their everyday marketing needs. Each week, Lightboard designs sales presentations, case studies, and social media, collaborating with the ShareThis team of marketing managers and designers.
              </p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <PullQuote src="case-studies/share-this/zoe-black.jpg" name='Zoe Black' title='Director of Product Marketing'>
                <p>
                  A system like Lightboard is really practical, cost effective, and drives great results.
                </p>
              </PullQuote>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <LogoImage src="logos/share-this.png" alt="ShareThis logo" width={300}/>
              <p>ShareThis is a Sharing Intelligence Company with over 60 employees, based in Palo Alto, CA. Their consumer engagement and sharing tools are used by three million publishers and brands like USA Today, ET, Ellen, and the Dallas Cowboys to drive engagement, traffic, and personalization. ShareThis collects and synthesizes social share data and makes that data more actionable for marketers and publishers by providing holistic views of their customers' most recent and relevant affinities.</p>
              <h2>Business Situation</h2>
              <p>As ShareThis scaled up, so did their everyday marketing design needs. With so many more case studies, one-sheets, and PowerPoint decks now necessary to arm their B2B sales team, Zoe and her marketing team wanted to be smart about their ROI when producing compelling sales materials.</p>
              <h2>Discovery</h2>
              <p>ShareThis discovered Lightboard through referral—a colleague who loved the Lightboard design collaboration platform. Zoe's colleague thought Lightboard would be a great partner for a company like ShareThis that struggled with a lack of internal design resources, given Lightboard is a more efficient, cost-effective solution than hiring another full-time designer or contracting a big, costly creative agency.</p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <ImageCaption src="case-studies/share-this/invitations.png" imageTitle="Logo Inspiration">
              Among the first few projects were simple event invitations.
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h2>Path to Success</h2>
              <p>ShareThis tested Lightboard with a PowerPoint design project. The process was so simple, fast, and easy—and the results so high-quality—that they quickly decided to use Lightboard for all of their everyday marketing design needs. Now Lightboard operates as their in-house marketing design team, creating and putting the finishing touches on all of their marketing collateral, from case studies to infographics, social media images to presentations. Every week, Zoe and her team run three to five projects through the Lightboard platform.</p>
            </Readable>
            <ImageCaption src="case-studies/share-this/powerpoint-callout.png" >
              Presentation design for ShareThis.
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h2>Benefits</h2>
              <h4>Simple, Efficient & Flexible Team Technology</h4>
              <p>Through the Lightboard platform and process, ShareThis has been able to significantly increase their marketing efficiency—whittling down the design process from weeks to days.</p>
              <p>The highly collaborative platform means that ShareThis can invite internal stakeholders to participate directly in the design review and feedback process online and eliminate time-consuming emails and meetings. And ShareThis knows that a real person is always available at Lightboard should they need to talk through any particular design challenges.</p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section className='spaced'>
        <Row>
          <Column>
            <ImageCaption src="case-studies/share-this/casestudy-band.png"/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h4>Diverse Design Resources</h4>
              <p>ShareThis uses Lightboard to create high-quality design across an array of different marketing materials. With Lightboard's deep bench of diverse design talent, ShareThis knows that whatever they need Lightboard has the right people in place to generate amazing results—and they needn't over-burden their in-house design team to do it.</p>
              <p>More than ten different Lightboard designers have worked on ShareThis projects, ranging from illustrators to specialists in print layout and HTML email development. Lightboard is able to start the right designer for the project within a business day—frequently turning around the project same-day to meet ShareThis' need for timely graphics to support social media pushes.</p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <PullQuote src="case-studies/share-this/zoe-black.jpg" name='Zoe Black' title='Director of Product Marketing'>
                <p>It's important that our brand is consistent and our marketing is high quality—Lightboard ensures that every product marketing piece that we deliver looks awesome and is consistent with our brand.</p>
              </PullQuote>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h4>Maximizing Marketing ROI</h4>
              <p>Lightboard has been a cost-effective solution for ShareThis's three-person marketing team, who can now focus on their strategic priorities and leave everyday marketing design in Lightboard's hands. ShareThis has not only increased their output, but also maximizes their design budget by leveraging Lightboard for all their everyday design needs rather than contracting individual designers for different projects or hiring an additional full-time designer.</p>
              <p>Zoe and her team don't need to worry about how they'll get a design project completed—and can focus on smashing their marketing milestones for ShareThis.</p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section inset className='spaced'>
        <Row fluid>
          <Column>
            <ImageCaption src="case-studies/share-this/powerpoint-band.png"/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <KeyStats stats={caseStudy.stats} />
          </Column>
        </Row>
      </Section>
    </LayoutCaseStudy>
  );
};

export default ShareThis;
