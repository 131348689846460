// @flow
import type { Node } from 'react';
import * as React from 'react';
import type { CaseStudyStats } from '../../../utils/types/case_study';
import { css } from "@emotion/react";
import sizes from '../../../utils/config/breakpoints.json';
import colors from '../../../utils/config/colors.json';

type Props = {
  stats: CaseStudyStats[]
}

export default ({ stats }: Props): Node => {

  let statsCss = css`

    ul {
      margin: auto;
      li {
        padding: 0 2rem;
        text-align: center;
        @media(max-width: ${sizes.breakpointMobile}) {
          width: 100%;
        },
        h2 {
          border-bottom: solid 1px ${colors.darkBlue};
          padding: 0 5rem 1rem;
          margin-bottom: 1rem;
          font-size: 8rem;
        }
    }
  `;

  return (
    <div css={statsCss}>
      <h1>Key Stats</h1>
      <ul className='flex-list flex-center'>
        {stats.map(stat => (
          <li key={stat.label}>
            <h2>
              {stat.prefix && <span>{stat.prefix}</span>}
              {stat.stat}
              {stat.percent && <sup>%</sup>}
              {stat.plus && <span>+</span>}
            </h2>
            <small>{stat.label}</small>
          </li>
        ))}
      </ul>
    </div>
  );
};
