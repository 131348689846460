//@flow
import type { Node } from "react";
import React from "react";

import Layout from '../components/layouts/layout-basic';
import { BleedImage } from '../components/ui/images/fluid';
import { HeroAngle, HeroBlurb } from '../components/heroes';
import EmailForm from '../components/forms/email';

import { ExtrudedText } from '../components/ui';
import {Spread, SpreadPanel, Readable } from "../utils/layout/grid";

import colors from '../utils/config/colors.json';
import HeroBars from '../components/heroes/hero-bars';
import PortfolioCallout from '../components/pages/shared/portfolio-callout';
import HowItWorks from '../components/pages/shared/how-it-works';
import StartCta from '../components/pages/shared/start-cta';
import ClientLogos from '../components/pages/projects/common/client-logos';

import ExtrudedButton from '../components/ui/extruded-button';

const DefaultPage = (): Node => {

  let primaryHeroColor = colors.primaryHero;
  let secondaryHeroColor = colors.secondaryHero;
  let tertiaryHeroColor = colors.tertiaryHero;

  return (
    <Layout pageTitle="Lightboard | B2B marketing design on-demand | Branding, Presentations, Websites" pageDescription="Lightboard is a modern design agency for B2B marketing teams. Join the hundreds of companies working with Lightboard.">
      <HeroAngle backgroundColor={primaryHeroColor} patchColor={secondaryHeroColor} heroBars={HeroBars}>
        <HeroBlurb>
          <Readable left>
            <ExtrudedText headlineText="Hello!" extrudeColor={primaryHeroColor} />
            <h1>B2B Marketing design, on-demand.</h1>
            <p>Lightboard gives your team superpowers.</p>
            <p>Experienced designers and account managers, powered by our own world-class collaboration software—just a click away.</p>
            <div className='spaced'>
              <EmailForm formLocation="primary-hero" buttonLabel="Request Demo" />
            </div>
          </Readable>
        </HeroBlurb>
      </HeroAngle>
      <HeroAngle backgroundColor={secondaryHeroColor} patchColor={tertiaryHeroColor}>
        <Spread flopped fluid vcenter>
          <SpreadPanel>
            <Readable left>
              <ExtrudedText headlineText="How?" extrudeColor={secondaryHeroColor} />
              <h2>Driven by software.</h2>
              <p>We work as an extension of your team.</p>
              <p>Through our project management software, we collaborate with you to deliver great design on-demand.</p>
              <p>You and your team always know exactly what's going on with every project, every minute. No more guessing about deadlines and deliverables.</p>
              <p>
                <ExtrudedButton arrowIcon color='red' to="/difference">See how</ExtrudedButton>
              </p>
            </Readable>
          </SpreadPanel>
          <SpreadPanel>
            <BleedImage className='spaced' src='home/software.png' alt="Lightboard Software" height='75vh' side='left' />
          </SpreadPanel>
        </Spread>
      </HeroAngle>
      <HeroAngle backgroundColor={tertiaryHeroColor} bodyColor="#000000" noTail>
        <Spread fluid vcenter>
          <SpreadPanel>
            <Readable right>
              <ExtrudedText headlineText="Quality Design" extrudeColor={primaryHeroColor} color="#FFFFFF" />
              <h2 style={{ 'paddingTop':'20px'}}>The design you actually need.</h2>
              <p>A great presentation to help you land opportunities.</p>
              <p>Case studies to explain your business. Illustration and layout for your eBooks.</p>
              <p>Display ads, email templates, and landing pages for your campaigns.</p>
              <p>The design you need every day.</p>
              <ExtrudedButton arrowIcon color='blue' to="/portfolio">Our Portfolio</ExtrudedButton>
            </Readable>
          </SpreadPanel>
          <SpreadPanel>
            <BleedImage className='spaced' src='home/portfolio.png' alt="Lightboard Portfolio" height='75vh' side='right'/>
          </SpreadPanel>
        </Spread>
      </HeroAngle>
      <ClientLogos/>
      <PortfolioCallout/>
      <HowItWorks hideCta={true}/>
      <StartCta/>
    </Layout>);
};

export default DefaultPage;
