//@flow
import * as React from "react";
import ProjectTypes from '../shared/project-types';
import StartCta from '../shared/start-cta';
import { Section } from '../../../utils/layout/grid';

const PortfolioFooter = ():React.Node => {

  return (
    <div className='headroom'>
      <Section inset padded>
        <ProjectTypes/>
      </Section>
      <StartCta/>
    </div>
  );
};

export default PortfolioFooter;
