//@flow
import React from "react";
import type { Node } from "react";

import {  } from '../../components/ui/images/fluid';
import { Column, Readable, Row, Section } from "../../utils/layout/grid";
import { PullQuote, ImageCaption, IframeCaption, LogoImage } from "../../components/ui";

import KeyStats from "../../components/pages/case-studies/stat-boxes";
import { useCaseStudiesIndex } from '../../utils/graphql/case_studies';
import LayoutCaseStudy from '../../components/layouts/layout-case-study';

const Glowforge =  (): Node => {

  let caseStudy = useCaseStudiesIndex('glowforge');

  return (
    <LayoutCaseStudy caseStudy={caseStudy} heroUrl='case-studies/glowforge/hero.png'>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h1>Two Seattle startups working together</h1>
              <p>
                Tony Wright, founder and Head of Product at Glowforge, turned to Lightboard to design pieces for their crowdfunding video on an extremely tight turnaround. The video was a smashing success, raising nearly $30M in pre-purchases for their laser cutter.
              </p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <LogoImage src='logos/glowforge.png' alt="Glowforge logo" className="headroom" width={300}/>
              <p>Founded in Seattle in 2015, Glowforge makes an at-home laser cutter. They're doing for laser cutters what MakerBot did for 3D printing.</p>
              <p>After the biggest crowdfunding campaign in history, the first Glowforge laser cutters start shipping in the summer of 2017.</p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row className='spaced'>
          <Column>
            <IframeCaption iframeSrc='https://www.youtube.com/embed/ysCaqh38JVQ' allowFullscreen>
              This video has been viewed more than five million times and captured nearly $30 million in sales. Lightboard helped make it happen.
            </IframeCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h2>Business Situation</h2>
              <p>In 2016, Glowforge decided to make a huge push to launch at Maker Faire. They needed to make a demo video ASAP—and needed a ton of new designs to show the breadth of what their laser cutter could do.</p>
              <p>The deadline was tight—they finished the script on Monday morning and needed to shoot on Friday morning—and they needed time to actually laser cut everything.</p>
              <p>Tony called Lightboard around 11AM Monday and by end of day they had designers working on luggage tags, flasks, picture frames, and even a six-foot long papercut banner.</p>
              <p>By end of day Thursday, Lightboard had everything laser-ready—and even had time to do revisions on each to make sure the vector files would cut cleanly on the Glowforge prototype.</p>

              <PullQuote src="case-studies/glowforge/tony-wright.jpg" name='Tony Wright' title='Founder and Head of Product'>
                <p>Good design is really hard—especially in a hurry. Lightboard's designers saved our bacon. It's like having an army of great designers at your fingertips.</p>
              </PullQuote>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h2>Designs</h2>
              <h4>Birthday Party</h4>
              <p>The video has several scenes, one of which is a birthday party for a young girl, Mia. The party is meant to show off the amazing things you can make at home with the Glowforge. For this scene, we designed the hand-drawn invitation, a six-foot paper banner, and a crown.</p>
              <p>The banner was a particularly impressive—originally designed to be cut from four colors of paper, it featured custom vector illustration and set the tone for the party.</p>
            </Readable>
            <ImageCaption src="case-studies/glowforge/banner-screenshot.jpg">
              Banner in use in the video
            </ImageCaption>
            <ImageCaption src="case-studies/glowforge/banner-art.png">
              Vector file of the birthday banner
            </ImageCaption>
            <ImageCaption src="case-studies/glowforge/crown-art.png">
              Vector file of Mia's crown
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h4>Craft Sales</h4>
              <p>Another key segment for Glowforge is the small-business craftsperson—someone making goods for sale in marketplaces like Etsy.</p>
              <p>Tony wanted to show the types of pieces a crafter could make with the Glowforge, so we designed luggage tags, photoframes, journal covers—all that could be laser cut from wood or leather.</p>
            </Readable>
            <ImageCaption src="case-studies/glowforge/tags-art.png">
              Initial luggage tag designs
            </ImageCaption>
            <ImageCaption src="case-studies/glowforge/tags-wood.jpg">
              Luggage tags in lasercut from birch
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <h2>Stellar Results</h2>
              <p>There's no other way Tony could source so much design so quickly—or put them to work so effectively. And Lightboard did it without breaking a sweat.</p>
              <p>And we're delighted to have played a small part in a piece of marketing collateral that was so stunningly successful for our customer.</p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <Readable>
              <PullQuote src="case-studies/glowforge/dan-shapiro.jpg" name='Dan Shapiro' title='Founder and CEO'>
                <p>With Glowforge, people can print out remarkable objects in their living room. We used Lightboard to help inspire people by creating some of the sample designs that we use to show off the Glowforge.</p>
              </PullQuote>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <ImageCaption src="case-studies/glowforge/laptop-seal.jpg">
              We couldn't resist giving the Glowforge prototype a try ourselves—here's the Lightboard seal on one of our laptops.
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            After the launch, we also worked with Glowforge on branding their Proofgrade line of products. Unfortunately these didn't make it into production, but we're still pretty proud of them.
          </Column>
        </Row>
        <Row>
            <ImageCaption size={4} src="case-studies/glowforge/proofgrade-1.png"/>
            <ImageCaption size={4} src="case-studies/glowforge/proofgrade-2.png"/>
            <ImageCaption size={4} src="case-studies/glowforge/proofgrade-3.png"/>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <KeyStats stats={caseStudy.stats} />
          </Column>
        </Row>
      </Section>
    </LayoutCaseStudy>
  );
};

export default Glowforge;
