//@flow
import * as React from "react";
import { Helmet } from 'react-helmet';
import { css } from '@emotion/react';
import { MdCloudDownload } from 'react-icons/md';

import { Column, Row, Section } from "../../../utils/layout/grid";

import TrackedLink from '../../ui/tracked-link';
import { toSentence } from '../../formatting/index';
import { FluidImage } from '../../ui/images/fluid';
import { useSiteMetadata } from '../../../utils/graphql/site_metadata';
import { PrimaryButton } from '../../forms/buttons/index';
import colors from '../../../utils/config/colors';

import type { AssetDownload } from '../../../utils/types/downloads';
import { Link } from 'gatsby';

export const AssetPreview = ({ asset } : {asset: AssetDownload}): React.Node => {

  let { lbDomain, lbAssetHost }= useSiteMetadata();
  let previewUrl = `downloads/${asset.downloadType}/${asset.slug}.png`;
  let detailUrl = `/downloads/${asset.downloadType}/${asset.slug}`;
  let downloadUrl = `${lbAssetHost}/downloads/${asset.downloadType}/download/${asset.slug}.${asset.fileExtension}`;

  let pinterestUrl = `https://www.pinterest.com/pin/create/button/?url=${lbDomain}/downloads/${asset.downloadType}/&media=${lbDomain + "/" + previewUrl}&description=${asset.description} Downloadable ${asset.typeDescription} from Lightboard.com. Awesome service for marketing design!`;

  function fontDescription() {
    const { fonts } = asset;
    if (!fonts) return;
    let fontSet:any[] = fonts.map(font => <a href={font.download_url} target="_blank" rel="noreferrer">{font.name}</a>);
    return <span>Requires {toSentence(fontSet)}.</span>;
  }

  let descriptionCss = css`
    border-top: dotted 1px ${colors.borderColor};
    margin: 1rem;
    padding: 2rem 0;
  `;

  return (
    <>
      <Helmet>
        <script type="text/javascript" src="//assets.pinterest.com/js/pinit.js" />
      </Helmet>
      <Section className='no-link-decoration footroom'>
        <Row>
          <Column>
            <Link to={detailUrl}>
              <FluidImage src={previewUrl} alt={asset.title}/>
            </Link>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row vcenter>
          <Column size={8}>
            <h3 className='no-link-decoration'>
              <a data-pin-do="buttonPin" href={pinterestUrl} data-pin-shape="round"></a>
              &nbsp;&nbsp;{asset.title}
            </h3>
          </Column>
          <Column size={4}>
            <p>
              <TrackedLink to={downloadUrl} trackId={`${asset.downloadType}-download`} trackData={{assetId:asset.slug}} external>
                <PrimaryButton>
                  <MdCloudDownload/>
                  <span>Download {asset.fileExtension && <span>{`${asset.fileExtension}`}</span>}</span>
                </PrimaryButton>
              </TrackedLink>
            </p>
          </Column>
        </Row>
        <Row>
          <Column>
            <div css={descriptionCss}>
              <p>{asset.description}</p>
              {asset.fonts && <p>{fontDescription()}</p>}
            </div>
          </Column>
        </Row>
      </Section>
    </>
  );
};
