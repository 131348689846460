// @flow
import type { Node } from "react";
import * as React from "react";

import classnames from "classnames";
import { Link } from "gatsby";
import { Row, ColumnMin, ColumnMax} from '../../../utils/layout/grid';
import { FaBars, FaTimes } from 'react-icons/fa';

import * as Styles from "./nav.module.styl";
import logo from "../../../images/lightboard.svg";
import { PrimaryButton } from '../../forms/buttons/index';
import GoToAppButton from './go_to_app_button';

type LinkDef = {
  label: string,
  url: string,
  button?: boolean,
  external?: boolean,
  skipDesktop?: boolean,
}

type Props = {
  solid?: boolean,
  shortMenu?: boolean,
  registered?: boolean,
}

const NavLink = ({link, desktop = false, onClick}: { link: LinkDef, desktop?: boolean, onClick?: Function}) => {
  if (link.skipDesktop && desktop) {
    return null;
  }

  let label;
  if (link.button) {
    if (desktop) {
      label = <button>{link.label}</button>;
    } else {
      label = <PrimaryButton>{link.label}</PrimaryButton>;
    }
  } else {
    label = <span>{link.label}</span>;
  }

  if (link.external) {
    return <a onClick={onClick} key={link.url} href={link.url}>{label}</a>;
  } else {
    return <Link onClick={onClick} key={link.url} to={link.url}>{label}</Link>;
  }
};

const toggleMenu = () => {
  const openClass = Styles.menuMobileOpen;

  const [body] = document.getElementsByTagName('body');
  const isOpen = body.classList.contains(openClass);

  if (isOpen) {
    body.classList.remove(openClass);
  }
  else {
    body.classList.add(openClass);
  }
};

const MobileMenu = ({ links }: { links: LinkDef[] }) => (
  <div className={Styles.menuMobile}>
    <div className={Styles.mobileLogo}>
      <img alt="Lightboard Logo" src={logo} />
    </div>
    <ul>
      {links.map((link, i) => <li key={i}><NavLink link={link} onClick={toggleMenu} /></li>)}
      <li><GoToAppButton /></li>
    </ul>
    <div className={Styles.mobileToggle} onClick={toggleMenu}>
      <FaTimes />
    </div>
  </div>
);

const MobileMenuToggle = () => (
  <div className={Styles.mobileHamburger} onClick={toggleMenu}>
    <FaBars />
  </div>
);


const HeaderNavLinks = ({ links }: { links: LinkDef[] }) => (
  <>
    {links.map((link, i) => <NavLink key={i} link={link} desktop />)}
    <GoToAppButton desktop />
  </>
);

const LogoHomeLink = () => (
  <div className={Styles.logo}>
    <Link to="/" className='no-link-decoration'>
      <img alt="Lightboard Logo" src={logo} />
    </Link>
  </div>
);

const landerLinks = [
  { label: 'Request A Demo', url: '/request-demo', button: true }
];

const standardLinks = [
  { label: 'Home', url: '/', skipDesktop: true },
  { label: 'About', url: '/about' },
  { label: 'Portfolio', url: '/portfolio' },
  { label: 'Blog', url: '/blog/', skipDesktop: true, external: true },
  { label: 'Case Studies', url: '/case-studies' },
  { label: 'Pricing', url: '/pricing' },
  { label: 'Contact', url: '/contact' },
  { label: 'Jobs', url: '/jobs', skipDesktop: true }
];

type State = {
  visible: boolean
}

class ScrollSpyMenu extends React.Component<{links: LinkDef[]}, {visible: boolean}> {
  state: State = { visible: false };
  checkScrollInterval: ?any = null;

  componentDidMount() {
    this.checkScrollInterval = setInterval(this.checkScroll, 200);
  }

  componentWillUnmount() {
    if (this.checkScrollInterval) {
      clearInterval(this.checkScrollInterval);
    }
  }

  checkScroll = () => {
    const { scrollY, innerHeight } = window;
    const visible = scrollY > innerHeight;
    if (visible !== this.state.visible) this.setState({visible});
  };


  render(): React.MixedElement {
    const menuClasses = classnames(Styles.menu,Styles.menuSolid, Styles.menuScrollSpy, {
      [Styles.menuScrollSpyIn]: this.state.visible
    });
    const linkClasses = classnames(Styles.links, 'flex-end');

    return (
      <nav className={menuClasses}>
        <Row fluid vcenter>
          <ColumnMin>
            <MobileMenuToggle />
          </ColumnMin>
          <ColumnMin>
            <LogoHomeLink />
          </ColumnMin>
          <ColumnMax className={linkClasses}>
            <HeaderNavLinks links={this.props.links} />
          </ColumnMax>
        </Row>
      </nav>
    );
  }
}

const Nav = ({ shortMenu, solid }: Props): Node => {
  const links: LinkDef[] = shortMenu ? landerLinks : standardLinks;

  const menuClasses = classnames(Styles.menu, {[Styles.menuSolid]: solid});
  const linkClasses = classnames(Styles.links, 'flex-end');

  return (
    <>
    {!solid && <ScrollSpyMenu links={links}/>}
    <nav className={menuClasses}>
      <Row fluid vcenter>
        <ColumnMin>
          <MobileMenuToggle />
        </ColumnMin>
        <ColumnMin>
          <LogoHomeLink />
        </ColumnMin>
        <ColumnMax className={linkClasses}>
          <HeaderNavLinks links={links} />
        </ColumnMax>
      </Row>
      <MobileMenu links={links} />
    </nav>
    </>
  );
};

export default Nav;
