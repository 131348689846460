//@flow
import * as React from "react";
import Layout from './layout-basic';
import type { LayoutProps } from './layout-basic';
import { Column, Readable, Row, Section } from '../../utils/layout/grid';
interface LayoutArticleProps extends LayoutProps {
  footer?: React.Node
}

const LayoutArticle = (props: LayoutArticleProps): React.Node => {
  let {footer, ...rest} = props;
  return (
  <Layout {...rest} solidMenu>
    <Section>
      <Row>
        <Column>
          <Readable className='headroom'>
            {props.children}
          </Readable>
        </Column>
      </Row>
    </Section>
    {footer}
  </Layout>
  );
};

export default LayoutArticle;
