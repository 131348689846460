// @flow
import * as React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import type { ImageProps } from './fluid.js';
import { FluidImage } from './fluid.js';
let logoImages;
let logoWhiteImages;

function setup() {
  const data = useStaticQuery(graphql`{
  logoWhite: allFile(filter: {sourceInstanceName: {eq: "logos"}}) {
    edges {
      node {
        id
        absolutePath
        img: childImageSharp {
          gatsbyImageData(
            quality: 95
            placeholder: NONE
            transformOptions: {duotone: {highlight: "#FFFFFF", shadow: "#FFFFFF", opacity: 0}}
            layout: FULL_WIDTH
          )
        }
      }
    }
  }
  logoColor: allFile(filter: {sourceInstanceName: {eq: "logos"}}) {
    edges {
      node {
        id
        absolutePath
        img: childImageSharp {
          gatsbyImageData(
            quality: 95,
            placeholder: NONE,
            layout: FULL_WIDTH
          )
        }
      }
    }
  }
}
`);

  logoImages = {};
  let images = data.logoColor.edges;
  images.forEach(edge => {
    let image = edge.node;
    let key = image.absolutePath.split('/images/').pop();
    logoImages[key] = image.img.gatsbyImageData;
  });

  logoWhiteImages = {};
  let whiteLogos = data.logoWhite.edges;
  whiteLogos.forEach(edge => {
    let image = edge.node;
    let key = image.absolutePath.split('/images/').pop();
    logoWhiteImages[key] = image.img.gatsbyImageData;
  });
}


export const LogoImage = (props: ImageProps): React.Node => {

  let {src} = props;
  if (!logoImages) setup();

  let {white, ...imageProps} = props;
  let imageSet = (white) ? logoWhiteImages : logoImages;

  if (!imageSet[src]) {
    console.error("Can't find %s", src);
    return null;
  } else {
    return <FluidImage fluid={imageSet[src]} {...imageProps} />;
  }
};
