//@flow
import { useStaticQuery, graphql } from 'gatsby';
import type { CaseStudy } from '../types/case_study';

let showcases;
let showcasesIndex;

function setup() {
  const { allShowcasesJson } = useStaticQuery(
    graphql`
      query {
        allShowcasesJson (sort: {fields:priority}) {
          edges {
            node {
              ...showcaseDetails
            }
          }
        }
      }
    `
  );
  showcasesIndex = {};
  showcases = allShowcasesJson.edges.map(({node}) => {
    showcasesIndex[node.slug]=node;
    return ({ ... node});
  });
}

export const useShowcasesIndex = (slug: string):CaseStudy => {
  if (!showcasesIndex) setup();
  return showcasesIndex[slug];
};


export const useShowcases = ():CaseStudy[] => {
  if (!showcases) setup();
  return showcases;
};

export const showcaseFragment: any = graphql`
  fragment showcaseDetails on ShowcasesJson {
    slug
    pageTitle
    companyName
    metaDescription
    shortText
    hidden
    priority
    color
    projectTypes {
      id
      score
    }
  }
`;
