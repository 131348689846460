//@flow
import type { Node } from "react";

import React from "react";
import { CaseStudies, PresentationCovers } from '../../components/pages/downloads';
import { Column, Row, Section } from "../../utils/layout/grid/index";
import { Link } from 'gatsby';
import RequestDownloads from '../../components/pages/downloads/request-downloads';
import LayoutLifted from '../../components/layouts/layout-lifted';
import { PrimaryButton } from '../../components/forms/buttons/index';
import { MdTrendingFlat } from 'react-icons/md';

const DownloadsIndex = (): Node => {

  let portal={'url':'lightboard-icon.png', 'color':'#225691'};

  return (
    <LayoutLifted portal={portal} pageTitle="Free Marketing Design Downloads" pageDescription="Royalty free marketing design downloads to help you with your next project." onViewEvent="downloads-page" disableRetarget heroUrl='downloads/presentation-cover-hero.jpg'>
      <Section>
        <Row>
          <Column readable>
            <div className='text-center'>
              <h1>Marketing Template Downloads</h1>
              <p>Free templates for marketing design.</p>
              <p>Everybody needs a little help now and then–so we made these templates that are completely free to use in your marketing projects.</p>
            </div>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column size={3} mobileSize={0}/>
          <Column size={3} mobileSize={6} textCenter>
            <Link to="/downloads/presentation-covers">
              <PrimaryButton>
                <span>Presentation Covers</span>
                <MdTrendingFlat/>
              </PrimaryButton>
            </Link>
          </Column>
          <Column size={3} mobileSize={6} textCenter>
            <Link to="/downloads/case-study-templates">
              <PrimaryButton>
                <span>Case Study Templates</span>
                <MdTrendingFlat/>
              </PrimaryButton>
            </Link>
          </Column>
          <Column size={3} mobileSize={0}/>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column readable>
            <h1>Presentation Covers</h1>
            <p>PowerPoint cover images, free to download and use. <Link to="/downloads/presentation-covers">View all.</Link></p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid>
          <Column>
            <PresentationCovers limit={3}/>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column readable>
            <h1>Case Study Templates</h1>
            <p>Case Study templates in InDesign and Word, free to download and use. <Link to="/downloads/case-study-templates">View all.</Link></p>
          </Column>
        </Row>
      </Section>
      <Section padded spaced>
        <Row fluid>
          <Column>
            <CaseStudies limit={3}/>
          </Column>
        </Row>
      </Section>
      <RequestDownloads/>
    </LayoutLifted>
  );
};

export default DownloadsIndex;
