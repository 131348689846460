// @flow
import * as React from 'react';
import Color from 'color';

type Props = {
  headlineText?: string,
  headlineSet?: string[],
  color?: string,
  fontSize?: number,
  extrudeSize?: number,
  extrudeColor?: string,
  webfontLoadingState: string
};

import withWebfontLoadingState from '../../utils/with_webfont_loading_state';
import fontFamilies from '../../utils/config/fonts.json';
import breakpoints from '../../utils/config/breakpoints.json';
import { css } from '@emotion/react';

const ExtrudedText = ({ headlineSet, headlineText, color, fontSize=12, extrudeSize=18, extrudeColor, webfontLoadingState }: Props) => {
  function extrudeShadow(depth: number, color: any) {
    let shadows = [];
    let i = -3;

    while (++i < depth) {
      shadows.push(`${i}px ${i}px 0 ${color || ''}`);
    }
    return shadows.join(",");
  }

  let componentStyle: Object = {
    'margin': `auto auto -${extrudeSize}px ${extrudeSize}px`,
  };

  if (webfontLoadingState === 'loaded') {
    componentStyle.color = (color || '#FFFFFF');
    componentStyle.textShadow = extrudeShadow(extrudeSize, Color(extrudeColor).darken(0.3).hex());
    componentStyle.transform = `translate(-${extrudeSize}px,-${extrudeSize}px)`;
  } else {
    componentStyle.visibility = 'hidden';
  }

  let mobileBreakpoint = `@media (max-width: ${breakpoints.breakpointMobile})`;

  let styling = css({
    'fontFamily': fontFamilies.extrudeFont,
    'transition': "text-shadow 0.5s 0.1s linear, transform 0.5s 0.1s linear, color 0.3s 0.2s linear",
    'fontSize':`${fontSize}rem`,
    [mobileBreakpoint]: {
      'fontSize': `${fontSize*0.7}rem`
    }
  });

  return (
    <div style={componentStyle} css={styling}>
      <span>{headlineText}</span>
    </div>
  );
};

export default (withWebfontLoadingState(ExtrudedText): React.ComponentType<any>);
