// @flow
import * as React from 'react';
import { Link } from 'gatsby';
import useTracking from '../../utils/track/use_tracking';

type Props = {
  children: any,
  to: string,
  external?: boolean,
  trackId: string,
  trackData?: Object
}


const TrackedLink = (props : Props): React.Node => {

  const tracking = useTracking();

  const track = () => {
    tracking.event(props.trackId, props.trackData);
  };

  if (props.external) {
    return (
      <a href={props.to} onClick={track}>
        {props.children}
      </a>
    );
  }

  return (
    <Link onClick={track} to={props.to}>
      {props.children}
    </Link>
  );
};

export default TrackedLink;

