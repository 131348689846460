// @flow
import type { Node } from 'react';
import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import { FillerBlock } from '../../placeholders/index';

let fluidImages;

function setup() {

  const data = useStaticQuery(graphql`{
  fluidImages: allFile(filter: {sourceInstanceName: {eq: "fluidImages"}}) {
    edges {
      node {
        absolutePath
        img: childImageSharp {
          gatsbyImageData(
            quality: 95
            placeholder: NONE
            layout: FULL_WIDTH
          )
        }
      }
    }
  }
}
`);

  fluidImages = {};
  let images = data.fluidImages.edges;
  images.forEach(edge => {
    let image = edge.node;
    let key = image.absolutePath.split('/fluid-images/').pop();
    fluidImages[key] = image.img.gatsbyImageData;
  });
}

//TODO: This might not work w/new version of Gatsby
export const getRawImage = (src: string): any => {
  if (!fluidImages) setup();
  if (!fluidImages[src]) {
    console.error("Can't find %s", src);
    return null;
  } else {
    return fluidImages[src];
  }
};

export type ImageProps = {
  src: string,
  alt?: string,
  css?: any,
  className?: string,
  fluid?: Object,
  fadeIn?: boolean,
  height?: string,
  imgStyle?: Object,
  offset?: any,
  onLoad?: Function,
  side?:'left'|'right'|'center',
  style?: {
    backgroundRepeat?: string,
    backgroundSize?: string,
    minHeight?: string|number,
    position?: string,
    top?: string|number,
    left?: string|number,
    right?: string|number,
    bottom?: string|number,
    zIndex?: string|number,
    width?: string|number,
    height?: string|number,
    margin?: string|number,
    marginBottom?: string|number,
    marginTop?: string|number,
    marginLeft?: string|number,
    marginRight?: string|number,
    maxWidth?: string|number,
  } ,
  width?: any,
  white?: boolean
};

export const FluidImage = ({src, fluid, alt, className, width, style, imgStyle, onLoad, fadeIn=true}: ImageProps):Node  => {

  if (!fluidImages) setup();
  if (!fluidImages[src] && !fluid) {
    console.error("Fluid Images: can't find %s", src);
    return <FillerBlock height={250} backgroundColor="#d7513e"/>;
  } else {
    if (width) {
      if (!style) {
        style = { width: '0' };
      }
      style.width = (typeof width === 'string') ? width : `${width}px`;
    }

    let _fluid = (fluid) ? fluid : fluidImages[src];

    return (
      <GatsbyImage
        image={getImage(_fluid)}
        alt={alt}
        className={className}
        style={style}
        imgStyle={imgStyle}
        onLoad={onLoad} />
    );
  }
};

export const BleedImage = (props:ImageProps): Node => {
  let wrapStyle = {
    'height': props.height,
    'marginLeft': 'auto',
    'marginRight': 'auto',
    'marginTop':'auto',
    'marginBottom':'auto'
  };

  let side = 'center';
  if (props.side ==='left') {
    side = 'right';
  } else if (props.side==='right') {
    side = 'left';
  }

  if (props.offset) {
    if (side==='left') {
      wrapStyle.marginLeft = props.offset;
    } else if (side === 'right') {
      wrapStyle.marginRight = props.offset;
    }
  }

  let imageStyle = {
    'objectPosition': `${side || 'center'} center`
  };

  return (
    <FluidImage {...props} style={wrapStyle} imgStyle={imageStyle}/>
  );
};
