//@flow
import type { Node } from "react";
import React from "react";

import Layout from '../components/layouts/layout-basic';
import LayoutDemo from '../components/demo';

const DefaultLayout = (): Node => {
  return (
    <Layout solidMenu>
      <LayoutDemo/>
    </Layout>);
};

export default DefaultLayout;
