// @flow

import * as React from 'react';
import styled from '@emotion/styled';

import type { CommonLayoutProps  } from '../../types';
import SpreadContext from './context';
import itemStyles from './item_styles';

const StyledPanel = styled.div(itemStyles);

export const SpreadPanel = (props: { children: any }): React.MixedElement => (
  <SpreadContext.Consumer>
    {(context: CommonLayoutProps) => (
      <StyledPanel {...context} {...props}/>
    )}
  </SpreadContext.Consumer>
);
