import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const { Component } = React;

export default class Currency extends Component {
  static propTypes = {
    absolute: PropTypes.bool,
    className: PropTypes.string,
    colorize: PropTypes.bool,
    hideUnit: PropTypes.bool,
    hideZero: PropTypes.bool,
    label: PropTypes.string,
    round: PropTypes.bool,
    title: PropTypes.string,
    value: PropTypes.node
  };

  static defaultProps = {
    absolute: false,
    colorize: false,
    hideZero: false
  };

  noValue() {
    return <span className='currency' title={this.props.title}>&mdash;</span>;
  }

  renderCurrency()  { // eslint-disable-line
    const { absolute, className, colorize, hideUnit, hideZero, round, title } = this.props;
    let { value } = this.props;

    if (typeof value === 'string') {
      value = parseFloat(value);
    }

    if (isNaN(value) || value === null) {
      return this.noValue();
    }

    const isZero = value === 0;
    if (hideZero && isZero) {
      return this.noValue();
    }

    const isNegative = value < 0;

    if (absolute) {
      value = Math.abs(value);
    }

    let formatted;
    if (typeof Intl !== 'undefined') {
      let fractionDigits = (round) ? 0 : 2;
      let formatConfig = {
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits
      };

      if (!hideUnit) {
        formatConfig.style = 'currency';
        formatConfig.currency = 'USD';
      }
      formatted = Intl.NumberFormat('en-US',formatConfig).format(value);
    } else {
      let sign = '';
      if (!absolute && isNegative) {
        sign = '-';
      }
      var digits = round ? 0 : 2;
      formatted = `${sign}$${Math.abs(value.toFixed(digits))}`;
    }

    const classNameFull = classNames({
      'success-text': colorize && !isZero && !isNegative,
      'danger-text': colorize && isNegative
    }, className);

    return (<span className={classNameFull} style={{'whiteSpace':'nowrap'}} title={title}>{formatted}</span>);

  }

  render() {
    const { label } = this.props;

    if (label) {
      return (
        <React.Fragment>
          <span>{label}</span>
          {this.renderCurrency()}
        </React.Fragment>
      );
    }

    return this.renderCurrency();
  }
}
