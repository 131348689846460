//@flow
import type { Node } from "react";
import React from "react";
import Layout from "../../components/layouts/layout-basic";
import { Column, Row, Section } from "../../utils/layout/grid";
import CustomerProfiles from '../../components/pages/shared/customer-profiles';

const DesignFor =  (): Node => {
  return (
    <Layout pageTitle="How we help your team" pageDescription="We've worked with a lot of different teams. Here's how we can work with yours." solidMenu>
      <Section>
        <Row>
          <Column>
            <CustomerProfiles/>
          </Column>
        </Row>
      </Section>
    </Layout>
  );
};

export default DesignFor;
