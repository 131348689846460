//@flow
import * as React from "react";
import { Row, Column, Section } from '../../../utils/layout/grid';
import { PrimaryButton } from '../../forms/buttons/index';
import { LinkedPanel } from '../../containers/panel';
import { useHelpArticles } from '../../../utils/graphql/help_articles';
import { FluidImage } from '../../ui/images/fluid';

const HelpLinks = (): React.Node => {
  let helpArticles = useHelpArticles();
  return (
    <Section padded>
      <Row>
        <Column textCenter>
          <div className='footroom'>
            <h2>More Help Articles</h2>
          </div>
        </Column>
      </Row>
      <Row balancedGutters>
        {helpArticles.map(help => {
          return (<Column size={4} key={help.slug} textCenter>
            <LinkedPanel to={help.slug} height={300} >
              <div className='spaced-half'>
                <FluidImage src={`help/${help.imageUrl}.png`}/>
                <p>{help.title}</p>
                <div className='spaced-half'>
                  <PrimaryButton>View</PrimaryButton>
                </div>
              </div>
            </LinkedPanel>
          </Column>);
        })}
      </Row>
    </Section>
  );
};

export default HelpLinks;
