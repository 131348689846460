//@flow
import type { Node } from "react";
import React from "react";

import Layout from '../components/layouts/layout-basic';
import CalendlyEmbed from "../components/ui/calendly";

class ScheduleCall extends React.Component<{}> {

  render():Node {
    return (
      <Layout pageTitle="Schedule a time with Lightboard" solidMenu>
        <CalendlyEmbed account="team-lightboard" eventName="20min"/>
      </Layout>
    );
  }
}



export default ScheduleCall;
