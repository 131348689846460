//@flow
import * as React from 'react';
import { Column, Centerer } from '../../utils/layout/grid';
import PullQuote from '../ui/pullquote/index';
import { getRawImage } from '../ui/images/fluid';

type TextProps = {
  limit?: number
}

export function FillerText({limit}: TextProps):React.Node {

  let ipsum = "Lorem ipsum dolor amet tilde single-origin coffee poutine, selvage pour-over gluten-free flexitarian raw denim pabst ugh tumeric keffiyeh skateboard. Kogi church-key knausgaard literally woke, coloring book gastropub artisan mustache ramps synth";

  if (limit) {
    ipsum = ipsum.substring(0,limit);
  }

  return <React.Fragment>{ipsum}</React.Fragment>;
}

export function FillerImage({size=96}: {size?: number}):React.Node {
  return (
    <img alt="Filler" src={`https://picsum.photos/${size}`}/>
  );
}

type BlockProps = {
  className?: string,
  children?: any,
  backgroundColor?: string,
  border?: any,
  padding?: any,
  textAlign?: string,
  color?: string,
  height?: any,
  width?: string,
  borderRadius?: string
}

export function FillerBlock({
  border,
  borderRadius='0',
  children,
  className,
  backgroundColor="#edf7ff",
  height=20,
  padding=2,
  textAlign='center',
  width='100%',
  color="#000"
}: BlockProps):React.Node {

  if (border) {
    border = (border===true) ? 'solid 1px #ff0000' : border;
  } else {
    border = '0px';
  }

  let minHeight = height + 'px';

  return (
    <div className={className || ''} style={{'backgroundColor': backgroundColor, 'borderRadius': borderRadius, 'color': color, 'border': border, 'minHeight': minHeight, 'width':width, 'padding': padding + 'rem', 'textAlign':textAlign, 'position':'relative'}}>
      {children}
    </div>
  );
}

export function FillerPortal(props: BlockProps):React.Node {
  let borderRadius = props.borderRadius || '100rem';
  let width = props.width || '96px';
  let height = props.height || 96;
  return <FillerBlock borderRadius={borderRadius} height={height} width={width} {...props}/>;
}

export function FillerCarousel({height=300, slideText="slide"}: {height?: number, slideText?: string}):React.Node {

  let slide = <FillerBlock height={height}>
    <Centerer><small>{slideText}</small></Centerer>
  </FillerBlock>;

  return (
    <>
      <Column size={3}>
        {slide}
      </Column>
      <Column size={6}>
        {slide}
      </Column>
      <Column size={3}>
        {slide}
      </Column>
    </>
  );
}

export function FillerQuote():React.Node {
  return (
    <PullQuote headshotFluid={getRawImage('headshots/brad-bouse.jpg')} name='John Smith' title='Assistant to the regional manager'>
      <p><FillerText/></p>
    </PullQuote>);
}
