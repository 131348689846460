//@flow
import * as React from 'react';
import fetch from '../../utils/fetch';
import useTracking from '../../utils/track/use_tracking';
import { useSiteMetadata } from '../../utils/graphql/site_metadata';

const { useState } = React;
type RenderForm = ({
  onSubmit: Function,
  email: string,
  phase: 'waiting' | 'submitting' | 'submitted' | 'error',
  message: string,
  errorMessage: string,
  setEmail: (email: string) => void,
  setMessage: (message: string) => void,
  setErrorMessage: (message: string) => void
}) => any;

type Props = {
  formLocation?: string,
  render: RenderForm
};

const CaptureForm = ({
  formLocation,
  render
}: Props):React.Node => {
  const [phase, setPhase] = useState('waiting');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const track = useTracking();
  const { lbAppHost } = useSiteMetadata();

  let onSubmit = (e: any ) => {
    e.preventDefault();
    let eventData = {'location':formLocation, 'email':email};

    setPhase('submitting');

    if (!email) {
      track.event('form-submit-error', eventData);
      setErrorMessage('Please enter your email address.');
      return setPhase('error');
    } else {
      setErrorMessage('');
    }

    track.event('form-submit', eventData);

    fetch(`${lbAppHost}/api/leads`, {
      method: "POST",
      credentials: 'include',
      body: JSON.stringify({
        email,
        signUpForm: formLocation,
        message
      })
    }).then((response: any) => {
      try {
        return response.json();
      }
      catch (e) {
        return {};
      }
    }).then(
      ({id, conversion}: any) => {
        if (id) {
          track.alias(id);
        }
        track.refresh();

        if (conversion) {
          track.conversion();
        }
        track.event('form-submit-success', eventData);
        setPhase('submitted');
      },
      () => {
        track.event('form-submit-error', eventData);
        setPhase('error');
      }
    );
  };

  return render({
    onSubmit,
    email,
    phase,
    message,
    errorMessage,
    setEmail,
    setMessage,
    setErrorMessage
  });
};

export default CaptureForm;
