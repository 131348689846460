//@flow
import type { Node } from "react";
import React from "react";
import { Link } from "gatsby";

import { Column, Spread, SpreadPanel, Readable, Row, Section } from "../../utils/layout/grid";
import { FluidImage } from "../../components/ui/images/fluid";

import { GiantButton } from '../../components/forms/buttons/index';
import ProjectTypes from '../../components/pages/shared/project-types';
import PortfolioCallout from '../../components/pages/shared/portfolio-callout';
import StartCta from '../../components/pages/shared/start-cta';
import Layout from '../../components/layouts/layout-basic';

import { useMembershipFaqs } from '../../utils/graphql/faqs/memberships';
import { PricingBadge }  from '../../components/pages/pricing';
import MembershipFaqs from "../../components/pages/pricing/membership-faqs";

const PricingPage =  (): Node => {
  let faqs = useMembershipFaqs();

  const PricingUnits = () => {
    return (
    <Section>
      <Row>
        <Column readable textCenter>
          <h1>Membership Pricing</h1>
        </Column>
      </Row>
      {/*
      <Row>
        <Column textCenter readable>
          1x 2x 3x Custom
        </Column>
      </Row>
      */}
      <Row gutters>
          <Column size={2}>
          </Column>
          <Column size={4}>
            <PricingBadge>
              <h1>Monthly</h1>
              <h3>25 design hours</h3>
              <h2 className='pricing'>$2,750</h2>
              <p>$110/hr (Save 27%)</p>
            </PricingBadge>
          </Column>
          <Column size={4}>
            <PricingBadge>
              <h1>Quarterly</h1>
              <h3>75 design hours</h3>
              <h2 className='pricing'>$6,750</h2>
              <p>$90/hr (Save 40%)</p>
            </PricingBadge>
          </Column>
          <Column size={2}>
          </Column>
        </Row>
    </Section>
    );
  };

  return (
    <Layout pageTitle="Lightboard Pricing: Memberships" solidMenu onViewEvent="pricing">
      <Spread gutters flopped spaced style={{padding: '40px 40px 20px 40px'}}>
        <SpreadPanel>
          <FluidImage src="illustrations/monitor.png" />
        </SpreadPanel>
        <SpreadPanel>
          <Readable right>
            <h1> Great design starts <br />with a great relationship.</h1>
            <p> Our pricing is built around relationships.</p>
            <p> As we get to know you, we become more efficient.</p>
            <p> So, that's how we price our service.</p>
            <p> Memberships give you a dedicated design team for a fraction of the price of a full-time employee. Your team is led by an account manager and senior designer—backed by our collaboration software and team of designerss, illustrators, animators, and developers—ready to deliver terrific design every day of the week.</p>
          </Readable>
        </SpreadPanel>
      </Spread>
      <PricingUnits/>
      <Section gutters>
        <Row>
          <Column textCenter readable>
            <p>Memberships are paid in advanced. Unused hours rollover with renewal. <br/>Larger and annual plans are available.</p>
          </Column>
        </Row>
        <Row>
          <Column textCenter readable>
            <Link to={"/schedule"}>
              <GiantButton>Schedule A Call</GiantButton>
            </Link>
          </Column>
        </Row>
        <Row>
          <Column textCenter readable>
            <p>If you'd prefer to pay per project, we offer <Link to="/pricing/hourly">hourly pricing</Link>.</p>
          </Column>
        </Row>
      </Section>
      <Section spaced>
        <Row>
          <Column textCenter>
            <Readable className="spaced">
              <h1>Frequently Asked Questions</h1>
              <p>Questions we often hear about Memberships.</p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section spaced>
        <Row>
          <MembershipFaqs faqs={faqs}/>
        </Row>
      </Section>
      <ProjectTypes/>
      <PortfolioCallout/>
      <StartCta/>
    </Layout>
  );
};

export default PricingPage;
