// @flow
import React from 'react';

export type SpreadContextValue = {
  balancedGutters?: boolean,
  flopped?: boolean,
  vcenter?: boolean,
  alignCenter?: boolean,
  gutters?: boolean,
};

const GridContext: React$Context<SpreadContextValue> = React.createContext<SpreadContextValue>({});
export default GridContext;
