//@flow
import { useStaticQuery, graphql } from 'gatsby';

import type { Faq } from '../../types';

let membershipFaqs;

function setup() {
  const { faqs } = useStaticQuery(
    graphql`
      query {
        faqs: allMembershipFaqJson {
          edges {
            node {
              id
              title
              body
            }
          }
        }
      }
    `
  );

  membershipFaqs = faqs.edges.map(({node}) => {
    return ({ ... node});
  });
}

export const useMembershipFaqs = ():Faq[] => {
  if (!membershipFaqs) setup();
  return membershipFaqs;
};
