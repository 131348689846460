// @flow

const combineStyles = (mobileBreakpoint: number, styles: Object[][]): { ... } => {
  let allSizes = {};
  let desktopOnly = {};

  for (let block of styles) {
    Object.assign(allSizes, block[0]);
    Object.assign(desktopOnly, block[1]);
  }

  const breakpoint = `@media(min-width: ${mobileBreakpoint}px)`;

  return {
    ...allSizes,
    [breakpoint]: desktopOnly
  };
};

export default combineStyles;
