export function track(name, data) {
  if (typeof window === 'undefined') return;
  if (window.analytics && window.analytics.track) {
    window.analytics.track(name, data);
  }
}

export const identify = (info: Object) => {
  if (typeof window === 'undefined') return;
  const { analytics } = window;
  if (!analytics) return;

  if (info.userId && analytics.identify) {
    let traits = {
      bucketId:info.bucketId,
      firstVisit: new Date()
    };
    if (info.email) {
      traits["email"] = info.email;
    }
    analytics.identify(info.userId, traits);
  }

};

