// @flow
import type { Node } from "react";
import React from "react";
import { useHelpArticles } from '../../utils/graphql/help_articles';
import PortfolioCallout from '../../components/pages/shared/portfolio-callout';
import { Section, Row, Column } from '../../utils/layout/grid';
import { PrimaryButton } from '../../components/forms/buttons/index';
import { FluidImage } from '../../components/ui/images/fluid';
import { LinkedPanel } from '../../components/containers/panel';
import LayoutBasic from '../../components/layouts/layout-basic';
import StartCta from '../../components/pages/shared/start-cta';

const HelpIndex = ({ data }: {data: Object}): Node => {

  let helpArticles = useHelpArticles();

  return (
    <LayoutBasic pageTitle="Lightboard Help" solidMenu>
      <Section padded>
        <Row>
          <Column textCenter>
            <h1>Lightboard Help Articles</h1>
            <p>Learn more about how to work with Lightboard.</p>
            <Section padded>
              <Row balancedGutters>
                {helpArticles.map(helpArticle => {
                  return (<Column size={4} key={helpArticle.slug} textCenter>
                    <LinkedPanel height={300} to={`${helpArticle.slug}`} >
                      <div className='spaced-half'>
                        <FluidImage src={`help/${helpArticle.imageUrl}.png`}/>
                        <p>{helpArticle.title}</p>
                        <div className='spaced-half'>
                          <PrimaryButton>Read</PrimaryButton>
                        </div>
                      </div>
                    </LinkedPanel>
                  </Column>);
                })}
              </Row>
            </Section>
          </Column>
        </Row>
      </Section>
      <PortfolioCallout/>
      <StartCta/>
    </LayoutBasic>);

};

export default HelpIndex;

