//@flow
import React from "react";
import type { Node } from "react";


import { Column, Row, Section, Readable } from "../../utils/layout/grid";
import { LogoImage, ImageCaption, BleedImage  } from "../../components/ui";
import LayoutCaseStudy from '../../components/layouts/layout-case-study';
import { useCaseStudiesIndex } from '../../utils/graphql/case_studies';

const Pinger =  (): Node => {

  let caseStudy = useCaseStudiesIndex('pinger');

  return (
    <LayoutCaseStudy caseStudy={caseStudy} heroUrl='case-studies/pinger/hero.png'>
      <Section>
        <Row>
          <Column readable>
            <h1>Nimble Display Ad Execution</h1>
            <p>Sideline needed a reliable design partner to generate and test hundreds of display ads to promote their
              mobile app. Lightboard delivered.
            </p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column readable>
            <LogoImage src="logos/pinger.png" width={200}/><br />
            <p>Sideline is a phone solution for small businesses, entrepreneurs, freelancers, or anyone
              who needs a second phone line to manage their professional calls while keeping their personal number private.</p>
            <h2 className='headroom'>Designing Winning Campaigns</h2>
            <p>As Sideline entered their growth phases, they needed to quickly determine which messaging was most effective for their audience. Lightboard helped them create permutations around eleven options to determine the right approach.</p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/pinger/feature-campaign-1-full.png'/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>White Glove Service</h2>
            <p>Sideline needed to get their messaging right–and that meant a lot of testing. We designed 36 iterations of the ad units in dozens of sizes. In a normal scenario, that many units would be a logistical nightmare–but Lightboard's project management system made it easy.</p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/pinger/carousel-2-full.png'>
              Carousel ad tests for Facebook and Instagram.
            </ImageCaption>
          </Column>
        </Row>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/pinger/carousel-1-full.png'/>
          </Column>
        </Row>
      </Section>

      <Section>
        <Row>
          <Column readable>
            <h2>True Colors</h2>
            <p>For an ad-saturated audience, color changes can make a critical difference. We worked with Sideline to test a range of colors to see what performed best.</p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/pinger/feature-colortesting-full.png'/>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid vcenter>
          <Column size={6}>
            <BleedImage side="left" src='case-studies/pinger/feature-pricing-2.1.png'/>
          </Column>
          <Column>
            <Readable left>
              <h2>Point of Value</h2>
              <p>An important part of any marketing story, is the value proposition. Sideline wanted to be
                upfront about the value the provide customers, and worked with Lightboard
                to craft messaging around pricing.
              </p>
              <p>Pricing information is tough to get right, and Lightboard worked diligently to arrive at the
                subtle, less-is-more approach that empowers Sideline's potential customers to make the right,
                informed decision.
              </p>
            </Readable>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid>
          <ImageCaption size={6} src='case-studies/pinger/messagingtesting-a-2up.png'/>
          <ImageCaption size={6} src='case-studies/pinger/messagingtesting-b-2up.png'/>
        </Row>
      </Section>
    </LayoutCaseStudy>
   );
};

export default Pinger;
