//@flow
import * as React from 'react';
import PullQuote from '../../ui/pullquote/index';
import { LogoImage } from '../../ui/images/logos';

import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { getRawImage } from '../../ui/images/fluid';
import sizes from '../../../utils/config/breakpoints.json';

let shareThis = {
  path: 'share-this',
  logo: 'logos/share-this',
  headshot: 'headshots/zoe-black.jpg',
  name: 'Zoe Black',
  role:'Director of Product Marketing, ShareThis',
  quote: 'Lightboard ensures that every product marketing piece that we deliver looks awesome and is consistent with our brand.'
};

let easternBank = {
  path: 'eastern-bank',
  logo: 'logos/eastern-bank',
  headshot: 'headshots/kyle-costello.jpg',
  name: 'Kyle Costello',
  role: 'VP, Digital Marketing, EasternBank',
  quote: 'Lightboard feels like an extension of our team. The value that their work generates for Eastern Bank is tremendous.'
};

let glowforge = {
  path: 'glowforge',
  logo: 'logos/glowforge',
  headshot: 'headshots/tony-wright.jpg',
  name: 'Tony Wright',
  role: 'Founder and Head of Product, Glowforge',
  quote: `Good design is really hard–especially in a hurry. Lightboard's designers saved our bacon. It's like having an army of great designers at your fingertips.`
};

let waffle = {
  path: 'waffle',
  logo: 'logos/waffle',
  headshot: 'headshots/megan-espeland.jpg',
  name: 'Megan Espeland',
  role:'Head of Marketing, Waffle.io',
  quote: `Do yourself a favor. Make your life easier. Work with Lightboard.`
};

const CaseStudySlide = ({review}: {review: Object}) => {
  let wrapper = css`
    .logo {
      max-width: 200px;
      margin-bottom: -20px;
    }

    min-height: 280px;
    @media (max-width: ${sizes.breakpointMobile}) {
      min-height: 380px;
    }
    a, a:hover {
      color: inherit;
    }
  `;

  let headshot = getRawImage(review.headshot);

  return (
    <div css={wrapper}>
      <Link to={`/case-studies/${review.path}`}>
        <LogoImage className='logo' src={`${review.logo}.png`}/>
        <PullQuote inline title={review.role} name={review.name} company={review.company} headshotFluid={headshot} readMore>
          {review.quote}
        </PullQuote>
      </Link>
    </div>);
};

//TODO this is not working as a carousel
const CaseStudyCarousel = (): React.Node => {
 return <CaseStudySlide review={shareThis} />;
  /*
  return (
    <Carousel loop>
      <CaseStudySlide review={shareThis} />
      <CaseStudySlide review={easternBank} />
      <CaseStudySlide review={glowforge} />
      <CaseStudySlide review={waffle} />
    </Carousel>
  );

  */
};

export default CaseStudyCarousel;
