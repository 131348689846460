//@flow
import React from "react";
import type { Node } from "react";


import { Column, Row, Section } from "../../utils/layout/grid";
import { ImageCaption, LogoImage } from "../../components/ui";
import LayoutCaseStudy from '../../components/layouts/layout-case-study';
import { useCaseStudiesIndex } from '../../utils/graphql/case_studies';

const Techsmith =  (): Node => {

  let caseStudy = useCaseStudiesIndex('techsmith');

  return (
    <LayoutCaseStudy caseStudy={caseStudy} heroUrl="case-studies/techsmith/hero.png">
      <Section>
        <Row>
          <Column readable>
            <h1>Creating More Brand Awareness</h1>
            <p>TechSmith's suite of screen capture software helps customers create professional, high-quality videos and images for faster, more engaging communication sharing.</p>
            <p>TechSmith partners with Lightboard to generate batches of display ads, hundreds of units at a time.</p>
          </Column>
        </Row>
      </Section>
      <Section spaced>
        <Row>
          <Column readable>
            <LogoImage src="logos/techsmith.png" className='headroom footroom' width={300}/>
            <p>Founded in 1987, TechSmith pioneered the revolutionary idea of capturing screen content for better communication. Today, TechSmith is internationally recognized as
              a leading source for visual communication software. Professionals use TechSmith software to show off a product, teach a course, train others, and instantly start communicating more effectively.
            </p>
            <h2 className='headroom'>Business Need</h2>
            <p>With multiple products to advertise for audiences across the globe, TechSmith needs a design partner who can produce hundreds of ad units quickly and efficiently
              to spread brand awareness.</p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/techsmith/feature-campaign-2.png'>
              TechSmith brand display ad campaign in leaderboard, skyscraper, and large rectangle sizes.
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>Strategy and Measurement Plan</h2>
            <p>Lightboard worked in partnership with TechSmith to design and execute their display ad strategy. We created high-volume to achieve TechSmith's goals:</p>
            <ul className='bullet-list'>
              <li>Appeal to high interest targets</li>
              <li>Drive sign-ups for free product trials</li>
              <li>Find and connecting with quality leads at greater scale</li>
            </ul>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>Path to Success</h2>
            <p>TechSmith's high interest targets are instructional designers, e-course educators, and corporate
              training professionals who need better tools for sharing knowledge. We created a wide variety of display ads so the campaign could target
              each of these groups.</p><br />
          </Column>
        </Row>
      </Section>
      <Section padded >
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/techsmith/hero.png'>
              In this display ad set, we explored using more traditional business images to attract professionals.
            </ImageCaption>
          </Column>
        </Row>
        <Row fluid>
          <Column>
            <ImageCaption src='case-studies/techsmith/hero-quantity.png'>
              In this set, we chose more playful images, vibrant colors, and supporting play-on-words descriptions to test whether these ads were more
              engaging.
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column readable>
            <h2>Translated to scale internationally</h2>
            <p>When working with large ad sets, translating ad copy can be a tedious and time consuming task. Lightboard saved TechSmith time and design resources by
              updating the copy on hundreds of display ads for their international campaigns.</p>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <ImageCaption src='case-studies/techsmith/hero-true.png'>
              TechSmith's display ad campaign for Snagit in English, French, and German.
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>Design Iterations</h2>
            <p>We created an alternative set of translated display ads for additional audience testing.
              From the playful display ad set, the eye-catching, vibrant green ads resulted in higher conversions. The additional translated display
              ads are useful for additional testing for TechSmith's international customers.</p>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row fluid >
          <Column>
            <ImageCaption src='case-studies/techsmith/feature-translations.png'>
              Testing a vibrant green background color to beat 'banner-blindness' in TechSmith's display ad campaign for Camtasia, in English, French, and German.
            </ImageCaption>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column readable>
            <h2>A Trusted Design Partner</h2>
            <h4>High Volume</h4>
            <p>TechSmith needed to roll out hundreds of display ad campaigns to further test their products in international markets.
              Lightboard was a great solution for TechSmith because we could take their campaign goals and design direction and turnaround hundreds of display ads quickly and efficiently.</p>
              <h4>100% Brand Assurance</h4>
              <p>The display ad sets needed to feature TechSmith's many screen capture products in unique ways. The close partnership between TechSmith and Lightboard ensured our teams
                collaborated on maintaining brand assurance while experimenting with ad campaigns for international audiences.</p>
          </Column>
        </Row>
      </Section>
    </LayoutCaseStudy>
  );
};

export default Techsmith;
