import mixpanel from 'mixpanel-browser';
let initialized = false;

export function track(name, data) {
  if (!initialized) return;
  mixpanel.track(name, data);
}

export const initTracking = (apiToken: ?string) => {
  if (!apiToken) return;

  mixpanel.init(apiToken);
  initialized = true;
};

export const alias = (id: string) => {
  if (!initialized) return;
  if (mixpanel.get_distinct_id && mixpanel.get_distinct_id().indexOf('-') > -1) {
    mixpanel.alias(id);
  }
};

export const identify = (info: Object) => {
  if (!initialized) return;
  mixpanel.identify(info.userId);

  if (info.email) {
    mixpanel.people.set('$email', info.email);
  }

  mixpanel.people.set_once('First visit', new Date());
  mixpanel.people.set_once('bucketId', info.bucketId);

  mixpanel.people.increment('page_views');
};
