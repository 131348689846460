//@flow
import type { Node } from "react";
import React from "react";

import { FluidImage } from "../components/ui/images/fluid";
import Layout from "../components/layouts/layout-basic";
import { Column, Spread, SpreadPanel, Row, Readable, Section } from "../utils/layout/grid";

import ContactForm from '../components/forms/contact';
import MembershipPricing from '../components/pages/pricing/membership-pricing-matrix';
import EmailForm from '../components/forms/email/index';
import { useMembershipFaqs } from '../utils/graphql/faqs/memberships';
import MembershipFaqs from "../components/pages/pricing/membership-faqs";

const MembershipsPage = ({ data }: {data: Object}): Node => {
  let faqs = useMembershipFaqs();

  return (
    <Layout pageTitle="Memberships" pageDescription="How Lightboard's subscription pricing model works." solidMenu onViewEvent="memberships" >
      <Spread gutters flopped spaced>
        <SpreadPanel>
          <FluidImage src="illustrations/monitor.png" />
        </SpreadPanel>
        <SpreadPanel>
          <Readable right>
            <h1> Great design starts <br />with a great relationship.</h1>
            <p> Our pricing is built around relationships.</p>
            <p> As we get to know you, we become more efficient.</p>
            <p> So that's how we price our service.</p>
            <p> Memberships give you a dedicated design team for a fraction of the price of a full-time employee. Your team is led by an account manager and senior designer–backed by our collaboration software and team of designers–ready to deliver terrific design every day of the week.</p>
          </Readable>
        </SpreadPanel>
      </Spread>
      <Section gutters>
        <Row>
          <Column>
            <MembershipPricing />
          </Column>
        </Row>
        <Row>
          <Column readable textCenter>
            <h2>Interested in learning more?</h2>
            <p>We're happy to chat.</p>
            <EmailForm buttonLabel="Request a quote" formLocation="membership-pricing"/>
          </Column>
        </Row>
        <Row>
          <Column>
            <h1 className='spaced'>Frequently Asked Questions</h1>
          </Column>
        </Row>
      </Section>
      <MembershipFaqs faqs={faqs}/>
      <Section spaced>
        <Row>
          <Column>
            <Readable>
              <ContactForm />
            </Readable>
          </Column>
        </Row>
      </Section>
    </Layout>
  );
};

export default MembershipsPage;
