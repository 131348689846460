// @flow

import type { OffsetValue } from '../../types';
import paddingAndSpacing from '../../utils/padding_and_spacing';

const sectionStyles = (
  {
    padded,
    spaced,
    inset
  }: { padded?: OffsetValue, spaced?: OffsetValue, inset?: boolean },
): any => {
  let styles: Object = inset ? {
    backgroundColor: 'rgba(0,0,0,0.025)',
    padding: '1rem 0',
    boxShadow:  'inset 0px 2px 1px -2px rgba(0,0,0,0.125)',
    border: 'solid 1px rgba(0,0,0,0.08)'
  } : {};


  return { ...styles, ...paddingAndSpacing({padded, spaced}) };


};

export default sectionStyles;
