//@flow
import * as React from "react";
import { Helmet } from "react-helmet";
import { useSiteMetadata } from '../../../utils/graphql/site_metadata';

type MetaProps = {
  pageTitle?: string,
  pageDescription?: string,
  pageShareImage?: string
}

const defaultTitle = "Lightboard | B2B marketing design on-demand";
const defaultDescription = "Lightboard is a modern design agency for B2B marketing teams. Join the hundreds of companies working with Lightboard.";
const defaultShareImage = "https://lightboard.com/lightboard-share.png";

const Meta = function({pageTitle = defaultTitle, pageDescription, pageShareImage=defaultShareImage}: MetaProps): React.Node {

  let favIconPath = "/favicon.ico";
  let { environment } = useSiteMetadata();

  if (environment ==='development') {
    favIconPath = "favicon-dev.png";
  }  else if (environment ==='staging') {
    favIconPath = "favicon-staging.png";
  }

  return (
    <Helmet>
      <title>{pageTitle}</title>

      <meta charSet = "utf-8" />
      <meta name = "description" content={pageDescription}/>
      <meta charSet = "p:domain_verify" content="46352f5df5894db7b1924a71993cad3c" />

      <meta property = "fb:app_id" content="212194982626840" />
      <meta property = "og:site_name" content = "Lightboard" />

      <meta property = "og:title" content = {pageTitle} />
      <meta property = "og:description" content = {pageDescription || defaultDescription} />
      <meta property = "og:type" content = "website"/>
      <meta property = "og:image" content={pageShareImage} />

      <meta name = "twitter:card" content="summary_large_image"/>
      <meta name = "twitter:title" content={pageTitle}/>
      <meta name = "twitter:description" content={pageDescription || defaultDescription}/>

      <meta name = "twitter:label1" content="Written by"/>
      <meta name = "twitter:data1" content="Lightboard"/>
      <meta name = "twitter:site" content="@lightboarding"/>
      <meta name = "twitter:creator" content="@lightboarding"/>
      <meta name = "twitter:image" content={pageShareImage}/>

      <link href="/apple-touch-icon-precomposed.png" rel="apple-touch-icon"/>
      <link rel="shortcut icon" href={favIconPath} type="image/x-icon"/>
      <link rel="icon" href={favIconPath} type="image/x-icon"/>
    </Helmet>
  );
};

export default Meta;
