//@flow

import * as React from "react";
import Layout from "../components/layouts/layout-basic";
import { Column, Row, Section } from "../utils/layout/grid";

import Carousel from '../components/ui/carousel/index';
import { css } from '@emotion/react';

import {
  SlideAlwaysAwesome,
  SlideAtAGlance,
  SlideWhatWeDo,
  SlideHowItWorks,
  SlideAwesomeDesigners,
  SlidePresentations,
  SlideAds,
  SlideIllustration,
  SlideWeb,
  SlideLogos,
  SlideCta
} from '../components/pages/tour/index';

const TourPage =  ({pageContext}:{pageContext: Object}): React.Node => {

  const navCss=css`
    margin-top:-6rem;
    z-index:100001;
    position:relative;`;

  let { pageNumber, title } = pageContext;
  let pageTitle = title || "Lightboard Tour";

  return (
    <Layout pageTitle={pageTitle} onViewEvent="tour-page">
      <Section>
        <Row fluid>
          <Column>
            <Carousel keyboardNav useRouteBase='/tour' navCss={navCss} startAt={pageNumber}>
              <SlideAlwaysAwesome/>
              <SlideAtAGlance/>
              <SlideWhatWeDo/>
              <SlideHowItWorks/>
              <SlideAwesomeDesigners/>
              <SlidePresentations/>
              <SlideAds/>
              <SlideIllustration/>
              <SlideWeb/>
              <SlideLogos/>
              <SlideCta/>
            </Carousel>
          </Column>
        </Row>
      </Section>
    </Layout>
  );
};

export default TourPage;
