//@flow

import * as React from "react";
import { css } from '@emotion/react';

import { Section, Row, Column } from "../../../utils/layout/grid";
import { FluidImage } from '../../ui/images/fluid';
import { Panel } from '../../containers/panel';
import EmailForm from '../../forms/email/index';

import {
  FaUser,
  FaFeather,
  FaArchive,
  FaMap,
  FaFlask,
  FaCheck
} from 'react-icons/fa';

const MiniFeature = ({icon:Icon, title, children}: {icon:React.ComponentType<{}>, title: string, children: React.Node}) => {
  return (
    <Column size={4}>
      <Panel padding={4} color="#FFFFFF" border="solid 1px #0065b0" backgroundColor="rgba(0,0,0,0)" borderRadius='1rem'>
        <Icon/>
        <h4>{title}</h4>
        {children}
      </Panel>
    </Column>
  );
};

const Features = () => {
  return (
    <Section padded>
      <Row>
        <Column textCenter>
          <h1 className='footroom'>What you get.</h1>
        </Column>
      </Row>
      <Row gutters>
        <MiniFeature icon={FaUser} title="Account Manager">
          <p>Design professional manages your projects, keeping them on track.</p>
        </MiniFeature>
        <MiniFeature icon={FaFeather} title="Great Designers">
          <p>Expert print and digital designers, ready to amaze you.</p>
        </MiniFeature>
        <MiniFeature icon={FaCheck} title="Consistent Results">
          <p>Every project meets your brand guidelines.</p>
        </MiniFeature>
        <MiniFeature icon={FaArchive} title="Collaboration Platform">
          <p>Invite your colleagues to review, approve, and request projects.</p>
        </MiniFeature>
        <MiniFeature icon={FaMap} title="Asset Manager">
          <p>All of your projects at your fingertips, available to anyone in your team.</p>
        </MiniFeature>
        <MiniFeature icon={FaFlask} title="Best Practices">
          <p>We share what we've learned from thousands of projects.</p>
        </MiniFeature>
      </Row>
    </Section>
  );
};

const HowItWorks = ({hideCta}: {hideCta?:boolean}): React.Node => {

  const howCss = css`background: linear-gradient(to bottom,#005696,#003963);}`;
  const processBox = css`padding: 4rem 4rem 0 4rem;p { margin-top:1rem;}`;
  const processCircle=css`width:6rem;height:6rem;border-radius:100rem;border: solid 2px #FFFFFF;margin:auto;h2 { line-height:5rem;font-size:3rem;} `;

  return (
    <Panel css={howCss} backgroundColor="#FF0000" color="#FFFFFF">
      <Section padded>
        <Row>
          <Column>
            <FluidImage src="illustrations/how-it-works.png" style={{'maxWidth':'95%', 'margin':'auto'}}/>
          </Column>
        </Row>
        <Row>
          <Column textCenter>
            <h1 className="padded">How it works</h1>
          </Column>
        </Row>
        <Row>
          <Column textCenter size={3} mobileSize={0}>
            <div css={processCircle}>
              <h2>1</h2>
            </div>
          </Column>
          <Column textCenter size={3} mobileSize={0}>
            <div css={processCircle}>
              <h2>2</h2>
            </div>
          </Column>
          <Column textCenter size={3} mobileSize={0}>
            <div css={processCircle}>
              <h2>3</h2>
            </div>
          </Column>
          <Column textCenter size={3} mobileSize={0}>
            <div css={processCircle}>
              <h2>4</h2>
            </div>
          </Column>
        </Row>
        <Row>
          <Column css={processBox} textCenter size={3}>
            <p>Submit a request. We'll review and send you an estimate same day.</p>
          </Column>
          <Column css={processBox} textCenter size={3}>
            <p>Our team starts work within a business day. View progress through our app.</p>
          </Column>
          <Column css={processBox} textCenter size={3}>
            <p>Changes? No problem. We work with you until it's perfect.</p>
          </Column>
          <Column css={processBox} textCenter size={3}>
            <p>All set? Download final assets, guaranteed to put a smile on your face.</p>
          </Column>
        </Row>
      </Section>
      <Features/>
      {!hideCta &&
        <Section padded>
          <Row>
            <Column textCenter readable>
              <h2>See for yourself.</h2>
              <p>Add your email below and we'll send you a case study.</p>
              <EmailForm buttonLabel="Send Case Study" formLocation="request-case-study"/>
            </Column>
          </Row>
        </Section>}
    </Panel>
  );
};

export default HowItWorks;
