// @flow
import * as React from 'react';
import styled  from '@emotion/styled';

type Props = {
  children: Node | string,
  color: string,
  position: string,
};

const Text = styled.div`
  background: ${({ position, color }: { position: string, color: string }): string =>
    position === 'top' ? `linear-gradient(${color} 100%, transparent 50%)` : `linear-gradient(transparent 50%, ${color} 50%)`};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: -15% 0 0 0;
  padding: 0rem;
  line-height: 0.9;

`;

export default ({children, color, position}: Props): React.MixedElement => (
  <Text position={position} color={color}>
    {children}
  </Text>
);
