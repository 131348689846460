// @flow
const pick = (whitelist: string[], props: Object): { [key: string]: any } => {
  const result: { [key: string]: any } = {};
  for (let k of whitelist) {
    if (typeof props[k] !== 'undefined') {
      result[k] = props[k];
    }
  }
  return result;
};
export default pick;
