//@flow
import * as React from "react";
import { Section, Row, Column } from '../../../../utils/layout/grid';
import EmailForm from '../../../forms/email/index';
import { Link } from 'gatsby';
import { BigDollar } from '../../../ui/styled-text';
import { Panel } from '../../../containers/panel';
import { OutlineButton } from '../../../forms/buttons/index';

const PricingPanel = ({children}: {children: React.Node}) => {
  return (
    <Panel borderRadius="0.5rem" padding={4} height={300} backgroundColor="#fefefe" border="solid 1px #f0f0f0">
      {children}
    </Panel>);
};

export default function Pricing({children}: {children?:React.Node}): React.Node {

  let pricingDetails: React.Node = <p>We have two pricing models: Hourly and Membership.</p>;

  if (children) {
    pricingDetails = children;
  }
  return (
    <>
      <Section padded>
        <Row>
          <Column textCenter>
            <h2>Pricing</h2>
            {pricingDetails}
          </Column>
        </Row>
      </Section>
      <Section gutters>
        <Row>
          <Column size={6}>
            <PricingPanel>
              <div className='footroom'>
                <h2>Hourly</h2>
                <div>
                  <BigDollar amount="150"/>
                </div>
                <p>Pay project-to-project with hourly pricing.</p>
                <p>Perfect for teams that only need a single project. Each project starts with an estimate that includes at least three revisions, and we start work within a business day.</p>
              </div>
              <Link to="/pricing" className='no-link-decoration'>
                <OutlineButton>Details</OutlineButton>
              </Link>
            </PricingPanel>
          </Column>
          <Column size={6}>
            <PricingPanel>
              <div className='footroom'>
                <h2>Membership</h2>
                <div><BigDollar amount="90"/></div>
                <p>Discounted rate with a subscription to our design service. Ideal for teams that need regular design support, a Membership is a cost-effective way to support your marketing and design team. Dedicated account manager and design lead.</p>
              </div>
              <Link to="/memberships" className='no-link-decoration'>
                <OutlineButton>Details</OutlineButton>
              </Link>
            </PricingPanel>
          </Column>
        </Row>
        <Row>
          <Column textCenter>
            <div className='headroom'>
              <p>View all <Link to="/pricing">pricing details</Link>.</p>
            </div>
          </Column>
        </Row>
      </Section>
      <Section padded>
        <Row>
          <Column readable>
            <EmailForm formLocation="project-pricing" buttonLabel='Request Consultation'/>
          </Column>
        </Row>
      </Section>
    </>
  );
}
