//@flow
import * as React from "react";

import { getPortfolioSets } from '../../../utils/graphql/portfolio';
import { Link } from 'gatsby';
import * as Styles from './portfolio.module.styl';
import { Section, Row, Column } from '../../../utils/layout/grid';

const isActive = (location: {pathname:string}, key:string) => {
  let pathName = (location || {}).pathname;
  return (pathName === `/portfolio/${key}` || pathName === `/portfolio/${key}/`);
};

const PortfolioMenu = ({location}:{location:Object}): React.Node => {
const portfolioSets = getPortfolioSets();
let _portfolioSets = [{key:'all', title: 'All', extension:'png', images:[]}].concat(portfolioSets);
let numCategories = _portfolioSets.length + 1;

return (
  <Section className={Styles.menu}>
    <Row fluid>
      {_portfolioSets.map((set) => (
        <Column className={`${Styles.menuLink} ${(isActive(location, set.key)) ? Styles.menuLinkActive : ''}`} size={1 / numCategories} mobileSize={1 / Math.floor(numCategories / 2)} key={set.key}>
          <Link to={(set.key==='all') ? `/portfolio/` : `/portfolio/${set.key}`} activeClassName={Styles.menuLinkActive}>
            <h3>{set.title}</h3>
          </Link>
        </Column>
      ))}
      <Column className={`${Styles.menuLink} ${(isActive(location, 'motion')) ? Styles.menuLinkActive : ''}`} size={1 / numCategories} mobileSize={1 / Math.floor(numCategories / 2)} key='motion'>
        <Link to={`/portfolio/motion`} activeClassName={Styles.menuLinkActive}>
          <h3>Motion</h3>
        </Link>
      </Column>
    </Row>
  </Section>
  );
};

export default PortfolioMenu;
