//@flow
import { useStaticQuery, graphql } from 'gatsby';
import type { AssetDownload } from '../../types/downloads';
export const usePresentationCoversDownloads = ():AssetDownload[] => {
  const { allPresentationCoversJson } = useStaticQuery(
    graphql`
      query {
        allPresentationCoversJson (sort: {fields:priority}) {
          edges {
            node {
              slug
              title
              description
              fonts {
                name
                download_url
              }
            }
          }
        }
      }
    `
  );
  let presentationCovers = allPresentationCoversJson.edges.map(presentationCover =>
    ({
      downloadType: 'presentation-covers',
      typeDescription:'Presentation cover page',
      fileExtension: 'ppt',
      ...presentationCover.node
    }));
  return presentationCovers;
};
