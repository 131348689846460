//@flow
import * as React from "react";

import { Column, Row, Section } from "../../../utils/layout/grid";
import PortfolioGallery from './portfolio-gallery';
import LayoutPortfolio from '../../layouts/layout-portfolio';
import { getPortfolioSet } from '../../../utils/graphql/portfolio';
import type { PortfolioSet } from '../../../utils/types';
import PortfolioMenu from './portfolio-menu';

const PortfolioPage = ({pageContext, location}: { pageContext: Object, location: Object}):React.Node => {
  let { set } = pageContext;
  let portfolioSet: PortfolioSet = getPortfolioSet(set.key);
  let title = `${portfolioSet.title} Portfolio`;
  let description = `Examples of ${portfolioSet.title} design from Lightboard.`;

  return (
    <LayoutPortfolio pageTitle={title} breadcrumbsTitle={portfolioSet.title} heroUrl={portfolioSet.heroUrl || 'tiles/seamless-white.png'} pageDescription={description} onViewEvent="portfolio-set" solidMenu>
      <Section>
        <Row fluid>
          <Column>
            <PortfolioGallery set={portfolioSet} columnSize={12} standalone location={location}/>
          </Column>
        </Row>
      </Section>
      <Section spaced>
        <Row>
          <Column textCenter>
            <h1>See more of our work.</h1>
            <PortfolioMenu location={location}/>
          </Column>
        </Row>
      </Section>
    </LayoutPortfolio>
  );
};

export default PortfolioPage;
